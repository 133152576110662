// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cart_mini_container__lEhDJ {
    width: 100px;
    height: 90px;
    background-color: #009F7F;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    color: #FFFFFF;
    font-size: small;
    display: flex;
    justify-content: center;
    flex-direction: column;
    font-weight: 600;
    cursor: pointer;
    pointer-events: auto;
}

.cart_mini_price__kq194 {
    color: #009F7F;
    background-color: #FFFFFF;
    border-radius: 8px;
    font-weight: 600;
    margin: 0.5rem 0.5rem 0 0.5rem;
    padding: 0.25rem 0;
}

@media (max-width: 650px) {
    .cart_mini_container__lEhDJ {
        width: 90%;
        height: auto !important;
        background-color: #009F7F;
        border-radius: 24px !important;
        color: #FFFFFF;
        font-size: small;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        font-weight: 600;
        cursor: pointer;
        pointer-events: auto;
        padding: 2px 2px 2px 10px;
    }

    .cart_mini_price__kq194 {
        border-radius: 24px;
        height: 100% !important;
        margin: 0 !important;
        padding: 0.80rem 0.50rem !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/shop/components/cart/cart_mini/cart_mini.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,YAAY;IACZ,yBAAyB;IACzB,2BAA2B;IAC3B,8BAA8B;IAC9B,cAAc;IACd,gBAAgB;IAChB,aAAa;IACb,uBAAuB;IACvB,sBAAsB;IACtB,gBAAgB;IAChB,eAAe;IACf,oBAAoB;AACxB;;AAEA;IACI,cAAc;IACd,yBAAyB;IACzB,kBAAkB;IAClB,gBAAgB;IAChB,8BAA8B;IAC9B,kBAAkB;AACtB;;AAEA;IACI;QACI,UAAU;QACV,uBAAuB;QACvB,yBAAyB;QACzB,8BAA8B;QAC9B,cAAc;QACd,gBAAgB;QAChB,aAAa;QACb,8BAA8B;QAC9B,mBAAmB;QACnB,gBAAgB;QAChB,eAAe;QACf,oBAAoB;QACpB,yBAAyB;IAC7B;;IAEA;QACI,mBAAmB;QACnB,uBAAuB;QACvB,oBAAoB;QACpB,mCAAmC;IACvC;AACJ","sourcesContent":[".container {\n    width: 100px;\n    height: 90px;\n    background-color: #009F7F;\n    border-top-left-radius: 8px;\n    border-bottom-left-radius: 8px;\n    color: #FFFFFF;\n    font-size: small;\n    display: flex;\n    justify-content: center;\n    flex-direction: column;\n    font-weight: 600;\n    cursor: pointer;\n    pointer-events: auto;\n}\n\n.price {\n    color: #009F7F;\n    background-color: #FFFFFF;\n    border-radius: 8px;\n    font-weight: 600;\n    margin: 0.5rem 0.5rem 0 0.5rem;\n    padding: 0.25rem 0;\n}\n\n@media (max-width: 650px) {\n    .container {\n        width: 90%;\n        height: auto !important;\n        background-color: #009F7F;\n        border-radius: 24px !important;\n        color: #FFFFFF;\n        font-size: small;\n        display: flex;\n        justify-content: space-between;\n        flex-direction: row;\n        font-weight: 600;\n        cursor: pointer;\n        pointer-events: auto;\n        padding: 2px 2px 2px 10px;\n    }\n\n    .price {\n        border-radius: 24px;\n        height: 100% !important;\n        margin: 0 !important;\n        padding: 0.80rem 0.50rem !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `cart_mini_container__lEhDJ`,
	"price": `cart_mini_price__kq194`
};
export default ___CSS_LOADER_EXPORT___;
