import React, { useContext, useEffect, useState } from "react";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import styles from "../../styles/admin.module.css";
import TableView from "../../components/table_view";
import {
  faList,
  faPenToSquare,
  faPlus,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import axiosInstance from "../../../AxiosInstance";
import myContext from "../../../MyContext";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../components/delete_modal/delete_modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function SubcategoriesList() {
  const [rows, setRows] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const deleteObj = (id) => {
    axiosInstance.delete("subcategory/" + id + "/").then((response) => {
      window.location.reload();
    });
  };

  const { setEditing } = useContext(myContext);
  const navigate = useNavigate();
  const editObj = (data) => {
    navigate("/admin/subcategories/create", { state: data });
  };

  const addProduct = (data) => {
    setEditing(data);
    navigate("/admin/subcategories/add-product");
  };

  const listSubcategory = (data) => {
    navigate("/admin/subcategories/detailed", { state: data });
  };

  const cols = [
    { title: "ID", internal: "id" },
    { title: "Name", internal: "name", filter: true },
    { title: "Slug", internal: "slug", filter: true },
    { title: "Code", internal: "code", filter: true },
    {
      title: "Category",
      internal: "category.name",
      filterInternal: "category",
      filter: true,
    },
    {
      title: "Biller",
      internal: "supplier.name",
      filterInternal: "biller",
      filter: true,
    },
    { title: "Display", internal: "display", isBoolean: true },
    {
      title: "Actions",
      internal: "id",
      code: (data, row) => {
        return (
          <div className={"d-flex justify-content-end"}>
            <div
              role={"button"}
              onClick={() => editObj(data)}
              className={"pe-3"}
            >
              <FontAwesomeIcon icon={faPenToSquare} color={"#9EA3AC"} />
            </div>

            <div
              role={"button"}
              className={"pe-3"}
              onClick={() => {
                setShowDelete(true);
                setSelectedId(row);
              }}
            >
              <FontAwesomeIcon icon={faTrash} color={"#EF4444"} />
            </div>

            <div
              role={"button"}
              onClick={() => addProduct(data)}
              className={"pe-3"}
            >
              <FontAwesomeIcon icon={faPlus} color={"#9EA3AC"} />
            </div>

            <div
              role={"button"}
              onClick={() => listSubcategory(data)}
              className={"pe-3"}
            >
              <FontAwesomeIcon icon={faList} color={"#9EA3AC"} />
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className={styles.mainContainer}>
      <AdminHeader />
      <AdminSidebar />

      <DeleteModal
        show={showDelete}
        setter={setShowDelete}
        onClick={() => deleteObj(selectedId)}
      />

      <div className={`${styles.container} me-4 pb-4`}>
        <TableView
          fetchUrl={"subcategory"}
          title={"subcategories"}
          cols={cols}
          rows={rows}
          pull={setRows}
          withIndex={true}
          useReactQuery={"subcategory-list"}
        />
      </div>
    </div>
  );
}
