import React, { useContext, useEffect, useState } from "react";
import styles from "../../styles/admin.module.css";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import {
  DateInput,
  PrimaryButton,
  RadioInput,
  SecondaryButton,
  TextInput,
} from "../../components/inputs";
import axiosInstance from "../../../AxiosInstance";
import MyContext from "../../../MyContext";
import {
  toast,
  ToastTypes,
} from "../../../components/ajonjolib/toasts/toast/toast";
import { formatDateToDayMonthYear, formatDateYMD } from "../../../utils/utils";
import { queryClient } from "../../../App";
import { useNavigate } from "react-router-dom";

export default function PromotionsCreate() {
  const { editing, product } = useContext(MyContext);

  const navigate = useNavigate();

  // Calculer la date d'hier et une date un mois après
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  const nextMonth = new Date(today);
  nextMonth.setMonth(today.getMonth() + 1);

  // Formater les dates en format 'YYYY-MM-DD' pour les inputs de type date
  const formatDate = (date) => {
    return date.toISOString().split("T")[0];
  };
  const [form, setForm] = useState({
    product_id: product.id,
    value: product.price,
    max_units: 99,
    active: 1,
    type: 1,
    start_date: formatDate(yesterday),
    end_date: formatDate(nextMonth),
  });

  useEffect(() => {
    if (editing) {
      editing.active = editing.active === true ? 1 : 0;
    }
    if (product?.id === editing?.id) {
      console.log("samed id");
      setForm({
        product_id: product.id,
        value: editing.value,
        max_units: 99,
        active: editing?.active,
        type: editing?.type,
        start_date: formatDateYMD(editing?.start_date),
        end_date: formatDateYMD(editing?.end_date),
      });
    }
  }, []);

  const back = () => {
    // window.location.replace("/admin/promotions");
    navigate("/admin/promotions", { replace: true });
  };

  const submit = () => {
    let formData = new FormData();
    for (const key in form) {
      formData.append(key, form[key]);
    }

    if (editing) {
      formData.append("product_id", editing.product.id);
      console.log("addming");
      axiosInstance
        .put("promotion/" + editing.id + "/", formData)
        .then((response) => {
          queryClient.invalidateQueries({
            queryKey: ["tableData-promotion-list"],
          });
          back();
        });
    } else {
      formData.append("product_id", product.id);
      axiosInstance.post("promotion/", formData).then((response) => {
        console.log("adming");
        if (response.status !== 201) {
          toast(
            "Active promotion for product already exists",
            ToastTypes.ERROR
          );
        }
        back();
      });
    }
  };

  return (
    <div className={styles.mainContainer}>
      {form.product_id}
      <AdminHeader />
      <AdminSidebar />

      <div className={`${styles.crudContainer}`}>
        <div className={styles.crudText}>
          <div className={"mb-2"}>Create Promotion</div>
          <div>Add your promotion information from here</div>
        </div>

        <div className={styles.crudInputs}>
          <div className={`${styles.createContainer} p-4`}>
            <div style={{ fontWeight: "500" }}>Product:</div>
            <div className={"pb-3"}>
              {product?.name || product?.product?.name}
            </div>
            <div>
              {console.log(product)}
              <p>Cost : {product?.cost || product?.product?.cost}</p>
            </div>
            <TextInput name={"value"} form={form} setForm={setForm} />
            <TextInput
              name={"Max Units"}
              internalName={"max_units"}
              form={form}
              setForm={setForm}
            />
            {console.log(form.start_date)}
            {console.log(form.end_date)}
            <DateInput
              name={"Start"}
              internalName={"start_date"}
              form={form}
              setForm={setForm}
            />
            <DateInput
              name={"End"}
              internalName={"end_date"}
              form={form}
              setForm={setForm}
            />
            <RadioInput
              name={"type"}
              options={["Percentage", "Price"]}
              form={form}
              setForm={setForm}
            />
            <RadioInput
              name={"active"}
              options={["No", "Yes"]}
              form={form}
              setForm={setForm}
            />
          </div>

          <div className={styles.buttonContainer}>
            <SecondaryButton name={"Back"} onClick={back} />
            <div className={"ps-3"} />
            <PrimaryButton
              name={editing ? "Update" : "Add Promotion"}
              onClick={submit}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
