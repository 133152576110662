import React from "react";
import styles from './cart_mini.module.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBagShopping} from "@fortawesome/free-solid-svg-icons";
import {formatPrice} from "../../../../utils/utils";

export default function CartMini({quantity, deliveryFee, price, setOpen}) {
    return (
        <div className={styles.container} onClick={() => setOpen(true)}>
            <div className={'d-flex align-items-center justify-content-center'}>
                <FontAwesomeIcon icon={faBagShopping} className={'pe-2'}/>
                <div>{quantity} produits</div>
            </div>

            <div className={`${styles.price}`}>{formatPrice(price + deliveryFee)}FCFA</div>
        </div>
    )
}