// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal_header__RC4m- {
    padding: 16px;
}

.modal_header__RC4m- div:first-child {
    font-weight: 600;
    color: #334155;
    font-size: 20px;
}

.modal_header__RC4m- div:last-child {
    font-weight: 400;
}

.modal_body__Jiiqq {
    font-weight: 400;
    padding-right: 16px;
    padding-left: 16px;
}

.modal_footer__yFkqq {
    margin-top: 1rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: end;
    padding-right: 16px;
}`, "",{"version":3,"sources":["webpack://./src/admin/components/modal/modal.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,gBAAgB;IAChB,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,aAAa;IACb,oBAAoB;IACpB,mBAAmB;AACvB","sourcesContent":[".header {\n    padding: 16px;\n}\n\n.header div:first-child {\n    font-weight: 600;\n    color: #334155;\n    font-size: 20px;\n}\n\n.header div:last-child {\n    font-weight: 400;\n}\n\n.body {\n    font-weight: 400;\n    padding-right: 16px;\n    padding-left: 16px;\n}\n\n.footer {\n    margin-top: 1rem;\n    margin-bottom: 1rem;\n    display: flex;\n    justify-content: end;\n    padding-right: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `modal_header__RC4m-`,
	"body": `modal_body__Jiiqq`,
	"footer": `modal_footer__yFkqq`
};
export default ___CSS_LOADER_EXPORT___;
