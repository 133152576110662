import React, { useEffect, useState } from "react";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import styles from "../../styles/admin.module.css";
import {
  BigTextInput,
  FileInput,
  PrimaryButton,
  SecondaryButton,
  TextInput,
} from "../../components/inputs";
import axiosInstance from "../../../AxiosInstance";
import { useLocation, useNavigate } from "react-router-dom";
import { queryClient } from "../../../App";

export default function BlogCreate() {
  const [form, setForm] = useState({});
  const { state } = useLocation();

  useEffect(() => {
    if (state) {
      setForm(state);
    }
  }, []);

  const navigate = useNavigate();
  const back = () => {
    // window.location.replace("/admin/blog");
    navigate("/admin/blog", { replace: true });
  };

  const submit = () => {
    let formData = new FormData();
    for (const key in form) {
      formData.append(key, form[key]);
    }

    if (state) {
      axiosInstance.put("blog/" + state.id + "/", formData).then((response) => {
        queryClient.invalidateQueries({
          queryKey: ["tableData-blog-list"],
        });
        back();
      });
    } else {
      axiosInstance.post("blog/", formData).then((response) => {
        queryClient.invalidateQueries({
          queryKey: ["tableData-blog-list"],
        });
        back();
      });
    }
  };

  return (
    <div className={styles.mainContainer}>
      <AdminHeader />
      <AdminSidebar />

      <div className={`${styles.crudContainer}`}>
        <div className={styles.crudText}>
          <div className={"mb-2"}>{state ? "Update" : "Create"} Entry</div>
          <div>
            Add your entry description and necessary information from here
          </div>
        </div>
        <div className={styles.crudInputs}>
          <FileInput name={"image"} form={form} setForm={setForm} />
          <div className={`${styles.createContainer} p-4`}>
            <TextInput name={"title"} setForm={setForm} form={form} />
            <BigTextInput name={"text"} setForm={setForm} form={form} />
          </div>

          <div className={styles.buttonContainer}>
            <SecondaryButton name={"Back"} onClick={back} />
            <div className={"ps-3"} />
            <PrimaryButton
              name={`${state ? "Update" : "Create"} Entry`}
              onClick={submit}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
