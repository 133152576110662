import styles from "../../styles/admin.module.css";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { queryClient } from "../../../App";
import {
  BigTextInput,
  PrimaryButton,
  SecondaryButton,
  SelectInput,
  TextInput,
} from "../../components/inputs";
import axiosInstance from "../../../AxiosInstance";
import { useLocation } from "react-router-dom";

export default function EmailTemplatesCreate() {
  const [form, setForm] = useState({});
  const { state } = useLocation();

  const navigate = useNavigate();
  const back = () => {
    // window.location.replace("/admin/email_templates");
    navigate("/admin/email_templates", { replace: true });
  };

  const submit = () => {
    let formData = new FormData();
    for (const key in form) {
      formData.append(key, form[key]);
    }

    axiosInstance.post("email_template/", formData).then((response) => {
      queryClient.invalidateQueries({
        queryKey: ["tableData-template-email-list"],
      });
      back();
    });
  };
  return (
    <div className={styles.mainContainer}>
      <AdminHeader />
      <AdminSidebar />

      <div className={`${styles.crudContainer}`}>
        <div className={styles.crudText}>
          <div className={"mb-2"}>Create Template</div>
          <div></div>
        </div>
        <div className={styles.crudInputs}>
          <div className={`${styles.createContainer} p-4`}>
            <TextInput name={"subject"} setForm={setForm} form={form} />
            <div className={"mb-2"} />
            <SelectInput
              name={"Template"}
              internalName={"type"}
              form={form}
              setForm={setForm}
              options={[
                { id: 0, name: "Inscription" },
                { id: 1, name: "Confirmation of Order" },
                { id: 2, name: "Billers mail" },
                { id: 3, name: "Billers recap" },
                { id: 4, name: "Automatic order" },
              ]}
            />
            <div className={"mb-2"} />
            <BigTextInput name={"body"} setForm={setForm} form={form} />
          </div>
          <div className={styles.buttonContainer}>
            <SecondaryButton name={"Back"} onClick={back} />
            <div className={"ps-3"} />
            <PrimaryButton
              name={state ? "Update Template" : "Create Template"}
              onClick={submit}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
