import React, { useEffect, useRef, useState } from "react";
import CartMini from "./cart_mini/cart_mini";
import styles from "./cart.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBagShopping,
  faMinus,
  faPlus,
  faSpinner,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import axiosInstance from "../../../AxiosInstance";
import { formatPrice } from "../../../utils/utils";
import { useNavigate } from "react-router-dom";
import {
  toast,
  ToastTypes,
} from "../../../components/ajonjolib/toasts/toast/toast";

function CartProduct({
  modifyQuantity,
  removeItem,
  item,
  removing,
  qteChanging,
}) {
  const [variantCost, setVariantCost] = useState(0);

  useEffect(() => {
    let cost = 0;
    item?.variants?.forEach((variant) => {
      cost += variant.price;
    });
    setVariantCost(cost);
  }, [item]);

  return (
    <div
      className={"d-flex px-2 align-items-center justify-content-between"}
      style={{ borderBottom: "#CCC solid 1px", padding: "2px 0" }}
    >
      <div className={`${styles.quantitySelector} py-1`}>
        <FontAwesomeIcon
          icon={faPlus}
          color={"#77798C"}
          className={"px-2"}
          role={"button"}
          onClick={() => (qteChanging ? null : modifyQuantity(item, 1))}
          style={{
            opacity: qteChanging ? 0.5 : 1,
            pointerEvents: qteChanging ? "none" : "auto",
          }}
        />
        {qteChanging ? (
          <div className="spinner"></div>
        ) : (
          <div style={{ fontWeight: "500" }}>{item.qty}</div>
        )}
        <FontAwesomeIcon
          icon={faMinus}
          color={"#77798C"}
          className={"px-2"}
          role={"button"}
          onClick={() => (qteChanging ? null : modifyQuantity(item, -1))}
          style={{
            opacity: qteChanging ? 0.5 : 1,
            pointerEvents: qteChanging ? "none" : "auto",
          }}
        />
      </div>
      <img
        className={"px-1"}
        src={
          item.product?.image ? item.product.image.image : "/placeholder.jpg"
        }
        alt={item.product.slug}
        height={"100px"}
      />
      <div>
        <div className={"d-flex justify-content-between"}>
          <div style={{ textAlign: "left" }}>
            <div>{item.product?.name}</div>
            <div style={{ color: "#009F7F", fontWeight: "600" }}>
              {formatPrice(
                (item.product.promotion_price
                  ? item.product.promotion_price
                  : item.product.price) + variantCost
              )}
            </div>
          </div>
          <div className={"d-flex flex-column"} style={{ minWidth: "80px" }}>
            <div>-</div>
            <div style={{ fontWeight: "600" }}>
              {formatPrice(
                item.qty *
                  ((item.product.promotion_price
                    ? item.product.promotion_price
                    : item.product.price) +
                    variantCost)
              )}
            </div>
          </div>
        </div>
        <div
          className={"d-flex flex-wrap"}
          style={{ color: "#616161", fontSize: "12px" }}
        >
          {item.variants?.map((variant) => (
            <div>
              {variant?.variant_type}: {variant?.name},{" "}
            </div>
          ))}
        </div>
      </div>
      <div
        className={"ps-2"}
        role={"button"}
        onClick={(event) => {
          if (removing) {
            return null;
          }
          event.stopPropagation();
          removeItem(item);
        }}
        style={{
          opacity: removing ? 0.5 : 1,
          pointerEvents: removing ? "none" : "auto",
        }}
      >
        <div>
          {removing ? (
            // <FontAwesomeIcon icon={faSpinner} color={"#CCC"} size={"xl"} />
            <div className="spinner"></div>
          ) : (
            <FontAwesomeIcon icon={faX} color={"#CCC"} size={"xl"} />
          )}
        </div>
      </div>
    </div>
  );
}

export default function Cart({ refreshCart, outCartItems, setWarehouseFee }) {
  const [isOpen, setIsOpen] = useState(false);
  const [total, setTotal] = useState(0);
  const [cartItems, setCartItems] = useState([]);
  const [deliveryFee, setDeliveryFee] = useState(0);
  const [indications, setIndications] = useState("");
  const district = JSON.parse(localStorage.getItem("district"));
  const componentRef = useRef(null);
  const miniRef = useRef(null);
  const [removing, setRemoving] = useState(false);
  const [qteChanging, setQteChanging] = useState(false);
  const navigate = useNavigate();
  const [restaurant_id, setRestaurant_id] = useState(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target) &&
        miniRef.current &&
        !miniRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    }

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    axiosInstance.get("current_user/").then((res) => {
      if (res.status !== 200) return;
      setDeliveryFee(res.data?.user_data.current_fee);
      if (setWarehouseFee) setWarehouseFee(res.data?.user_data.warehouse_fee);
    });

    axiosInstance.get("cart/view/").then((response) => {
      const cart = response.data;
      if (cart?.items?.length === 0) {
        setDeliveryFee(0);
      }
      setIndications(cart?.indications);
      setRestaurant_id(cart?.restaurant_id);
      setCartItems(cart?.items.sort((a, b) => a.id - b.id));
      if (outCartItems) outCartItems(cart?.items);
      let totalPrice = 0;
      cart?.items.forEach((item) => {
        const variant_cost = item?.variants?.reduce((a, b) => a + b.price, 0);
        const product_price = item.product.promotion_price
          ? item.product.promotion_price
          : item.product.price;
        totalPrice += item.qty * (product_price + variant_cost);
      });
      setTotal(totalPrice);
    });
  }, [refreshCart]);

  const modifyQuantity = (item, quantity) => {
    setQteChanging(true);
    const previousQuantity = item.qty;
    axiosInstance
      .post(`cart/add/${item.product.id}/`, {
        quantity: quantity,
        district_id: district.id,
        is_restaurant: !item.product.is_warehouse_product,
        item_id: item.id,
      })
      .then((response) => {
        const cart = response.data;
        if (response.status !== 200) return;
        setCartItems(cart?.items.sort((a, b) => a.id - b.id));
        if (outCartItems) outCartItems(cart?.items);
        let totalPrice = 0;
        cart?.items.forEach((item) => {
          const product_price = item.product.promotion_price
            ? item.product.promotion_price
            : item.product.price;
          totalPrice += item.qty * product_price;
        });
        setTotal(totalPrice);

        let found = false;

        cart?.items
          .filter((item2) => item2.id === item.id)
          .map((item2) => {
            found = true;
            if (previousQuantity === item2.qty) {
              toast("Quantité maximale ajoutée au panier!", ToastTypes.ERROR);
            }
          });
        setQteChanging(false);
      });
  };

  const removeItem = (item) => {
    setRemoving(true);
    axiosInstance.post(`cart/remove/${item.id}/`).then((response) => {
      setRemoving(false);
      const cart = response.data;
      setCartItems(cart?.items.sort((a, b) => a.id - b.id));
      if (outCartItems) outCartItems(cart?.items);
      let totalPrice = 0;
      cart?.items.forEach((item) => {
        const product_price = item.product.promotion_price
          ? item.product.promotion_price
          : item.product.price;
        totalPrice += item.qty * product_price;
      });
      setTotal(totalPrice);
    });
  };

  const makePurchase = () => {
    if (cartItems?.length === 0) return;
    axiosInstance.get("cart/is_open/").then((response) => {
      const is_open = response.data.is_open;
      const message = response.data.message;
      if (is_open) {
        navigate("/checkout", {
          state: {
            total: total,
            deliveryFee: deliveryFee,
            receivedIndications: indications,
            restaurant_id: restaurant_id,
          },
        });
      } else {
        toast(message, ToastTypes.ERROR);
      }
    });
  };

  return (
    <div
      className={styles.container}
      style={{
        display: cartItems?.length > 0 ? "flex" : "none",
        zIndex: isOpen ? "999999999" : "1",
      }}
    >
      <div
        className={styles.cartMini}
        style={{ pointerEvents: "none" }}
        ref={miniRef}
      >
        <CartMini
          price={total}
          deliveryFee={deliveryFee}
          quantity={cartItems?.length}
          setOpen={setIsOpen}
        />
      </div>
      {isOpen && (
        <div className={styles.cartDrop}>
          <div className={styles.cart} ref={componentRef}>
            <div className={`${styles.cartHeader} px-4 py-3`}>
              <div
                className={"d-flex align-items-center"}
                style={{ color: "#009F7F" }}
              >
                <FontAwesomeIcon
                  icon={faBagShopping}
                  color={"#009F7F"}
                  className={"pe-2"}
                />
                <div style={{ fontWeight: 500 }}>
                  {cartItems?.length} produits
                </div>
              </div>
              <div
                className={"d-flex align-items-center"}
                style={{ fontSize: "x-large", userSelect: "none" }}
                role={"button"}
                onClick={() => setIsOpen(false)}
              >
                <FontAwesomeIcon
                  icon={faX}
                  color={"#C7CDD4"}
                  className={"pe-2"}
                />
                <div style={{ color: "#C7CDD4" }}>Fermer</div>
              </div>
            </div>

            <div>
              {cartItems?.map((item) => (
                <CartProduct
                  qteChanging={qteChanging}
                  item={item}
                  modifyQuantity={modifyQuantity}
                  removeItem={removeItem}
                  removing={removing}
                />
              ))}
            </div>

            <div className={`${styles.cartFooter} mb-3 mx-3`}>
              <div className={"mb-3"}>
                Frais de livraison ({district.name}) {formatPrice(deliveryFee)}
              </div>
              <div
                className={`${styles.orderButton} pe-2 ps-4`}
                onClick={() => makePurchase()}
              >
                <div>Commander</div>
                <div className={`${styles.buttonPrice} px-5 my-1`}>
                  <div>
                    {formatPrice(
                      (parseFloat(total) + parseFloat(deliveryFee)).toFixed(0)
                    )}
                  </div>
                  <div>FCFA</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
