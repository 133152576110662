import React from "react";

export default function PageTitle({name, description, children}) {
    return (
        <div className={'py-2'}
             style={{
                 userSelect: 'none',
                 borderBottom: '1px solid #ccc',
                 backgroundColor: '#FFF'
        }}>
            <div style={{
                fontWeight: '700',
                fontSize: 'x-large'
            }}>{name}</div>
            <div
                style={{
                    fontWeight: '500',
                    fontSize: 'large'
                }}
            >{description}</div>
            <div>{children}</div>
        </div>
    );
}