import { useQuery } from "@tanstack/react-query";
import axiosInstance from "../AxiosInstance";

function useGetUser() {
  const district = JSON.parse(localStorage.getItem("district"));
  const permissions = parseInt(localStorage.getItem("permissions"));
  const access_token = localStorage.getItem("access_token");

  const userQuery = useQuery({
    queryKey: ["user"],
    queryFn: async () => {
      const { data } = await axiosInstance.get("current_user/");
      if (data?.response?.status === 500) {
        localStorage.removeItem("access_token");
      }
      if (district?.id !== data?.user_data?.district?.id && access_token) {
        if (!permissions) {
          if (window.location.pathname !== "/locate") {
            alert("Veuillez mettre à jour votre position");
            window.location.href = "/locate";
          }
        }
      }
      return data;
    },
  });

  return userQuery;
}

export default useGetUser;
