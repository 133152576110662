import React, {useEffect, useState} from "react";
import ShopHeader from "../../header/header";
import Footer from "../footer";
import {BigTextInput, PrimaryButton, TextInput} from "../../../../admin/components/inputs";
import axiosInstance from "../../../../AxiosInstance";

export default function Contact() {
    const [form, setForm] = useState({});
    const [showSuccess, setShowSuccess] = useState(false);

    useEffect(() => {
        document.title = 'Contact | Bring Me';
    }, []);
    const submit = () => {
        axiosInstance.post('contact_message/', {
            message: form['message']
        }).then((response) => {
            setShowSuccess(true);
        });
    }

    return (
        <div>
            <ShopHeader/>

            <div style={{paddingTop: "140px", height: '90vh'}} className={'mx-5 pb-5'}>
                <TextInput name={'nom'} form={form} setForm={setForm}/>
                <TextInput name={'email'} form={form} setForm={setForm}/>
                <BigTextInput name={'message'} form={form} setForm={setForm}/>

                <PrimaryButton name={'Envoyer'} onClick={() => submit()}/>
            </div>

            <Footer/>
        </div>
    );
}