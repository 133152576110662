import React, { useContext, useEffect, useState } from "react";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import styles from "../../styles/admin.module.css";
import TableView from "../../components/table_view";
import {
  faEdit,
  faPenToSquare,
  faTrash,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import axiosInstance from "../../../AxiosInstance";
import myContext from "../../../MyContext";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DeleteModal from "../../components/delete_modal/delete_modal";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import {
  toast,
  ToastTypes,
} from "../../../components/ajonjolib/toasts/toast/toast";

export default function WarehousesList() {
  const [rows, setRows] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [loadingPoints, setLoadingPoints] = useState({});
  const [editingPointId, setEditingPointId] = useState(null);
  const [editingPointValue, setEditingPointValue] = useState("");
  const [changePointLoading, setChangePointloading] = useState({});

  const deleteObj = (id) => {
    axiosInstance.delete("warehouse/" + id + "/").then((response) => {
      window.location.reload();
    });
  };
  const { setEditing } = useContext(myContext);
  const navigate = useNavigate();
  const editObj = (data) => {
    setEditing(data);
    navigate("/admin/warehouses/create");
  };

  const cols = [
    { title: "ID", internal: "id" },
    { title: "Name", internal: "name" },
    { title: "Slug", internal: "slug" },
    {
      title: "Points",
      internal: "points",
      code: (data, row) => {
        const isEditing = editingPointId === data.id;

        return (
          <div className="d-flex align-items-center">
            {changePointLoading[data.id] ? ( // Afficher l'icône de chargement
              <FontAwesomeIcon icon={faSpinner} spin className="ms-2" />
            ) : isEditing ? (
              <>
                <input
                  type="text"
                  value={editingPointValue}
                  onChange={(e) => setEditingPointValue(e.target.value)}
                  onBlur={() => {
                    // Logique pour sauvegarder la modification
                    // Vous pouvez appeler une fonction pour mettre à jour les points ici
                  }}
                />
                <FontAwesomeIcon
                  icon={faCheck} // Remplacez par l'icône de validation
                  className="ms-2"
                  color="green"
                  onClick={() => {
                    // Logique pour sauvegarder la modification
                    // Vous pouvez appeler une fonction pour mettre à jour les points ici
                    handleChangePoints(data?.id, editingPointValue);
                    setEditingPointId(null);
                    setEditingPointValue("");
                  }}
                />
              </>
            ) : (
              <>
                <span>{data.points}</span>
                <FontAwesomeIcon
                  icon={faEdit}
                  className="ms-2"
                  color="orange"
                  onClick={() => {
                    setEditingPointId(data.id);
                    setEditingPointValue(data.points);
                  }}
                />
              </>
            )}
          </div>
        );
      },
    },
    {
      title: "Status Points",
      internal: "points",
      code: (data, row) => {
        return (
          <div className="d-flex align-items-center">
            <span> {data.points_enabled ? "Activé" : "Desactivé"}</span>

            <div className="form-check form-switch ms-2">
              {loadingPoints[data.id] ? ( // Afficher l'icône de chargement
                <FontAwesomeIcon icon={faSpinner} spin className="ms-2" />
              ) : (
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id={`flexSwitchCheck${data.id}`}
                  checked={data.points_enabled}
                  onChange={() =>
                    togglePointsEnabled(data.id, data.points_enabled)
                  }
                />
              )}
            </div>
          </div>
        );
      },
    },
    {
      title: "Actions",
      internal: "id",
      code: (data, row) => {
        return (
          <div className={"d-flex justify-content-end"}>
            <div
              role={"button"}
              onClick={() => editObj(data)}
              className={"pe-3"}
            >
              <FontAwesomeIcon icon={faPenToSquare} color={"#9EA3AC"} />
            </div>

            <div
              role={"button"}
              onClick={() => {
                setShowDelete(true);
                setSelectedId(row);
              }}
            >
              <FontAwesomeIcon icon={faTrash} color={"#EF4444"} />
            </div>
          </div>
        );
      },
    },
  ];

  const togglePointsEnabled = (id, currentStatus) => {
    setLoadingPoints((prev) => ({ ...prev, [id]: true })); // Démarrer le chargement

    axiosInstance
      .post(`warehouse/${id}/toggle-points-enabled/`, {
        is_enabled: !currentStatus,
      })
      .then((response) => {
        if (response.status === 200) {
          toast("Modification réussie", ToastTypes.SUCCESS);
          // Met à jour l'état local sans recharger la page
          setRows((prevRows) =>
            prevRows.map((row) =>
              row.id === id ? { ...row, points_enabled: !currentStatus } : row
            )
          );
        } else {
          toast("Une erreur est survenue", ToastTypes.ERROR);
        }
      })
      .catch((error) => {
        toast("Error toggling points_enabled", ToastTypes.ERROR);
      })
      .finally(() => {
        setLoadingPoints((prev) => ({ ...prev, [id]: false })); // Arrêter le chargement
      });
  };

  const handleChangePoints = (id, value) => {
    setChangePointloading((prev) => ({ ...prev, [id]: true })); // Démarrer le chargement

    axiosInstance
      .post(`warehouse/${id}/update-points/`, {
        points: parseInt(value),
      })
      .then((response) => {
        if (response.status === 200) {
          toast("Modification réussie", ToastTypes.SUCCESS);
          // Met à jour l'état local sans recharger la page
          setRows((prevRows) =>
            prevRows.map((row) =>
              row.id === id ? { ...row, points: value } : row
            )
          );
        } else {
          toast("Une erreur est survenue", ToastTypes.ERROR);
        }
      })
      .catch((error) => {
        toast("Error toggling points_enabled", ToastTypes.ERROR);
      })
      .finally(() => {
        setChangePointloading((prev) => ({ ...prev, [id]: false })); // Arrêter le chargement
      });
  };

  return (
    <div className={styles.mainContainer}>
      <AdminHeader />
      <AdminSidebar />

      <DeleteModal
        show={showDelete}
        setter={setShowDelete}
        onClick={() => deleteObj(selectedId)}
      />

      <div className={`${styles.container} me-4`}>
        <TableView
          fetchUrl={"warehouse"}
          pull={setRows}
          noPagination={true}
          title={"warehouses"}
          cols={cols}
          rows={rows}
          withIndex={true}
          useReactQuery={"warehouse-list"}
        />
      </div>
    </div>
  );
}
