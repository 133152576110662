// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.my_orders_container__0xUSS {
    background-color: #FFFFFF;
    border-radius: 6px;
    border: #ccc solid 1px;
    max-width: 750px;
    width: 100%;
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none;
}

.my_orders_innerContainer__qXUhg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.my_orders_title__3DBzi {
    border-radius: 6px 6px 0 0;
    background-color: #F7F7F7;
    border-bottom: #ccc solid 1px;
}`, "",{"version":3,"sources":["webpack://./src/shop/my_orders/my_orders.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,sBAAsB;IACtB,gBAAgB;IAChB,WAAW;IACX,eAAe;IACf,yBAAiB;YAAjB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,0BAA0B;IAC1B,yBAAyB;IACzB,6BAA6B;AACjC","sourcesContent":[".container {\n    background-color: #FFFFFF;\n    border-radius: 6px;\n    border: #ccc solid 1px;\n    max-width: 750px;\n    width: 100%;\n    cursor: pointer;\n    user-select: none;\n}\n\n.innerContainer {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}\n\n.title {\n    border-radius: 6px 6px 0 0;\n    background-color: #F7F7F7;\n    border-bottom: #ccc solid 1px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `my_orders_container__0xUSS`,
	"innerContainer": `my_orders_innerContainer__qXUhg`,
	"title": `my_orders_title__3DBzi`
};
export default ___CSS_LOADER_EXPORT___;
