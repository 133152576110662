import React, { useContext, useEffect, useState } from "react";
import styles from "../../styles/admin.module.css";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import {
  PrimaryButton,
  SecondaryButton,
  SelectInput,
  TextInput,
} from "../../components/inputs";
import axiosInstance from "../../../AxiosInstance";
import MyContext from "../../../MyContext";
import { queryClient } from "../../../App";
import { useNavigate } from "react-router-dom";

export default function StockTransfer() {
  const [form, setForm] = useState({});
  const [warehouses, setWarehouses] = useState([]);
  const { editing } = useContext(MyContext);

  const navigate = useNavigate();

  useEffect(() => {
    setForm(editing);

    axiosInstance.get("warehouse/").then((response) => {
      setWarehouses(response.data);
    });
  }, []);

  const back = () => {
    // window.location.replace("/admin/stock");
    navigate("/admin/stock", { replace: true });
  };

  const submit = () => {
    let formData = new FormData();
    for (const key in form) {
      formData.append(key, form[key]);
    }

    axiosInstance
      .post("stock/transfer/" + editing.id + "/", formData)
      .then((response) => {
        queryClient.invalidateQueries({
          queryKey: ["tableData-stock-list"],
        });
        queryClient.invalidateQueries({
          queryKey: ["tableData-transfere-history-list"],
        });
        //back();
      });
  };

  return (
    <div className={styles.mainContainer}>
      <AdminHeader />
      <AdminSidebar />

      <div className={`${styles.crudContainer}`}>
        <div className={styles.crudText}>
          <div className={"mb-2"}>Transfer Batch</div>
          <div></div>
        </div>
        <div className={styles.crudInputs}>
          <div className={`${styles.createContainer} p-4`}>
            <div className={"d-flex justify-content-center"}>
              <div>
                <div style={{ fontWeight: "500" }}>Product:</div>
                <div className={"pb-3"}>{form.product?.name}</div>
              </div>
              <div className={"ps-5"}>
                <div style={{ fontWeight: "500" }}>Quantity:</div>
                <div className={"pb-3"}>{form.quantity}</div>
              </div>
              <div className={"ps-5"}>
                <div style={{ fontWeight: "500" }}>Current Warehouse:</div>
                <div className={"pb-3"}>{form.warehouse?.name}</div>
              </div>
            </div>
            <SelectInput
              name={"New Warehouse"}
              internalName={"warehouse_id"}
              options={warehouses}
              setForm={setForm}
              form={form}
            />
          </div>

          <div className={styles.buttonContainer}>
            <SecondaryButton name={"Back"} onClick={back} />
            <div className={"ps-3"} />
            <PrimaryButton name={"Transfer"} onClick={submit} />
          </div>
        </div>
      </div>
    </div>
  );
}
