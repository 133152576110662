import React, {useEffect, useState} from "react";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import styles from '../../styles/admin.module.css';
import TableView from "../../components/table_view";
import {faPenToSquare, faTrash} from "@fortawesome/free-solid-svg-icons";
import TableComponent from "../../components/table";
import TableHeader from "../../components/table_header";
import axiosInstance from "../../../AxiosInstance";

export default function ExpensesList() {
    const [rows, setRows] = useState([]);
    const [rows2, setRows2] = useState([]);

    useEffect(() => {
        axiosInstance.get('delivery_guy/').then((response) => {
            setRows(response.data);
        })

        axiosInstance.get('warehouse/').then((response) => {
            setRows2(response.data);
        })
    }, []);

    const cols = [
        {
            title: "Name",
            internal: "name"
        },
        {
            title: "Salary",
            internal: "salary"
        },
        {
            title: "Status",
            internal: "status"
        }
    ]

    const cols2 = [
        {
            title: "Name",
            internal: "name"
        },
        {
            title: "Amount",
            internal: "amount"
        },
        {
            title: "Actions",
            buttons: [
                {
                    icon: faTrash,
                    color: "#EF4444",
                    url: "/"
                },
                {
                    icon: faPenToSquare,
                    color: "#9EA3AC",
                    url: '/'
                }
            ]
        }
    ]

    return (
        <div className={styles.mainContainer}>
            <AdminHeader/>
            <AdminSidebar/>

            <div className={`${styles.container} me-4`}>
                <TableHeader title={'expenses'}/>
                <div className={'d-flex mt-5 justify-content-between'}>
                    <div style={{width: "49.5%"}}>
                        <TableComponent
                            fetchUrl={'delivery_guy'}
                            pull={setRows}
                            noPagination={true}
                            cols={cols}
                            rows={rows}
                        />
                    </div>
                    <div style={{width: "49.5%"}}>
                        <TableComponent
                            fetchUrl={'warehouse'}
                            pull={setRows2}
                            noPagination={true}
                            cols={cols2}
                            rows={rows2}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
