import React, { useState } from "react";
import styles from "../../styles/admin.module.css";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import TableView from "../../components/table_view";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { formatTimestamp } from "../../components/utils";
import { formatPrice } from "../../../utils/utils";
import ClosureMonthlyStatsChart from "./closure_stats";

export default function MonthlyClosureList() {
  const [rows, setRows] = useState([]);
  const navigate = useNavigate();

  const viewDetail = (data) => {
    navigate("/admin/closure/monthly/detail", { state: data });
  };

  const cols = [
    {
      title: "Date",
      internal: "month",
      //   filter: true,
      //   dateFilter: true,
      //   code: (data, row) => formatTimestamp(row),
    },
    { title: "Warehouse", internal: "warehouse_name", filter: true },
    { title: "Sales", internal: "sale_count" },

    {
      title: "Subtotal",
      internal: "subtotal",
      code: (row, data) => formatPrice(data),
    },
    {
      title: "Profits",
      internal: "total_profit",
      code: (row, data) => formatPrice(data),
    },
    {
      title: "Points used",
      internal: "total_points_used",
      code: (row, data) => formatPrice(data),
    },
    {
      title: "(Profits - Points) ",
      internal: "points_from_profit",
      code: (row, data) => formatPrice(data),
    },
    {
      title: "Total Delivery",
      internal: "total_delivery_expenses",
      code: (row, data) => formatPrice(data),
    },
    // {
    //   title: "Total Expenses",
    //   internal: "total_closure_expenses",
    //   code: (row, data) => formatPrice(data),
    // },
  ];

  return (
    <div className={styles.mainContainer}>
      <AdminHeader />
      <AdminSidebar />
      <div className={`${styles.container} me-4`}>
        <TableView
          title={"Monthly closures"}
          fetchUrl={"stats/warehouse"}
          cols={cols}
          pull={setRows}
          rows={rows}
          withIndex={false}
          noCreate={true}
          limit={50}
        />
      </div>
      <div>
        <ClosureMonthlyStatsChart />
      </div>
    </div>
  );
}
