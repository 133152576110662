// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.shop_sidebar_outContainer__maar9 {
    width: 300px;
    position: fixed;
    height: 100%;
    background-color: #FFF;
    box-shadow: #6B728C 0 0 10px;
    transition: width 0.3s linear;
    z-index: 4;
    overflow-x: hidden;
}

.shop_sidebar_container__hOagq {
    margin-top: -5rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    z-index: 9999;
}

.shop_sidebar_userBox__FdMHS {
    margin: 3rem 0;
    background-color: #F7F7F7;
    height: 120px;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.shop_sidebar_userButton__UsjLR {
    color: #FFFFFF;
    background-color: #009F7F;
    width: 50%;
    padding: 6px 10px;
    border-radius: 6px;
    font-weight: 500;
    cursor: pointer;
}

.shop_sidebar_hide__kdvBI {
    width: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/shop/components/sidebar/shop_sidebar.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,eAAe;IACf,YAAY;IACZ,sBAAsB;IACtB,4BAA4B;IAC5B,6BAA6B;IAC7B,UAAU;IACV,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,qBAAqB;IACrB,uBAAuB;IACvB,aAAa;AACjB;;AAEA;IACI,cAAc;IACd,yBAAyB;IACzB,aAAa;IACb,aAAa;IACb,WAAW;IACX,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,yBAAyB;IACzB,UAAU;IACV,iBAAiB;IACjB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".outContainer {\n    width: 300px;\n    position: fixed;\n    height: 100%;\n    background-color: #FFF;\n    box-shadow: #6B728C 0 0 10px;\n    transition: width 0.3s linear;\n    z-index: 4;\n    overflow-x: hidden;\n}\n\n.container {\n    margin-top: -5rem;\n    height: 100%;\n    display: flex;\n    flex-direction: column;\n    align-content: center;\n    justify-content: center;\n    z-index: 9999;\n}\n\n.userBox {\n    margin: 3rem 0;\n    background-color: #F7F7F7;\n    height: 120px;\n    display: flex;\n    width: 100%;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}\n\n.userButton {\n    color: #FFFFFF;\n    background-color: #009F7F;\n    width: 50%;\n    padding: 6px 10px;\n    border-radius: 6px;\n    font-weight: 500;\n    cursor: pointer;\n}\n\n.hide {\n    width: 0 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"outContainer": `shop_sidebar_outContainer__maar9`,
	"container": `shop_sidebar_container__hOagq`,
	"userBox": `shop_sidebar_userBox__FdMHS`,
	"userButton": `shop_sidebar_userButton__UsjLR`,
	"hide": `shop_sidebar_hide__kdvBI`
};
export default ___CSS_LOADER_EXPORT___;
