import React, { useEffect, useState } from "react";
import styles from "../../styles/admin.module.css";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import TableView from "../../components/table_view";

export default function AlertPage() {
  const [rows, setRows] = useState([]);
  const [cols, setCols] = useState([]);
  const permissions = parseInt(localStorage.getItem("permissions"));
  const warehouse_id = localStorage.getItem("warehouse_id");
  const defaultFilters =
    permissions !== 1 ? { warehouse_id: warehouse_id } : {};

  useEffect(() => {
    let tempCols = [];
    tempCols.push({ title: "Product", internal: "product.name" });
    // tempCols.push({ title: "Quantity", internal: "purchased_quantity" });
    tempCols.push({
      title: "Leftover Quantity",
      internal: "quantity",
      code: (data, row) => {
        return data.quantity;
      },
    });

    tempCols.push({ title: "Warehouse", internal: "warehouse.name" });
    if (permissions === 1)
      tempCols.push({ title: "Supplier", internal: "product.biller.name" });
    tempCols.push({
      title: "Days Left",
      internal: "expiry_date",
      code: (data, row) => {
        const num = Math.ceil(
          (new Date(data.expiry_date) - new Date()) / (1000 * 60 * 60 * 24)
        );
        return <div>{num > 0 ? num : "Expired"}</div>;
      },
    });
    tempCols.push({
      title: "Expiry Date",
      internal: "expiry_date",
      code: (data, row) => {
        return (
          <div>
            {data.expiry_date < new Date().toISOString().slice(0, 10) ? (
              <div
                style={{
                  backgroundColor: "#d9534F",
                  borderRadius: "6px",
                  color: "#FFF",
                  textAlign: "center",
                  fontSize: "large",
                }}
              >
                {row}
              </div>
            ) : (
              <div
                style={{
                  backgroundColor: "#d9a94f",
                  borderRadius: "6px",
                  color: "#FFF",
                  textAlign: "center",
                  fontSize: "large",
                }}
              >
                {row}
              </div>
            )}
          </div>
        );
      },
    });
    setCols(tempCols);
  }, [permissions]);

  return (
    <div className={styles.mainContainer}>
      <AdminHeader />
      <AdminSidebar />

      <div className={`${styles.container} me-4`}>
        <TableView
          defaultFilters={defaultFilters}
          fetchUrl={"stock/expiry_alerts"}
          pull={setRows}
          title={"Expiry Alerts"}
          cols={cols}
          rows={rows}
          noCreate={true}
        />
      </div>
    </div>
  );
}
