import React, { useEffect, useState } from "react";
import styles from "../../styles/admin.module.css";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import { useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../../../AxiosInstance";
import { formatPrice } from "../../../utils/utils";
import { PrimaryButton, SecondaryButton } from "../../components/inputs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faDownload,
  faEdit,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { queryClient } from "../../../App";
import { useQuery } from "@tanstack/react-query";
import Loading from "../../components/loading";

export default function BillerStats() {
  const { state } = useLocation();
  const [data, setData] = useState({});
  const [year, setYear] = useState("");

  const billerDetailQuery = useQuery({
    queryKey: [`billerDetail`, { query: state?.id }],
    queryFn: async () => {
      const { data } = await axiosInstance.get(`c/supplier/${state.id}/stats/`);
      let received = data;
      setYear(received?.year);
      setData(received?.sales_by_month);
      return data;
    },
    enabled: !!state?.id,
  });

  useEffect(() => {
    axiosInstance.get(`c/supplier/${state.id}/stats/`).then((response) => {
      let received = response.data;
      setYear(received?.year);
      setData(received?.sales_by_month);
    });
  }, [state]);

  const updatePaymentStatus = (month) => {
    const currentStatus = billerDetailQuery?.data?.sales_by_month[month]?.paid;
    const currentYear = billerDetailQuery?.data?.sales_by_month[month]?.year;
    const commission =
      billerDetailQuery?.data?.sales_by_month[month]?.total_sales *
      (state.commission / 100);
    axiosInstance
      .post(`supplier/${state.id}/update-payment-status/`, {
        month: month,
        paid: !currentStatus,
        year: currentYear,
        amount: commission,
      })

      .then((response) => {
        setData((prevData) => ({
          ...prevData,
          [month]: {
            ...prevData[month],
            paid: !currentStatus,
          },
        }));
        queryClient.invalidateQueries({ queryKey: ["billerDetail"] });
        queryClient.invalidateQueries({ queryKey: ["tableData-biller-list"] });
      });
  };

  const getStatusColor = (paid) => (paid ? "green" : "red");

  const statusColumn = Array.from({ length: 12 }, (_, index) => index + 1).map(
    (month) => (
      <td
        key={month}
        style={{
          backgroundColor: getStatusColor(
            billerDetailQuery?.data?.sales_by_month[month]?.paid
          ),
          color: "white",
          textAlign: "center",
        }}
      >
        {billerDetailQuery?.data?.sales_by_month[month]?.paid ? "Payé" : "Non"}
        <button
          style={{
            marginLeft: "10px",
            cursor: "pointer",
            border: "none",
            background: "transparent",
            color: "white",
          }}
          onClick={() => updatePaymentStatus(month)}
        >
          <FontAwesomeIcon
            icon={
              billerDetailQuery?.data?.sales_by_month[month]?.paid
                ? faTimesCircle
                : faEdit
            }
          />
        </button>
      </td>
    )
  );

  const remiseColumn = Array.from({ length: 12 }, (_, index) => index + 1).map(
    (month) => (
      <td>
        {billerDetailQuery?.data?.sales_by_month[month]?.remise
          ? formatPrice(billerDetailQuery?.data?.sales_by_month[month]?.remise)
          : 0}
      </td>
    )
  );

  const salesColumn = Array.from({ length: 12 }, (_, index) => index + 1).map(
    (month) => (
      <td>
        {billerDetailQuery?.data?.sales_by_month[month]?.total_sales
          ? formatPrice(
              billerDetailQuery?.data?.sales_by_month[month]?.total_sales
            )
          : 0}
      </td>
    )
  );

  const feesColumn = Array.from({ length: 12 }, (_, index) => index + 1).map(
    (month) => (
      <td>
        {billerDetailQuery?.data?.sales_by_month[month]?.total_fees
          ? formatPrice(
              billerDetailQuery?.data?.sales_by_month[month]?.total_fees
            )
          : 0}
      </td>
    )
  );

  const totalColumn = Array.from({ length: 12 }, (_, index) => index + 1).map(
    (month) => (
      <td>
        {billerDetailQuery?.data?.sales_by_month[month]?.total
          ? formatPrice(billerDetailQuery?.data?.sales_by_month[month]?.total)
          : 0}
      </td>
    )
  );
  const completedOrdersColumn = Array.from(
    { length: 12 },
    (_, index) => index + 1
  ).map((month) => (
    <td>
      {billerDetailQuery?.data?.sales_by_month[month]?.completed_orders || 0}{" "}
      {/* Affichez 0 si aucune donnée n'est disponible */}
    </td>
  ));
  const pointsUsedColumn = Array.from(
    { length: 12 },
    (_, index) => index + 1
  ).map((month) => (
    <td>
      {billerDetailQuery?.data?.sales_by_month[month]?.points_used
        ? formatPrice(
            billerDetailQuery?.data?.sales_by_month[month]?.points_used
          )
        : 0}
    </td>
  ));

  const commissionColumn = Array.from(
    { length: 12 },
    (_, index) => index + 1
  ).map((month) => (
    <td>
      {billerDetailQuery?.data?.sales_by_month[month]?.total_sales
        ? formatPrice(
            billerDetailQuery?.data?.sales_by_month[month]?.total_sales *
              (state.commission / 100) -
              billerDetailQuery?.data?.sales_by_month[month]?.points_used
          )
        : 0}
    </td>
  ));

  const download = (month, year) => {
    const monthNames = [
      "Janvier",
      "Février",
      "Mars",
      "Avril",
      "Mai",
      "Juin",
      "Juillet",
      "Août",
      "Septembre",
      "Octobre",
      "Novembre",
      "Décembre",
    ];
    const monthNumber = monthNames.indexOf(month) + 1;
    console.log(monthNumber, year);
    const commissionAmount = billerDetailQuery?.data?.sales_by_month[
      monthNumber
    ]?.total_sales
      ? billerDetailQuery?.data?.sales_by_month[monthNumber].total_sales *
        (state.commission / 100)
      : 0;
    const formattedCommission = formatPrice(commissionAmount);
    // const year = new Date().getFullYear();
    axiosInstance
      .get(`supplier/${state?.id}/excel/?month=${monthNumber}&year=${year}`, {
        responseType: "blob",
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${state?.name}-${monthNumber}-${year}-Commission-${formattedCommission}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
      });
  };

  const navigate = useNavigate();
  const back = () => {
    // window.location.replace("/admin/billers");
    navigate("/admin/billers", { replace: true });
  };

  return (
    <div className={styles.mainContainer}>
      <AdminHeader />
      <AdminSidebar />

      <div className={`${styles.container} me-4 pb-5`}>
        <h2>{state?.name}</h2>

        <div className={"mb-4"} />

        {billerDetailQuery?.isLoading ? (
          <Loading />
        ) : (
          <>
            <table className="table table-bordered table-hover">
              <thead>
                <tr>
                  <th></th>
                  {Object.values(
                    billerDetailQuery?.data?.sales_by_month || {}
                  ).map((monthData, index) => (
                    <th key={index}>
                      {monthData.month_name} {monthData.year}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Remise</td>
                  {remiseColumn}
                </tr>
                <tr>
                  <td>Livraison</td>
                  {feesColumn}
                </tr>
                <tr>
                  <td>
                    Taxe sur <br /> les produits
                  </td>
                  {remiseColumn}
                </tr>
                <tr>
                  <td>
                    Total + remise <br /> - livraison
                  </td>
                  {salesColumn}
                </tr>
                <tr>
                  <td>Total</td>
                  {totalColumn}
                </tr>
                <tr>
                  <td>Points Used</td>
                  {pointsUsedColumn}
                </tr>
                <tr>
                  <td>Commission</td>
                  {commissionColumn}
                </tr>
                <tr>
                  <td>Commandes complétées</td>
                  {completedOrdersColumn}
                </tr>
                <tr>
                  <td>Excel</td>
                  {Object.values(
                    billerDetailQuery?.data?.sales_by_month || {}
                  ).map((monthData, index) => (
                    <td
                      key={index}
                      onClick={() =>
                        download(monthData.month_name, monthData.year)
                      }
                      style={{
                        color: "blue",
                        cursor: "pointer",
                        userSelect: "none",
                      }}
                    >
                      <FontAwesomeIcon icon={faDownload} />
                    </td>
                  ))}
                </tr>
                <tr>
                  <td>Payment</td>
                  {statusColumn}
                </tr>
              </tbody>
            </table>
            <div className={styles.buttonContainer}>
              <SecondaryButton name={"Back"} onClick={back} />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
