import React, { useEffect, useMemo, useState } from "react";
import styles from "./checkout.module.css";
import ShopHeader from "../components/header/header";
import axiosInstance, { baseURLFront } from "../../AxiosInstance";
import { useLocation, useNavigate } from "react-router-dom";
import {
  arrondirParMultiple,
  formatPrice,
  getCheckoutConfirmation,
} from "../../utils/utils";
import {
  toast,
  ToastTypes,
} from "../../components/ajonjolib/toasts/toast/toast";
import Loading from "../../admin/components/loading";

export default function Checkout() {
  const { state } = useLocation();
  const [currentItemPrice, setCurrentItemPrice] = useState(null);
  const [userData, setUserData] = useState({});
  const [indications, setIndications] = useState(state?.receivedIndications);
  const [canPurchase, setCanPurchase] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showPurchaseError, setShowPurchaseError] = useState(false);
  const district = JSON.parse(localStorage.getItem("district"));
  const [selectedPayment, setSelectedPayment] = useState("cash");
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [loadingPayment, setLoadingPayment] = useState(true);
  const [qrCodeLink, setQrCodeLink] = useState("");
  const [qrCode, setQrCode] = useState(false);
  const navigate = useNavigate();
  const [userPoints, setUserPoints] = useState(0);
  const [warehousePoint, setWarehousePoints] = useState(0);
  const [pointsToUse, setPointsToUse] = useState(0);
  const [isActiveWareHouse, setIsActiveWareHouse] = useState(false);

  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

  const currentAmount = useMemo(() => {
    return (currentItemPrice || state?.total) + state?.deliveryFee;
  }, [currentItemPrice, state]);

  const amountToPay = useMemo(() => {
    const multiple = currentAmount < userPoints ? currentAmount : 500;
    return arrondirParMultiple(userPoints, currentAmount, multiple);
  }, [userPoints, currentAmount]);

  useEffect(() => {
    axiosInstance.get("current_user/").then((res) => {
      setUserData(res.data);
      setUserPoints(res.data.user_data.points);

      // const initialPoints = Math.min(
      //   res.data.user_data.points,
      //   (currentItemPrice || state.total) + state.deliveryFee < 500
      //     ? 500
      //     : Math.floor((currentItemPrice || state.total) / 500) * 500
      // );
      const initialPoints =
        res.data.user_data.points >
        (currentItemPrice || state?.total) + state?.deliveryFee
          ? (currentItemPrice || state?.total) + state?.deliveryFee
          : res.data.user_data.points >= 500
          ? 500
          : 0;

      // const multiple = currentAmount < 500 ? currentAmount : 500;
      // setPointsToUse(
      //   arrondirParMultiple(
      //     res.data.user_data?.points || 0,
      //     (currentItemPrice || state.total) + state?.deliveryFee,
      //     multiple
      //   )
      // );
    });
    // axiosInstance
    //   .get(`warehouses/${district?.warehouse_id}/points/`)
    //   .then((res) => {
    //     console.log({ res });

    //     setWarehousePoints(
    //       res?.data?.data?.points_enabled ? res?.data?.data?.points : 0
    //     );
    //   });

    axiosInstance
      .get(
        state?.restaurant_id
          ? `billers/${state?.restaurant_id}/points/`
          : `warehouses/${district?.warehouse_id}/points/`
      )
      .then((res) => {
        // console.log({ res });

        if (res?.data?.data?.points_enabled) {
          setIsActiveWareHouse(true);
          setWarehousePoints(res?.data?.data?.points);
        }
      });
  }, []);

  useEffect(() => {
    // Fetch the payment methods status
    setLoadingPayment(true);
    axiosInstance
      .get("/payment-methods/")
      .then((response) => {
        setPaymentMethods(response.data); // Set payment methods from the response
        setLoadingPayment(false);
      })
      .catch((error) => {
        console.error("Error fetching payment methods:", error);
        setLoadingPayment(false);
      });
  }, []);

  // check if product on cart
  useEffect(() => {
    if (loading) return;
    const interval = setInterval(() => {
      axiosInstance.get("cart/view/").then((response) => {
        console.log(response.data);
        if (response.data?.items?.length === 0) {
          navigate("/confirmation");
        } else {
          if (response.data?.items) {
            const dataQantityAndProductPrice = response?.data?.items.map(
              (item) => ({ qty: item?.qty, price: item?.product?.price })
            );

            const itemsTotalPrice = dataQantityAndProductPrice.reduce(
              (a, b) => a + b.qty * b.price + b?.variant_cost,
              0
            );

            setCurrentItemPrice(itemsTotalPrice);
          }
          console.log("we have data");
        }
        // if (!response.data.is_open) {
        //    // Redirect to home page if cart is empty
        // }
      });
    }, 2000); // Check every 2 seconds

    return () => clearInterval(interval); // Cleanup on component unmount
  }, []);

  const makePurchase = () => {
    // if (!canPurchase) return;
    setLoading(true);
    axiosInstance.get("cart/is_open/").then((response) => {
      setCanPurchase(false);
      const is_open = response.data.is_open;
      const message = response.data.message;
      if (is_open) {
        axiosInstance
          .post("sale/make_sale/", {
            district_id: district.id,
            indications: indications,
            points_used: pointsToUse,
            points_earned: Math.max(
              Math.floor(
                (currentItemPrice || state.total) -
                  (pointsToUse >= currentAmount ? 0 : +state?.deliveryFee) -
                  pointsToUse
              ) *
                (warehousePoint / 100),
              0
            ),
            isMobile: isMobile,
            selectedPayment,
            // success_url: "https://devfrontend.indilma.sn/confirmation",
            success_url: `${getCheckoutConfirmation(
              baseURLFront
            )}/confirmation?gain=${parseInt(
              Math.floor(
                currentAmount -
                  (pointsToUse >= currentAmount ? 0 : +state?.deliveryFee) -
                  pointsToUse
              ) *
                (warehousePoint / 100)
            )}&total=${currentAmount - pointsToUse}`,
            total:
              Math.floor(
                (currentItemPrice || state.total) + state.deliveryFee
              ) - pointsToUse,
          })
          .then((response) => {
            if (response.status === 200) {
              if (
                response.data &&
                typeof response.data === "string" &&
                response.data.startsWith("https")
              ) {
                return (window.location.href = response.data);
              } else if (response.data && typeof response.data === "string") {
                setQrCodeLink(response.data);
                return setQrCode(true);
              }

              navigate(
                `/confirmation?gain=${parseInt(
                  Math.floor(
                    currentAmount -
                      (pointsToUse >= currentAmount ? 0 : +state?.deliveryFee) -
                      pointsToUse
                  ) *
                    (warehousePoint / 100)
                )}&total=${currentAmount - pointsToUse}`,
                {
                  state: {
                    userData: userData,
                    total: currentAmount - pointsToUse,
                    deliveryFee: state.deliveryFee,
                    gain: parseInt(
                      Math.floor(
                        +currentAmount - +state?.deliveryFee - +pointsToUse
                      ) *
                        (+warehousePoint / 100)
                    ),
                  },
                  replace: true,
                }
              );
            } else {
              setShowPurchaseError(true);
              toast(
                "Une erreur est survenue. Merci de réessayer.",
                ToastTypes.ERROR
              );
              // axiosInstance.post("cart/clear/").then((response) => {
              //   setTimeout(() => {
              //     navigate("section", {});
              //   }, 300);
              // });
            }
          })
          .catch((error) => {
            setCanPurchase(true);
            setShowPurchaseError(true);
          })
          .finally(() => {
            setLoading(false); // Set loading to false once the process finishes
          });
      } else {
        // Toast shop is closed
        setCanPurchase(true);
        setLoading(false);
        toast(message, ToastTypes.ERROR);
      }
    });
  };

  return (
    <div className={styles.container}>
      <ShopHeader />

      <div style={{ paddingTop: "40px" }}>
        <div className={styles.boxContainer}>
          <div className="d-flex justify-content-between">
            <p>
              {userData?.user_data?.name} {userData?.user_data?.lastname}
            </p>
            {isActiveWareHouse ? (
              userPoints ? (
                userPoints >= 500 || currentAmount < userPoints ? (
                  <p className="fw-bold" style={{ color: "#20B880" }}>
                    {formatPrice(userPoints)} Points utilisables
                  </p>
                ) : null
              ) : null
            ) : null}
          </div>
          <div>{userData?.user_data?.phone_number}</div>

          <div className={"my-4"}>{userData?.user_data?.address}</div>

          <div className={"d-flex justify-content-between"}>
            <div>Total: </div>
            <div>
              <span className={"fw-bold"}>
                {" "}
                {formatPrice(currentAmount)} FCFA
              </span>
              {/* {pointsToUse > 0 ? (
                <>
                  <span style={{ textDecoration: "line-through" }}>
                    {formatPrice(
                      (currentItemPrice || state.total) + state.deliveryFee
                    )}{" "}
                    FCFA
                  </span>
                  <span className={"fw-bold"}>
                    {" "}
                    {formatPrice(
                      (currentItemPrice || state.total) +
                        state.deliveryFee -
                        pointsToUse
                    )}{" "}
                    FCFA
                  </span>
                </>
              ) : (
                `${formatPrice(
                  (currentItemPrice || state.total) + state.deliveryFee
                )} FCFA`
              )} */}
            </div>
          </div>
          <div className={"mt-2"}>
            <div className={"mb-1"}>Indications adresse:</div>
            <textarea
              value={indications}
              onChange={(e) => setIndications(e.target.value)}
              className={"w-100"}
              style={{ height: "90px" }}
            />
          </div>
          {isActiveWareHouse && (userPoints >= 500 || !!amountToPay) && (
            <div className={"mt-2 "}>
              <div className={"mb-1"}>Utiliser des points:</div>
              <div
                className="d-flex justify-content-center"
                style={{ gap: 10 }}
              >
                <h2
                  onClick={() => setPointsToUse(0)}
                  className={`point-option ${
                    pointsToUse === 0 ? "selected" : ""
                  }`}
                  style={{
                    cursor: "pointer",
                    padding: "10px 20px",
                    backgroundColor:
                      pointsToUse === 0 ? "#f0f0f0" : "transparent",
                    border:
                      pointsToUse === 0 ? "2px solid #000" : "1px solid #ccc",
                  }}
                >
                  0
                </h2>
                {/* {userPoints >= currentAmount && ( */}
                <h2
                  onClick={() => {
                    setPointsToUse(amountToPay);
                    if (+currentAmount - amountToPay == 0) {
                      setSelectedPayment("cash");
                    }
                  }}
                  className={`point-option ${
                    pointsToUse === amountToPay ? "selected" : ""
                  }`}
                  style={{
                    cursor: "pointer",
                    padding: "10px 20px",
                    border:
                      pointsToUse === amountToPay
                        ? "2px solid #000"
                        : "1px solid #ccc",
                    backgroundColor:
                      pointsToUse === amountToPay ? "#f0f0f0" : "transparent",
                    // color:
                  }}
                >
                  {formatPrice(amountToPay)}
                </h2>
                {/* )} */}
                {/* {userPoints >=
                  (currentItemPrice || state?.total) + state?.deliveryFee && (
                  <h2
                    onClick={() =>
                      setPointsToUse(
                        (currentItemPrice || state?.total) + state?.deliveryFee
                      )
                    }
                    className={`point-option ${
                      pointsToUse ===
                      (currentItemPrice || state?.total) + state?.deliveryFee
                        ? "selected"
                        : ""
                    }`}
                    style={{
                      cursor: "pointer",
                      padding: "10px 20px",
                      border:
                        pointsToUse ===
                        (currentItemPrice || state?.total) + state?.deliveryFee
                          ? "2px solid #000"
                          : "1px solid #ccc",
                      backgroundColor:
                        pointsToUse ===
                        (currentItemPrice || state?.total) + state?.deliveryFee
                          ? "#f0f0f0"
                          : "transparent",
                    }}
                  >
                    {(currentItemPrice || state?.total) + state?.deliveryFee}
                  </h2>
                )} */}
              </div>
            </div>
          )}

          {!qrCode ? (
            <>
              {/* Payment Method Selection */}
              <div className="mt-3">
                {loadingPayment ? (
                  <Loading />
                ) : (
                  <>
                    <h6>Moyens de paiement:</h6>
                    <div className="d-flex align-items-center justify-content-between">
                      {paymentMethods.map((method, index) => {
                        const methodName = method.name; // Adjust according to your response structure
                        const methodStatus = method.is_active; // Check if the method is active

                        // const isWaveDisabled =
                        //   methodName === "wave" && state.restaurant_id; // Condition to disable wave
                        const isPaymentMethodDisabled =
                          (state?.restaurant_id && methodName !== "cash") ||
                          (Math.floor(
                            currentAmount -
                              (pointsToUse >= currentAmount
                                ? 0
                                : +state?.deliveryFee) -
                              pointsToUse
                          ) *
                            (warehousePoint / 100) ===
                            0 &&
                            methodName !== "cash");
                        // Only render payment methods that are active and not disabled
                        if (methodStatus && !isPaymentMethodDisabled) {
                          return (
                            <div
                              key={index}
                              className="d-flex flex-column align-items-center mb-2"
                              onClick={() => setSelectedPayment(methodName)} // Only allow selection if not disabled
                              style={{
                                cursor: "pointer",
                                backgroundColor:
                                  selectedPayment === methodName
                                    ? "#f0f0f0"
                                    : "transparent",
                                padding: "10px",
                                borderRadius: "5px",
                                flex: "1",
                                textAlign: "center",
                                border:
                                  selectedPayment === methodName
                                    ? "2px solid #000"
                                    : "none",
                              }}
                            >
                              <img
                                src={`/assets/${methodName}.png`} // Assumes you have images named like the method name
                                alt={`${methodName} Payment`}
                                style={{ height: "40px", width: "auto" }}
                                className="mb-1"
                              />
                              <span>{methodName}</span>
                            </div>
                          );
                        }

                        return null; // Do not render if methodStatus is false or wave is disabled
                      })}
                    </div>
                    {pointsToUse ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: 10,
                          justifyContent: "space-between",
                        }}
                      >
                        <span>Montant final à payer : </span>
                        <h4>{formatPrice(currentAmount - amountToPay)} FCFA</h4>
                      </div>
                    ) : null}
                  </>
                )}
              </div>

              <div
                className={`${styles.orderButton} pe-2 ps-4 mt-3`}
                style={{
                  cursor: !loading ? "pointer" : "not-allowed",
                  opacity: loading || loadingPayment ? 0.5 : 1,
                  pointerEvents: loading || loadingPayment ? "none" : "auto",
                }}
                onClick={() => (loading ? null : makePurchase())}
              >
                <div className={"px-5 my-2"}>
                  {loading ? "Validation..." : " Confirmer ma commande"}
                </div>
              </div>
              {warehousePoint > 0 &&
                parseInt(
                  Math.floor(
                    currentAmount -
                      (pointsToUse >= currentAmount ? 0 : +state?.deliveryFee) -
                      pointsToUse
                  ) *
                    (warehousePoint / 100)
                ) > 0 && (
                  <div
                    className="text-center"
                    style={{
                      marginTop: 13,
                      fontWeight: "bold",
                    }}
                  >
                    Vous allez gagner{" "}
                    {formatPrice(
                      Math.floor(
                        currentAmount -
                          (pointsToUse >= currentAmount
                            ? 0
                            : +state?.deliveryFee) -
                          pointsToUse
                      ) *
                        (warehousePoint / 100)
                    )}{" "}
                    points Bring Me
                  </div>
                )}
            </>
          ) : (
            <div>
              <h5 className="text-center">
                Scannez ce QR code avec votre application
              </h5>
              <h5 className="text-center">Orange money / Maxit</h5>
              <img
                className="mx-auto d-block"
                src={`data:image/png;base64,${qrCodeLink}`}
                alt="QR Code de paiement"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
