import React, { useContext, useEffect, useState } from "react";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import styles from "../../styles/admin.module.css";
import TableView from "../../components/table_view";
import {
  faPenToSquare,
  faRecycle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import axiosInstance from "../../../AxiosInstance";
import myContext from "../../../MyContext";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../components/delete_modal/delete_modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatPrice } from "../../../utils/utils";

export default function SuppliersList() {
  const [rows, setRows] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const deleteObj = (id) => {
    axiosInstance.delete("biller/" + id + "/").then((response) => {
      window.location.reload();
    });
  };

  const { setEditing } = useContext(myContext);
  const navigate = useNavigate();
  const editObj = (data) => {
    setEditing(data);
    navigate("/admin/suppliers/create");
  };

  const cols = [
    { title: "ID", internal: "id" },
    { title: "Name", internal: "name" },
    { title: "Contact Name", internal: "contact_name" },
    { title: "Contact Number", internal: "contact_number" },
    { title: "Contact Email", internal: "contact_email" },
    { title: "City", internal: "city" },
    { title: "Automatic Order", internal: "automatic_order", isBoolean: true },
    {
      title: "Total Purchases",
      internal: "total_purchases",
      code: (row, data) => {
        return formatPrice(row.total_purchases); // Call your price formatting function here
      },
    },
    {
      title: "Ngor",
      internal: "warehouse_quantities.ngor",
      code: (data, row) => {
        return formatPrice(data?.warehouse_totals?.ngor);
      },
    },
    {
      title: "Plateau",
      internal: "warehouse_quantities.plateau",
      code: (data, row) => {
        return formatPrice(data?.warehouse_totals?.plateau);
      },
    },
    {
      title: "Point E",
      internal: "warehouse_quantities.point_e",
      code: (data, row) => {
        return formatPrice(data?.warehouse_totals?.point_e);
      },
    },
    {
      title: "No payed",
      internal: "t_no_payed",
      code: (data, row) => {
        return formatPrice(data?.t_no_payed);
      },
    },
    {
      title: "Actions",
      internal: "id",
      code: (data, row) => {
        return (
          <div className={"d-flex justify-content-end"}>
            <div
              role={"button"}
              onClick={() => editObj(data)}
              className={"pe-3"}
            >
              <FontAwesomeIcon icon={faPenToSquare} color={"#9EA3AC"} />
            </div>

            <div
              role={"button"}
              onClick={() => {
                setShowDelete(true);
                setSelectedId(row);
              }}
            >
              <FontAwesomeIcon icon={faTrash} color={"#EF4444"} />
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className={styles.mainContainer}>
      <AdminHeader />
      <AdminSidebar />

      <DeleteModal
        show={showDelete}
        setter={setShowDelete}
        onClick={() => deleteObj(selectedId)}
      />

      <div className={`${styles.container} me-4`}>
        <TableView
          fetchUrl={"biller"}
          pull={setRows}
          noPagination={true}
          title={"suppliers"}
          cols={cols}
          rows={rows}
          withIndex={true}
          useReactQuery={"supplier-list"}
        />
      </div>
    </div>
  );
}
