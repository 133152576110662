import React, { useState } from "react";
import styles from "../../styles/admin.module.css";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import TableView from "../../components/table_view";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axiosInstance from "../../../AxiosInstance";
import DeleteModal from "../../components/delete_modal/delete_modal";
import { useNavigate } from "react-router-dom";

export default function AdminAccountsList() {
  const [rows, setRows] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const navigate = useNavigate();

  const roleToName = (role) => {
    switch (role) {
      case 1:
        return "Admin";
      case 2:
        return "Warehouse";
      case 3:
        return "Biller";
      case 4:
        return "Manager";
    }
  };

  const deleteObj = (id) => {
    axiosInstance.delete("admin_accounts/" + id + "/").then((response) => {
      window.location.reload();
    });
  };

  const editObj = (data) => {
    navigate("/admin/admins/create", { state: data });
  };

  const cols = [
    {
      title: "Name",
      internal: "user_data.name",
      filter: true,
      filterInternal: "name",
    },
    {
      title: "Last Name",
      internal: "user_data.lastname",
      filter: true,
      filterInternal: "lastname",
    },
    {
      title: "Phone Number",
      internal: "user_data.phone_number",
      filter: true,
      filterInternal: "phone_number",
    },
    {
      title: "Email",
      internal: "email",
      filter: true,
      filterInternal: "email",
    },
    {
      title: "Role",
      internal: "user_data.role",
      code: (row, data) => roleToName(data),
    },
    {
      title: "Warehouse",
      internal: "user_data.warehouse_name",
      filter: true,
      filterInternal: "warehouse",
    },
    {
      title: "Biller",
      internal: "user_data.supplier_name",
      filter: true,
      filterInternal: "supplier",
    },
    { title: "Password", internal: "user_data.password" },
    {
      title: "Actions",
      internal: "id",
      code: (row, data) => {
        return (
          <div className={"d-flex justify-content-end"}>
            <div
              className={"pe-2"}
              style={{ cursor: "pointer" }}
              onClick={() => editObj(row)}
            >
              <FontAwesomeIcon icon={faPenToSquare} />
            </div>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                setSelectedId(row.id);
                setShowDelete(true);
              }}
            >
              <FontAwesomeIcon icon={faTrash} color={"#F00"} />
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className={styles.mainContainer}>
      <AdminHeader />
      <AdminSidebar />

      <DeleteModal
        show={showDelete}
        setter={setShowDelete}
        onClick={() => deleteObj(selectedId)}
      />

      <div className={`${styles.container} me-4`}>
        <TableView
          fetchUrl={"admin_accounts"}
          pull={setRows}
          noPagination={true}
          title={"admins"}
          cols={cols}
          rows={rows}
          useReactQuery={"admin-user-list"}
        />
      </div>
    </div>
  );
}
