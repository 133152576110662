// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-tooltip {
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
}
.custom-tooltip .label {
  font-weight: bold;
}
.custom-tooltip .intro,
.custom-tooltip .desc {
  margin: 0;
}

.chart-container {
}
`, "",{"version":3,"sources":["webpack://./src/admin/screens/warehouses/diagram.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,sBAAsB;EACtB,aAAa;EACb,kBAAkB;AACpB;AACA;EACE,iBAAiB;AACnB;AACA;;EAEE,SAAS;AACX;;AAEA;AACA","sourcesContent":[".custom-tooltip {\n  background-color: #fff;\n  border: 1px solid #ccc;\n  padding: 10px;\n  border-radius: 5px;\n}\n.custom-tooltip .label {\n  font-weight: bold;\n}\n.custom-tooltip .intro,\n.custom-tooltip .desc {\n  margin: 0;\n}\n\n.chart-container {\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
