import React, { useEffect, useState } from "react";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import styles from "../../styles/admin.module.css";
import TableView from "../../components/table_view";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import axiosInstance from "../../../AxiosInstance";
import { formatTimestamp } from "../../components/utils";
import { Modal as BootStrapModal } from "react-bootstrap";
import SaleDetailModal from "../closures/sale_detail/sale_detail";
import {
  DangerButton,
  PrimaryButton,
  SecondaryButton,
} from "../../components/inputs";
import modalstyles from "../../components/modal/modal.module.css";
import { formatPrice } from "../../../utils/utils";

export default function WaveTransactions() {
  const [rows, setRows] = useState([]);
  const [showSale, setShowSale] = useState(false);
  const [selectedSale, setSelectedSale] = useState({});
  const [showUnCancel, setShowUnCancel] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      axiosInstance.get(`wave/transactions/`).then((response) => {
        if (response.status === 200) {
          // Filter transactions to only include 'api_checkout'
          const filteredRows = response.data?.items?.filter(
            (transaction) => transaction.transaction_type === "api_checkout"
          );
          setRows(filteredRows);
        }
      });
    }, 35000);

    return () => clearInterval(interval);
  }, []);

  const cols = [
    {
      title: "Date",
      internal: "timestamp",
      code: (data, row) => formatTimestamp(data.timestamp),
    },
    { title: "Transaction ID", internal: "transaction_id", filter: true },
    {
      title: "Client",
      internal: "counterparty_name",
      filter: true,
    },
    {
      title: "Paiment numéro",
      internal: "counterparty_mobile",
      filter: true,
    },
    {
      title: "Montant",
      internal: "amount",
      code: (data, row) => {
        const total = parseFloat(data.amount) + parseFloat(+data.fee);
        return formatPrice(total);
      },
    },
  ];

  const view = (data) => {
    setSelectedSale(data);
    setShowSale(true);
  };

  return (
    <div className={`${styles.container} me-4`}>
      <TableView
        noCreate={true}
        title={"API Wave Transactions"}
        cols={cols}
        rows={rows}
        // useReactQuery={"wave-transaction-sale-list"}
      />
    </div>
  );
}
