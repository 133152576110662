import React from "react";
import {Modal as BootStrapModal} from "react-bootstrap";
import styles from "../modal/modal.module.css";
import {DangerButton, SecondaryButton} from "../inputs";

export default function DeleteModal({show, setter, onClick}) {
    return (
        <BootStrapModal show={show} centered={true} >
            <div className={styles.header}>
                <div>Delete</div>
                <div>Are you sure?</div>
            </div>

            <div className={styles.footer}>
                <SecondaryButton onClick={() => setter(false)} name={'Cancel'}/>
                <div className={'ms-3'}>
                    <DangerButton name={'Delete'} onClick={onClick}/>
                </div>
            </div>
        </BootStrapModal>
    )
}