import React, { useEffect, useState } from "react";
import styles from "../../styles/admin.module.css";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import {
  PrimaryButton,
  SecondaryButton,
  SelectInput,
  TextInput,
} from "../../components/inputs";
import axiosInstance from "../../../AxiosInstance";
import { useLocation, useNavigate } from "react-router-dom";
import { Select as AjonjoSelect } from "../../../ajonjolib/inputs/ajonjolinput";
import { queryClient } from "../../../App";

export default function AdminCreate() {
  const [form, setForm] = useState({});
  const { state } = useLocation();
  const [suppliers, setSuppliers] = useState([]);
  const [warehouses, setWarehouses] = useState([]);

  useEffect(() => {
    axiosInstance.get("supplier/").then((response) => {
      setSuppliers(response.data);
    });

    axiosInstance.get("warehouse/").then((response) => {
      setWarehouses(response.data);
    });
  }, []);

  useEffect(() => {
    if (state) {
      state.password = state.user_data.password;
      state.phone = state.user_data.phone_number;
      state.role = state.user_data.role;
      state.supplier = state.user_data.supplier;
      state.warehouse = state.user_data.warehouse;
      setForm(state);
    }
  }, [warehouses, suppliers]);

  const navigate = useNavigate();
  const back = () => {
    // window.location.replace("/admin/adminaccounts");
    navigate("/admin/adminaccounts", { replace: true });
  };

  const submit = () => {
    if (state) {
      console.log(form);
      //return;
      axiosInstance
        .put(`admin_accounts/${state.id}/`, form)
        .then((response) => {
          queryClient.invalidateQueries({
            queryKey: ["tableData-admin-user-list"],
          });
          queryClient.invalidateQueries({
            queryKey: ["tableData-user-list"],
          });
          back();
        });
    } else {
      axiosInstance.post("admin_accounts/", form).then((response) => {
        queryClient.invalidateQueries({
          queryKey: ["tableData-admin-user-list"],
        });
        queryClient.invalidateQueries({
          queryKey: ["tableData-user-list"],
        });
        back();
      });
    }
  };

  return (
    <div className={styles.mainContainer}>
      <AdminHeader />
      <AdminSidebar />

      <div className={`${styles.crudContainer}`}>
        <div className={styles.crudText}>
          <div className={"mb-2"}>Admin</div>
          <div />
        </div>

        <div className={styles.crudInputs}>
          <div className={`${styles.createContainer} p-4`}>
            <TextInput
              name={"name"}
              internalName={"first_name"}
              setForm={setForm}
              form={form}
            />
            <TextInput
              name={"Last Name"}
              internalName={"last_name"}
              setForm={setForm}
              form={form}
            />

            <TextInput name={"email"} setForm={setForm} form={form} />
            <TextInput name={"password"} setForm={setForm} form={form} />
            <TextInput name={"phone"} setForm={setForm} form={form} />

            <div
              style={{ textAlign: "left", color: "#4B5563", fontWeight: "500" }}
            >
              Role
            </div>
            <AjonjoSelect
              value={form["role"]}
              onChange={(val) => {
                setForm({ ...form, role: val[0] });
              }}
              options={[
                { value: 1, name: "Admin" },
                { value: 2, name: "Warehouse" },
                { value: 3, name: "Biller" },
                { value: 4, name: "Manager" },
              ]}
            />

            {form["role"] === 3 && (
              <div>
                <div
                  style={{
                    textAlign: "left",
                    color: "#4B5563",
                    fontWeight: "500",
                  }}
                >
                  Biller
                </div>
                <AjonjoSelect
                  value={form["supplier"]}
                  options={suppliers.map((sup) => {
                    return { value: sup.id, name: sup.name };
                  })}
                  onChange={(val) => {
                    setForm({ ...form, supplier: val[0] });
                  }}
                />
              </div>
            )}
            {form["role"] === 2 && (
              <div>
                <div
                  style={{
                    textAlign: "left",
                    color: "#4B5563",
                    fontWeight: "500",
                  }}
                >
                  Warehouse
                </div>
                <AjonjoSelect
                  value={form["warehouse"]}
                  options={warehouses.map((war) => {
                    return { value: war.id, name: war.name };
                  })}
                  onChange={(val) => {
                    setForm({ ...form, warehouse: val[0] });
                  }}
                />
              </div>
            )}
          </div>

          <div className={styles.buttonContainer}>
            <SecondaryButton name={"Back"} onClick={back} />
            <div className={"ps-3"} />
            <PrimaryButton
              name={state ? "Update" : "Create"}
              onClick={submit}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
