// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.confirmation_container__lj-2i {
    overflow-x: hidden;
    display: flex;
    justify-content: center;
}

.confirmation_boxContainer__HPa4i {
    max-width: 500px;
    font-weight: bold;
    margin-top: 160px;
    font-size: 1.2rem;
    padding: 10px;
    background-color: #FFFFFF;
    border-radius: 6px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    text-align: center;
}

.confirmation_orderButton__GkMw0 {
    background-color: #009F7F;
    border-radius: 40px;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 1.2rem;
    cursor: pointer;
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/shop/confirmation/confirmation.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,iBAAiB;IACjB,iBAAiB;IACjB,aAAa;IACb,yBAAyB;IACzB,kBAAkB;IAClB,yCAAyC;IACzC,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,cAAc;IACd,gBAAgB;IAChB,iBAAiB;IACjB,eAAe;IACf,kBAAkB;AACtB","sourcesContent":[".container {\n    overflow-x: hidden;\n    display: flex;\n    justify-content: center;\n}\n\n.boxContainer {\n    max-width: 500px;\n    font-weight: bold;\n    margin-top: 160px;\n    font-size: 1.2rem;\n    padding: 10px;\n    background-color: #FFFFFF;\n    border-radius: 6px;\n    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);\n    text-align: center;\n}\n\n.orderButton {\n    background-color: #009F7F;\n    border-radius: 40px;\n    color: #FFFFFF;\n    font-weight: 600;\n    font-size: 1.2rem;\n    cursor: pointer;\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `confirmation_container__lj-2i`,
	"boxContainer": `confirmation_boxContainer__HPa4i`,
	"orderButton": `confirmation_orderButton__GkMw0`
};
export default ___CSS_LOADER_EXPORT___;
