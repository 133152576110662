// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.adminheader_container__9as4Z {
    height: 78px !important;
    position: fixed;
    z-index: 1000;
}

.adminheader_userCircle__-UYr1 {
    border: #F3F4F6 solid 1px;
    padding: 2px 10px 2px 10px;
    border-radius: 50%;
    font-size: 1.5rem;
    cursor: pointer;
}

.adminheader_popupButton__\\+e\\+Xt {
    padding: 10px 12px;
    display: flex;
    align-items: center;
    cursor: pointer;
    -webkit-user-select: none;
            user-select: none;
}

.adminheader_popupButton__\\+e\\+Xt:hover {
    background-color: #F1F5F9;
}

.adminheader_popUpModule__JlNiE {
    position: absolute;
    top: 0;
    width: 200px;
    height: auto;
    background-color: #FFFFFF;
    box-shadow: #b4b6c0 0 0 2px;
    border-radius: 6px;
}

.adminheader_burger__oPHZK {
    padding: 4px 10px;
    cursor: pointer;
}

@media (max-width: 650px) {
    .adminheader_burger__oPHZK {
        display: block;
    }
}`, "",{"version":3,"sources":["webpack://./src/admin/components/styles/adminheader.module.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,eAAe;IACf,aAAa;AACjB;;AAEA;IACI,yBAAyB;IACzB,0BAA0B;IAC1B,kBAAkB;IAClB,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,yBAAiB;YAAjB,iBAAiB;AACrB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;IAClB,MAAM;IACN,YAAY;IACZ,YAAY;IACZ,yBAAyB;IACzB,2BAA2B;IAC3B,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI;QACI,cAAc;IAClB;AACJ","sourcesContent":[".container {\n    height: 78px !important;\n    position: fixed;\n    z-index: 1000;\n}\n\n.userCircle {\n    border: #F3F4F6 solid 1px;\n    padding: 2px 10px 2px 10px;\n    border-radius: 50%;\n    font-size: 1.5rem;\n    cursor: pointer;\n}\n\n.popupButton {\n    padding: 10px 12px;\n    display: flex;\n    align-items: center;\n    cursor: pointer;\n    user-select: none;\n}\n\n.popupButton:hover {\n    background-color: #F1F5F9;\n}\n\n.popUpModule {\n    position: absolute;\n    top: 0;\n    width: 200px;\n    height: auto;\n    background-color: #FFFFFF;\n    box-shadow: #b4b6c0 0 0 2px;\n    border-radius: 6px;\n}\n\n.burger {\n    padding: 4px 10px;\n    cursor: pointer;\n}\n\n@media (max-width: 650px) {\n    .burger {\n        display: block;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `adminheader_container__9as4Z`,
	"userCircle": `adminheader_userCircle__-UYr1`,
	"popupButton": `adminheader_popupButton__+e+Xt`,
	"popUpModule": `adminheader_popUpModule__JlNiE`,
	"burger": `adminheader_burger__oPHZK`
};
export default ___CSS_LOADER_EXPORT___;
