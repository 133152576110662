import React, { useEffect, useState } from "react";
import AdminHeader from "../components/header";
import styles from "../styles/admin.module.css";
import mystyles from "../styles/dashboard.module.css";
import AdminSidebar from "../components/sidebar";
import {
  faCartShopping,
  faDollar,
  faShop,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axiosInstance from "../../AxiosInstance";
import WarehouseMonthlyStatsChart from "./warehouses/MontlyStats";
import MonthlySalesStatsChart from "./warehouses/MonthlyBillersStats";

export default function Dashboard() {
  const [data, setData] = useState({});
  const permissions = parseInt(localStorage.getItem("permissions"));

  const cubes = [
    {
      title: "Total Revenue",
      subtitle: "(Last 30 Days)",
      unit: "$",
      value: "total_profit",
      icon: faDollar,
      bgColor: "#A7F3D0",
      color: "#0E805E",
    },
    {
      title: "Total Orders",
      subtitle: "(Last 30 Days)",
      unit: "",
      value: "sale_count",
      icon: faCartShopping,
      bgColor: "#FACACA",
      color: "#FD9191",
    },
    {
      title: "Today's Revenue",
      subtitle: "",
      unit: "$",
      value: "total_profit_today",
      icon: faDollar,
      bgColor: "#FFE8B2",
      color: "#FFC02D",
    },
    {
      title: "Total Shops",
      subtitle: "",
      unit: "",
      value: "total_shops",
      icon: faShop,
      bgColor: "#93C5FD",
      color: "#2C5CDC",
    },
  ];

  useEffect(() => {
    axiosInstance.get("dashboard/").then((response) => {
      setData(response?.data);
    });
  }, []);
  return (
    <div className={styles.mainContainer}>
      <AdminHeader />
      <AdminSidebar />

      {permissions === 1 && (
        <div className={styles.container}>
          <div className={`${mystyles.cubeContainer} mt-4`}>
            {cubes.map((cube, index) => (
              <div key={index} className={`${mystyles.cube} shadow-sm`}>
                <div className={"d-flex flex-row justify-content-between"}>
                  <div>
                    <div className={mystyles.title}>{cube.title}</div>
                    <div className={mystyles.subtitle}>{cube.subtitle}</div>
                  </div>
                  <div
                    className={mystyles.iconBall}
                    style={{ backgroundColor: cube.bgColor }}
                  >
                    <FontAwesomeIcon
                      icon={cube.icon}
                      color={cube.color}
                      size={"lg"}
                    />
                  </div>
                </div>
                <div className={"d-flex flex-row"}>
                  <div className={mystyles.cubeValue}>
                    {cube.unit}{" "}
                    {Object.keys(data).length > 0
                      ? data[cube.value]?.toLocaleString()
                      : ""}
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="px-5">
            <WarehouseMonthlyStatsChart />
          </div>
          <div className="px-5">
            <MonthlySalesStatsChart />
          </div>
        </div>
      )}
    </div>
  );
}
