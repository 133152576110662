import React, {useEffect} from "react";
import ShopHeader from "../components/header/header";

export default function AppLink() {

    useEffect(() => {
        // if android device open apstore link
        if (navigator.userAgent.match(/Android/i)) {
            //window.location.href = 'https://play.google.com/store/apps/details?id=com.angularfirebase.bringme&hl=fr';
            window.location.href = "https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.angularfirebase.bringme&hl=fr";
        } else if(navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
            window.location.href = 'https://apps.apple.com/us/app/bring-me-sénégal/id1508042789';
        } else {
            window.location.href = '/';
        }
    }, []);

    return (
        <div style={{overflowX: 'hidden', height: '100%'}}>
            <ShopHeader/>

            <div className={'d-flex flex-column h-100 px-4 justify-content-center align-items-center'}>
                <div className={'mb-5'}>
                    <a href={'https://apps.apple.com/us/app/bring-me-sénégal/id1508042789'} target={'_blank'}>
                        <img src={'/footer/app_store.png'} width={'100%'}/>
                    </a>
                </div>
                <div>
                    <a href={'https://play.google.com/store/apps/details?id=com.angularfirebase.bringme&hl=fr'} target={'_blank'}>
                        <img src={'/footer/play_store.png'} width={'97%'}/>
                    </a>
                </div>
            </div>
        </div>
    )
}