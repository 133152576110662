import React from "react";
import styles from './modal.module.css';
import {Modal as BootStrapModal} from "react-bootstrap";
import {PrimaryButton, SecondaryButton} from "../inputs";

export default function Modal({show, setter, name, description, children, buttonName, onClick}) {
    return (
        <BootStrapModal show={show} centered={true}>
            <div className={styles.header}>
                <div>{name}</div>
                <div>{description}</div>
            </div>

            <div className={styles.body}>
                {children}
            </div>

            <div className={styles.footer}>
                <SecondaryButton onClick={() => setter(false)} name={'Cancel'}/>
                <div className={'ms-3'}>
                    <PrimaryButton name={buttonName} onClick={onClick}/>
                </div>
            </div>
        </BootStrapModal>
    );
}