// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkout_container__CKfWj {
  overflow-x: hidden;
  display: flex;
  justify-content: center;
}

.checkout_boxContainer__E8Tv9 {
  margin-top: 80px;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 6px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  text-align: left;
}

.checkout_orderButton__jzqzk {
  background-color: #009f7f;
  border-radius: 40px;
  color: #ffffff;
  font-weight: 600;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.checkout_point-option__9fvq5.checkout_selected__dK8Xn {
  background-color: #f0f0f0; /* Couleur de fond pour l'option sélectionnée */
  border: 2px solid #000; /* Bordure plus épaisse pour l'option sélectionnée */
}

.checkout_point-option__9fvq5 {
  transition: background-color 0.3s; /* Ajoute une transition douce pour le changement de couleur */
}
`, "",{"version":3,"sources":["webpack://./src/shop/checkout/checkout.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,yBAAyB;EACzB,kBAAkB;EAClB,yCAAyC;EACzC,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,mBAAmB;EACnB,cAAc;EACd,gBAAgB;EAChB,iBAAiB;EACjB,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,eAAe;AACjB;;AAEA;EACE,yBAAyB,EAAE,+CAA+C;EAC1E,sBAAsB,EAAE,oDAAoD;AAC9E;;AAEA;EACE,iCAAiC,EAAE,8DAA8D;AACnG","sourcesContent":[".container {\n  overflow-x: hidden;\n  display: flex;\n  justify-content: center;\n}\n\n.boxContainer {\n  margin-top: 80px;\n  padding: 10px;\n  background-color: #ffffff;\n  border-radius: 6px;\n  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);\n  text-align: left;\n}\n\n.orderButton {\n  background-color: #009f7f;\n  border-radius: 40px;\n  color: #ffffff;\n  font-weight: 600;\n  font-size: 1.2rem;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  cursor: pointer;\n}\n\n.point-option.selected {\n  background-color: #f0f0f0; /* Couleur de fond pour l'option sélectionnée */\n  border: 2px solid #000; /* Bordure plus épaisse pour l'option sélectionnée */\n}\n\n.point-option {\n  transition: background-color 0.3s; /* Ajoute une transition douce pour le changement de couleur */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `checkout_container__CKfWj`,
	"boxContainer": `checkout_boxContainer__E8Tv9`,
	"orderButton": `checkout_orderButton__jzqzk`,
	"point-option": `checkout_point-option__9fvq5`,
	"selected": `checkout_selected__dK8Xn`
};
export default ___CSS_LOADER_EXPORT___;
