import React, { useEffect, useState } from "react";
import styles from "../../styles/admin.module.css";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import {
  BigTextInput,
  FileInput,
  PrimaryButton,
  SecondaryButton,
  TextInput,
} from "../../components/inputs";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import axiosInstance from "../../../AxiosInstance";

export default function ComboCreate() {
  const [newForm, setNewForm] = useState({});
  const [foundProducts, setFoundProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [subtotal, setSubtotal] = useState(0);
  const [timer, setTimer] = useState(null);
  const { state } = useLocation();
  const [startName, setStartName] = useState(state?.name);

  useEffect(() => {
    if (Object.keys(newForm).length > 2) {
      let newSubtotal = 0;
      let newTotalCost = 0;
      selectedProducts?.forEach((product) => {
        newSubtotal += product.price * product.quantity;
        newTotalCost += product.cost * product.quantity;
      });
      setSubtotal(newSubtotal);
      setNewForm({
        ...newForm,
        total_cost: newTotalCost,
      });
    }
  }, [selectedProducts]);

  useEffect(() => {
    if (timer) {
      clearTimeout(timer);
    }

    if (newForm["product_name"]?.trim() !== "") {
      const newTimer = setTimeout(() => {
        axiosInstance
          .get(
            `product/?max_query=5&no_combos=${true}&name=` +
              newForm["product_name"]
          )
          .then((response) => {
            setFoundProducts(response.data.results);
          });
      }, 400);

      setTimer(newTimer);
    }
  }, [newForm["product_name"]]);

  useEffect(() => {
    if (state) {
      state.total_cost = state.cost;
      setNewForm(state);

      let selected_products = state.selected_products;
      selected_products?.forEach((product) => {
        product.id = product.product;
      });
      setSelectedProducts(selected_products);
    }
  }, []);

  const back = () => {
    window.history.back();
  };

  const submit = () => {
    let formData = new FormData();
    for (const key in newForm) {
      formData.append(key, newForm[key]);
    }
    formData.append("products", JSON.stringify(selectedProducts));

    if (state) {
      axiosInstance
        .put("/product/" + state.id + "/update_combo/", formData)
        .then((response) => {
          back();
        });
    } else {
      axiosInstance
        .post("/product/create_combo/", formData)
        .then((response) => {
          back();
        });
    }
  };

  useEffect(() => {
    let new_name = newForm["name"];
    if (new_name !== startName && new_name) {
      new_name = new_name?.toLowerCase()?.replaceAll(" ", "-");
      setNewForm({
        ...newForm,
        slug: new_name,
      });
    }
  }, [newForm["name"]]);

  useEffect(() => {
    if (Object.keys(newForm).length > 2) {
      setNewForm({
        ...newForm,
        price: subtotal,
      });
    }
  }, [subtotal]);

  return (
    <div className={styles.mainContainer}>
      <AdminHeader />
      <AdminSidebar />

      <div className={`${styles.crudContainer}`}>
        <div className={styles.crudText}>
          <div className={"mb-2"}>Create Combo</div>
          <div></div>
        </div>

        <div className={styles.crudInputs}>
          <FileInput name={"image"} setForm={setNewForm} form={newForm} />
          <div className={`${styles.createContainer} p-4`}>
            <TextInput name={"name"} setForm={setNewForm} form={newForm} />
            <BigTextInput
              name={"description"}
              setForm={setNewForm}
              form={newForm}
            />
            <TextInput name={"slug"} setForm={setNewForm} form={newForm} />

            {/*Search*/}
            <div className={"mt-3"} />
            <TextInput
              form={newForm}
              setForm={setNewForm}
              name={"Product"}
              internalName={"product_name"}
            />
            <div>
              {foundProducts?.map((product, index) => (
                <div
                  key={index}
                  className={
                    "d-flex mb-2 px-2 py-2 align-items-center justify-content-between"
                  }
                  style={{
                    borderRadius: "6px",
                    border: "1px #ccc solid",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setSelectedProducts([
                      ...selectedProducts,
                      {
                        id: product.id,
                        name: product.name,
                        cost: product.cost,
                        quantity: 1,
                        price: product.price,
                      },
                    ]);
                    setFoundProducts([]);
                    setNewForm({ ...newForm, product_name: "" });
                  }}
                >
                  <div>{product.name}</div>
                  <FontAwesomeIcon icon={faPlus} color={"#009F7F"} />
                </div>
              ))}
            </div>
          </div>

          <div className={`${styles.createContainer} p-4`}>
            <div className={"d-flex justify-content-center"}>
              <table className="table table-bordered table-hover">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Product</th>
                    <th>Quantity</th>
                    <th>Unit Price</th>
                    <th>Total</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {selectedProducts?.map((product, index) => {
                    const handleQuantityChange = (event) => {
                      const updatedProducts = [...selectedProducts];
                      updatedProducts[index] = {
                        ...product,
                        quantity: parseFloat(event.target.value),
                      };
                      setSelectedProducts(updatedProducts);
                    };

                    const handlePriceChange = (event) => {
                      const updatedProducts = [...selectedProducts];
                      updatedProducts[index] = {
                        ...product,
                        price: parseFloat(event.target.value),
                      };
                      setSelectedProducts(updatedProducts);
                    };

                    const handleRemoveClick = () => {
                      const updatedProducts = [...selectedProducts];
                      updatedProducts.splice(index, 1); // Remove the product at the current index
                      setSelectedProducts(updatedProducts);
                    };

                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{product.name}</td>
                        <td>
                          <input
                            style={{ maxWidth: "90px" }}
                            type="number"
                            name="quantity"
                            value={product.quantity}
                            onChange={handleQuantityChange}
                          />
                        </td>
                        <td>
                          <input
                            style={{ maxWidth: "90px" }}
                            type="number"
                            name="price"
                            step={"0.01"}
                            min={"0"}
                            value={product.price}
                            onChange={handlePriceChange}
                          />
                        </td>
                        <td>
                          {parseFloat(product.price).toFixed(2) *
                            parseInt(product.quantity)}
                        </td>
                        <td>
                          <button onClick={handleRemoveClick}>Remove</button>
                        </td>
                      </tr>
                    );
                  })}
                  <tr>
                    <td colSpan={4} className={"text-end"}>
                      <b>Total</b>
                    </td>
                    <td>{subtotal}</td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className={styles.buttonContainer}>
            <SecondaryButton name={"Back"} onClick={back} />
            <div className={"ps-3"} />
            <PrimaryButton
              name={state ? "Update" : "Create Combo"}
              onClick={submit}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
