import React, { useEffect, useState } from "react";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import styles from "../../styles/admin.module.css";
import {
  PrimaryButton,
  SecondaryButton,
  SelectInput,
  TextInput,
} from "../../components/inputs";
import axiosInstance from "../../../AxiosInstance";
import { useLocation, useNavigate } from "react-router-dom";
import { queryClient } from "../../../App";

export default function DeliveryGuysCreate() {
  const { state } = useLocation();
  const [form, setForm] = useState({});
  const [warehouses, setWarehouses] = useState([]);

  useEffect(() => {
    if (state) {
      setForm({
        name: state.name,
        salary: state.salary,
        warehouse: state.warehouse?.id,
      });
    }

    axiosInstance.get("warehouse/").then((response) => {
      setWarehouses(response.data);
    });
  }, []);

  const navigate = useNavigate();
  const back = () => {
    // window.location.replace("/admin/deliveryguys");
    navigate("/admin/deliveryguys", { replace: true });
  };

  const create = () => {
    if (state) {
      axiosInstance.put(`delivery_guy/${state.id}/`, form).then((response) => {
        queryClient.invalidateQueries({
          queryKey: ["tableData-delivery-guys-list"],
        });
        back();
      });
    } else {
      axiosInstance.post("delivery_guy/", form).then((response) => {
        queryClient.invalidateQueries({
          queryKey: ["tableData-delivery-guys-list"],
        });
        back();
      });
    }
  };

  return (
    <div className={styles.mainContainer}>
      <AdminHeader />
      <AdminSidebar />

      <div className={`${styles.crudContainer}`}>
        <div className={styles.crudText}>
          <div className={"mb-2"}>Create Delivery Guy</div>
          <div></div>
        </div>
        <div className={styles.crudInputs}>
          <div className={`${styles.createContainer} p-4`}>
            <TextInput name={"name"} setForm={setForm} form={form} />
            <TextInput name={"salary"} setForm={setForm} form={form} />
            <SelectInput
              name={"warehouse"}
              setForm={setForm}
              form={form}
              options={warehouses}
            />
          </div>

          <div className={styles.buttonContainer}>
            <SecondaryButton name={"Back"} onClick={back} />
            <div className={"ps-3"} />
            <PrimaryButton
              name={state ? "Update" : "Create Delivery Guy"}
              onClick={create}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
