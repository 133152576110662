import React, {useEffect, useState} from "react";

export default function DeliveryText() {
    const [district, setDistrict] = useState({});

    useEffect(() => {
        const retrieved = localStorage.getItem('district');
        if(retrieved !== undefined) {
            setDistrict(JSON.parse(retrieved));
        }
    }, []);

    return (
        <div style={{
            marginTop: '1rem',
            display: 'flex',
            fontWeight: '500',
            fontSize: 'x-large'
        }}>
            <div>Livraison à {district?.name} - </div>
            <a
                style={{
                    color: '#00A6A8',
                    marginLeft: '0.3rem',
                    textDecoration: 'none',
                    cursor: 'pointer'
                }}
                href={'/locate'}>Modifier</a>
        </div>
    );
}