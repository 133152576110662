import React, { useEffect, useState } from "react";
import styles from "../home/home.module.css";
import ShopHeader from "../components/header/header";
import Footer from "../components/footer/footer";
import axiosInstance from "../../AxiosInstance";
import { PrimaryButton, TextInput } from "../../admin/components/inputs";
import GoogleMapReact from "google-map-react";
import PageTitle from "../components/page_title/page_title";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapPin } from "@fortawesome/free-solid-svg-icons";
import LoginPopup from "../components/login_popup/login_popup";
import Select from "../../components/ajonjolib/inputs/select/select";
import {
  ToastTypes,
  toast,
} from "../../components/ajonjolib/toasts/toast/toast";
import { useNavigate } from "react-router-dom";

export default function Locate() {
  const [districts, setDistricts] = useState([]);
  const [form, setForm] = useState({
    address: "MGXJ+FG9, Dakar, Senegal",
    lat: 14.698311,
    lng: -17.46878,
  });
  const [center, setCenter] = useState({ lat: 14.698311, lng: -17.46878 });
  const [address, setAddress] = useState("MGXJ+FG9, Dakar, Senegal");
  const [showLogin, setShowLogin] = useState(false);
  const [lockCenter, setLockCenter] = useState(false);
  const [selectedDistrict, setSelectedDistrict] = useState(null);

  let authenticated = localStorage.getItem("access_token") !== null;

  const navigate = useNavigate();

  const mapOptions = {
    center: {
      lat: 14.698311,
      lng: -17.46878,
    },
    zoom: 16,
  };

  const findAddress = async (lat, lng, override) => {
    setCenter({ lat, lng });
    setLockCenter(true);
    console.log({ lat, lng });

    setForm({ ...form, lat, lng });

    try {
      const response = await axiosInstance.post(`set_location/`, {
        latitude: lat,
        longitude: lng,
      });

      if (response.data) {
        return setSelectedDistrict(response.data?.id);
      }
      toast(
        "Nous ne livrons pas à cette adresse pour le moment",
        ToastTypes.ERROR
      );
    } catch (error) {
      console.error("Error finding address", error);
    }
  };

  useEffect(() => {
    document.title = "Locate | Bring Me";
    axiosInstance.get("district/").then((response) => {
      setDistricts(response.data);
    });

    setTimeout(() => localizeMe(true), 10);
  }, []);

  const getAddresValidation = async () => {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${form.lat},${form.lng}&key=AIzaSyBZmhQsod4pJDQvuctemtOfYnaRzOMsttg`
    );
    const data = await response.json();

    if (data.results.length > 0) {
      return data.results[0].formatted_address;
    }

    return null;
  };

  const submit = async () => {
    const addressResponse = await getAddresValidation();
    if (!addressResponse) {
      return;
    }

    if (addressResponse !== "" && addressResponse) {
      axiosInstance
        .post(`set_location/`, {
          latitude: form.lat,
          longitude: form.lng,
          address: addressResponse,
        })
        .then((response) => {
          if (response.data) {
            localStorage.setItem("district", JSON.stringify(response.data));
            window.location.replace("/");
            // navigate("/", { replace: true });
          } else {
            toast(
              "Nous ne livrons pas à cette adresse pour le moment",
              ToastTypes.ERROR
            );
          }
        })
        .catch((error) => console.log("heljsdfk"));
    }
  };

  const handlePinClick = ({ lat, lng }) => {
    findAddress(lat, lng, false);
  };

  const localizeMe = (override) => {
    console.log("localizeMe");
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setForm({
          ...form,
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
        findAddress(
          position.coords.latitude,
          position.coords.longitude,
          override
        );
      },
      (error) => {
        if (error.code === error.PERMISSION_DENIED) {
          alert(
            'La localisation est bloquée. Veuillez autoriser l\'accès à la localisation pour utiliser "me localiser".'
          );
        } else {
          alert(
            "Une erreur est survenue lors de la tentative de localisation."
          );
        }
      }
    );
  };

  return (
    <div className={styles.mainContainer}>
      <ShopHeader />
      <LoginPopup
        show={showLogin}
        setShow={setShowLogin}
        onSuccess={() => setShowLogin(false)}
      />

      <div style={{ paddingTop: "80px" }} className={"pb-5"}>
        <div className={styles.hideMobile}>
          <PageTitle name={"Où êtes-vous ?"} />
        </div>
        <div
          className={`d-flex justify-content-between px-4 flex-wrap ${styles.responsiveContainer}`}
        >
          <div className={styles.halfContainer}>
            <div className={"mb-2"} />
            <div className={styles.mapResponsive}>
              <GoogleMapReact
                options={{
                  fullscreenControl: false,
                  zoomControl: false,
                }}
                onClick={handlePinClick}
                defaultCenter={mapOptions.center}
                defaultZoom={mapOptions.zoom}
                bootstrapURLKeys={{
                  key: "AIzaSyBZmhQsod4pJDQvuctemtOfYnaRzOMsttg",
                }}
                center={center}
                onChange={(event) => {
                  findAddress(event.center.lat, event.center.lng, false);
                }}
                yesIWantToUseGoogleMapApiInternals
              >
                <div
                  lat={form.lat}
                  lng={form.lng}
                  className={"d-flex justify-content-center align-items-center"}
                >
                  <FontAwesomeIcon icon={faMapPin} color={"#F00"} size={"xl"} />
                </div>
              </GoogleMapReact>
              <div
                style={{
                  position: "absolute",
                  marginTop: "-70px",
                  display: "flex",
                  justifyContent: "center",
                  marginRight: "1rem",
                  right: "0",
                }}
              >
                <PrimaryButton
                  name={"Me localiser"}
                  onClick={() => localizeMe(false)}
                />
              </div>
            </div>

            <div className={"mb-2"} />
          </div>
          <div className={styles.halfContainer}>
            <div
              className={"mb-1"}
              style={{ color: "#4B5563", textAlign: "left", fontWeight: "500" }}
            >
              Quartier
            </div>
            <Select
              options={districts?.map((x) => {
                return { name: x.name, value: x.id };
              })}
              value={selectedDistrict}
              onChange={(value) => {
                setLockCenter(true);
                const found = districts.find(
                  (district) => district.id === value[0]
                );

                if (found) {
                  findAddress(found.latitude, found.longitude, false);
                }
                setSelectedDistrict(value[0]);
              }}
            />
            <div className={"mb-3"} />
            <PrimaryButton name={"Valider"} onClick={submit} />
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
