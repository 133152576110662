import React, {useState} from 'react';
import styles from "../../styles/admin.module.css";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import TableView from "../../components/table_view";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit} from "@fortawesome/free-solid-svg-icons";
import {useNavigate} from "react-router-dom";

export default function ImageAlerts() {
    const [rows, setRows] = useState([]);
    const navigate = useNavigate();

    const cols = [
        { title: "Product", internal: "name"},
        { title: "Biller", internal: "biller_name" , code: (row, data) => {return data ? data : '-'}},
        { title: "Fix", internal: "id", code: (row, data) => {
            return (
                <div>
                    <FontAwesomeIcon icon={faEdit} size="sm" role={'button'} onClick={() =>
                        navigate('/admin/products/create', {state: data})
                    } />
                </div>
            )
            } },
    ]

    return (
        <div className={styles.mainContainer}>
            <AdminHeader/>
            <AdminSidebar/>

            <div className={`${styles.container} me-4`}>
                <TableView
                    fetchUrl={'product/image_alert'}
                    pull={setRows}
                    title={'Image Alerts'}
                    cols={cols}
                    rows={rows}
                    noCreate={true}
                />
            </div>
        </div>
    )
}
