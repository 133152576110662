// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.shop_header_container__ZeY3O {
    display: flex;
    justify-content: space-between;
    background-color: #FFF;
    position: fixed;
    height: 80px;
    width: 100%;
    box-shadow: #6B728C 0 0 2px;
    -webkit-user-select: none;
            user-select: none;
    z-index: 1001;
}

.shop_header_innerContainer__1WcPj {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #FFF;
    position: fixed;
    height: 80px;
    width: 100%;
    box-shadow: #6B728C 0 0 2px;
    -webkit-user-select: none;
            user-select: none;
    padding: 0 3rem;
}

@media (max-width: 650px) {
    .shop_header_innerContainer__1WcPj {
        padding: 0 1rem;
    }
}`, "",{"version":3,"sources":["webpack://./src/shop/components/header/shop_header.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,sBAAsB;IACtB,eAAe;IACf,YAAY;IACZ,WAAW;IACX,2BAA2B;IAC3B,yBAAiB;YAAjB,iBAAiB;IACjB,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,sBAAsB;IACtB,eAAe;IACf,YAAY;IACZ,WAAW;IACX,2BAA2B;IAC3B,yBAAiB;YAAjB,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI;QACI,eAAe;IACnB;AACJ","sourcesContent":[".container {\n    display: flex;\n    justify-content: space-between;\n    background-color: #FFF;\n    position: fixed;\n    height: 80px;\n    width: 100%;\n    box-shadow: #6B728C 0 0 2px;\n    user-select: none;\n    z-index: 1001;\n}\n\n.innerContainer {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    background-color: #FFF;\n    position: fixed;\n    height: 80px;\n    width: 100%;\n    box-shadow: #6B728C 0 0 2px;\n    user-select: none;\n    padding: 0 3rem;\n}\n\n@media (max-width: 650px) {\n    .innerContainer {\n        padding: 0 1rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `shop_header_container__ZeY3O`,
	"innerContainer": `shop_header_innerContainer__1WcPj`
};
export default ___CSS_LOADER_EXPORT___;
