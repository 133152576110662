import React, { useState } from "react";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import axiosInstance from "../../../AxiosInstance";
import styles from "../../styles/admin.module.css";
import modalstyles from "../../components/modal/modal.module.css";
import { Modal as BootStrapModal } from "react-bootstrap";
import {
  PrimaryButton,
  SecondaryButton,
  SelectInput,
  TextInput,
} from "../../components/inputs";
import TableHeader from "../../components/table_header";
import TableComponent from "../../components/table";
import { queryClient } from "../../../App";
import { formatTimestamp } from "../../components/utils";
import Loading from "../../components/loading";
import TableView from "../../components/table_view";

function UsersListPointHistoriquePage() {
  // const { id } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);

  const [showCancel, setShowCancel] = useState(false);
  const [form, setForm] = useState({
    reason: null,
    action_type: null,
    points: 0,
  });

  const actionReasons = +state?.points
    ? [
        { id: "add", name: "Attribuer" },
        { id: "remove", name: "Retirer" },
      ]
    : [{ id: "add", name: "Attribuer" }];

  // const userPointQuery = useQuery({
  //   queryKey: [`user-point`, { query: "" }],
  //   queryFn: async () => {
  //     const { data } = await axiosInstance.get(`users/update-points/`);
  //     return data;
  //   },
  //   enabled: !!id,
  // });

  const handleAttributPoint = async () => {
    if (!form?.action_type || !form?.points || !form?.reason) {
      return alert("Veuillez mettre toute les champs");
    }

    if (form?.action_type === "remove" && +form?.points > +state?.points) {
      return alert(
        "Le nombre de points attribués est supérieur aux points actuels."
      );
    }

    const { data } = await axiosInstance.post(`users/update-points/`, {
      ...form,
      points: +form?.points,
      // user_id: +id,
    });

    if (data) {
      queryClient.invalidateQueries({ queryKey: ["tableData-user-list"] });
      queryClient.invalidateQueries({ queryKey: ["user-point"] });
      let newPointValue = +state?.points;

      if (form?.action_type === "add") {
        newPointValue += +form?.points;
      } else {
        newPointValue -= +form?.points;

        if (newPointValue < 0) {
          newPointValue = 0;
        }
      }

      // navigate(`/admin/users/point/${id}`, {
      //   replace: true,
      //   state: { ...state, points: newPointValue },
      // });
    }

    setShowCancel(false);
  };

  const cols = [
    {
      title: "Date",
      internal: "created_at",
      code: (_, row) => formatTimestamp(row),
    },
    {
      title: "Email",
      internal: "user_data.email",
    },
    {
      title: "Prenom nom",
      internal: "user_data.lastname",
      code: (row, data) => <p>{data + " " + row?.user_data?.name}</p>,
    },
    {
      title: "Points",
      internal: "points",
      code: (data, row) => (
        <b>
          {((data?.action_type === "achat" || data?.action_type === "remove") &&
            "- ") + data?.points}
        </b>
      ),
    },
    {
      title: "Utilisables",
      internal: "user_data.points",
      code: (data, row) => (
        <div
          style={{
            fontSize: 16,
            flexDirection: "row",
            display: "flex",
            gap: 10,
          }}
        >
          <p>OLD : <b>{data?.current_points}</b></p>
          {`-->`}
          <p>NEW : <b>{row}</b></p>
        </div>
      ),
    },
    {
      title: "Warehouse",
      internal: "warehouse.name",
      code: (data, row) =>
        data?.supplier?.name ? "-" : data?.warehouse?.name || "-",
    },
    {
      title: "Biller",
      internal: "supplier.name",
      code: (data, row) => data?.supplier?.name || "-",
    },
    { title: "Reason", internal: "reason" },
    {
      title: "Actions",
      internal: "action_type",
      code: (data, row) =>
        actionReasons.find(
          (reason) =>
            data?.action_type?.toLowerCase() === reason?.id?.toLowerCase()
        )?.name ||
        data?.action_type ||
        "-",
    },
  ];

  return (
    <div className={styles.mainContainer}>
      <AdminHeader />
      <AdminSidebar />

      <div className={`${styles.container} me-4`}>
        <TableView
          title={"Historique des points"}
          fetchUrl={"points/history"}
          cols={cols}
          pull={setRows}
          rows={rows}
          withIndex={false}
          noCreate={true}
          limit={50}
          useReactQuery={"historique-points-list"}
          refetchInterval={30000}
        />
      </div>

      {/* <BootStrapModal show={showCancel} centered={true}>
        <div className={modalstyles.header}>
          <div>Gestion des poinst</div>
        </div>

        <div className={modalstyles.body}>
          <div className="mb-3">
            <SelectInput
              options={actionReasons}
              form={form}
              setForm={setForm}
              name={"action_type"}
              internalName={"action_type"}
            />
          </div>
          <div className="mb-3">
            <TextInput
              name={"points"}
              type={"number"}
              placeholder={"Provide points..."}
              form={form}
              setForm={setForm}
              internalName={"points"}
            />
          </div>
          <div className="mb-3">
            <TextInput
              name={"reason"}
              placeholder={"Provide additional details..."}
              form={form}
              setForm={setForm}
              internalName={"reason"}
            />
          </div>
        </div>

        <div className={modalstyles.footer}>
          <SecondaryButton onClick={() => setShowCancel(false)} name={"Back"} />
          <div className={"ms-3"}>
            <PrimaryButton
              name={"valider"}
              onClick={handleAttributPoint}
              disabled={!form.reason || !form.points || !form.action_type}
            />
          </div>
        </div>
      </BootStrapModal> */}

      {/* <div className={`${styles.container} me-4`}>
        <TableHeader
          title={
            "La liste des points de " +
            state?.userName +
            " . | . Point total : " +
            state?.points
          }
          noCreate={true}
          withImport={false}
        />
        <div
          style={{
            margin: "20px 0",
            marginLeft: "auto",
            display: "flex",
            gap: 10,
            justifyContent: "flex-end",
          }}
        >
          <SecondaryButton name={"Back"} onClick={() => navigate(-1)} />
          <PrimaryButton
            name={"Ajouter des points"}
            onClick={() => setShowCancel(true)}
          />
        </div>
        {userPointQuery?.isLoading ? (
          <Loading />
        ) : (
          <div style={{ overflowX: "scroll" }}>
            <TableComponent cols={cols} rows={userPointQuery?.data || []} />
          </div>
        )}
      </div> */}
    </div>
  );
}

export default UsersListPointHistoriquePage;
