import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axiosInstance from "../../../AxiosInstance";
import { formatPrice, getMonthName } from "../../../utils/utils";
import Loading from "../../components/loading";

export default function BillerPayment() {
  const { slug } = useParams();
  const [billerData, setBillerData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingPayment, setLoadingPayment] = useState(true);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedPayment, setSelectedPayment] = useState("wave");
  const [qrCodeLink, setQrCodeLink] = useState("");
  const [qrCode, setQrCode] = useState(false);

  useEffect(() => {
    // Fetch biller data using slug
    axiosInstance
      .get(`/payment/${slug}/`)
      .then((response) => {
        setBillerData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching biller data:", error);
        setLoading(false);
      });

    // Fetch payment methods
    axiosInstance
      .get("/payment-methods/")
      .then((response) => {
        // Filter only wave and orange money
        const filteredMethods = response.data.filter(
          (method) =>
            method.name === "wave" ||
            method.name === "om" ||
            method.name === "maxit"
        );
        setPaymentMethods(filteredMethods);
        setLoadingPayment(false);
      })
      .catch((error) => {
        console.error("Error fetching payment methods:", error);
        setLoadingPayment(false);
      });
  }, [slug]);

  const handlePayment = () => {
    setLoading(true);
    axiosInstance
      .post(`/billers/payment/${slug}/process/`, {
        payment_method: selectedPayment,
      })
      .then((response) => {
        if (response.data && typeof response.data === "string") {
          if (response.data.startsWith("https")) {
            window.location.href = response.data;
          } else {
            setQrCodeLink(response.data);
            setQrCode(true);
          }
        }
      })
      .catch((error) => {
        console.error("Payment error:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="container mt-5">
      <div className="card p-4">
        <h2 className="text-center mb-4">{billerData?.supplier_name}</h2>

        <div className="row mb-4">
          <div className="col-md-6">
            <div className="card p-3">
              <h5>Commission du mois de{getMonthName(billerData?.month)}</h5>
              <h3 className="text-primary">
                {formatPrice(billerData?.month_commission)} FCFA
              </h3>
              <small>
                {getMonthName(billerData?.month)} {billerData?.year}
              </small>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card p-3">
              <h5>Commission totale</h5>
              <h3 className="text-success">
                {formatPrice(billerData?.total_commission)} FCFA
              </h3>
              <small>
                Total payé: {formatPrice(billerData?.total_payed)} FCFA
              </small>
            </div>
          </div>
        </div>

        {!qrCode ? (
          <>
            <div className="payment-methods mt-4">
              <h5 className="mb-3">Sélectionnez un moyen de paiement:</h5>
              {loadingPayment ? (
                <Loading />
              ) : (
                <div className="d-flex flex-wrap justify-content-center gap-4">
                  {paymentMethods.map(
                    (method, index) =>
                      method.is_active && (
                        <div
                          key={index}
                          className={`payment-option p-2 text-center ${
                            selectedPayment === method.name
                              ? "border border-primary"
                              : ""
                          }`}
                          // style={{
                          //   cursor: "pointer",
                          //   backgroundColor:
                          //     selectedPayment === method.name
                          //       ? "#f8f9fa"
                          //       : "white",
                          //   borderRadius: "8px",
                          //   minWidth: "80px",
                          //   maxWidth: "80px",
                          //   flex: "1 1 auto",
                          // }}
                          style={{
                            cursor: "pointer",
                            backgroundColor:
                              selectedPayment === method.name
                                ? "#f0f0f0"
                                : "transparent",
                            padding: "10px",
                            borderRadius: "5px",
                            flex: "1",
                            textAlign: "center",
                            border:
                              selectedPayment === method.name
                                ? "2px solid #000"
                                : "none",
                          }}
                          onClick={() => setSelectedPayment(method.name)}
                        >
                          <img
                            src={`/assets/${method.name}.png`}
                            alt={method.name}
                            style={{
                              height: "30px",
                              width: "auto",
                              display: "block",
                              margin: "auto",
                            }}
                            className="mb-1"
                          />
                          <div style={{ fontSize: "14px" }}>{method.name}</div>
                        </div>
                      )
                  )}
                </div>
              )}
            </div>

            <button
              className="btn btn-primary mt-4 w-100"
              onClick={handlePayment}
              disabled={loading || loadingPayment}
            >
              {loading ? "Traitement..." : "Payer maintenant"}
            </button>
          </>
        ) : (
          <div className="text-center">
            <h5>Scannez ce QR code avec votre application</h5>
            <h6>Orange money / Wave</h6>
            <img
              className="mx-auto d-block mt-3"
              src={`data:image/png;base64,${qrCodeLink}`}
              alt="QR Code de paiement"
              style={{ maxWidth: "300px" }}
            />
          </div>
        )}
      </div>
    </div>
  );
}
