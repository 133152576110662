// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login_textLabel__qGu9N {
    color: #4B5563;
    font-weight: 500;
    font-size: medium;
}

.login_labelLeft__etY6A {
    text-align: left;
}

.login_borderLogin__UeFPx {
    border-color: #D1D5DB !important;
    padding: 10px;
}

.login_forgotText__q3v2X {
    color: #009F7F;
    text-decoration: none;
    font-size: small;
}

.login_passwordToggleIcon__nzt2F {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}

.login_formControl__AKktj {
    padding-right: 40px;
}

.login_loginButton__8MUGZ {
    width: 100%;
    padding: 10px !important;
    background-color: #009F7F !important;
    font-weight: 500;
}

.login_passwordInputGroup__d\\+EYc {
    display: flex;
    align-items: center;
    position: relative;
}

.login_passwordInput__3WIzt {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding: 12px !important;
    font-size: 0.9rem !important;
}

.login_passwordToggle__AR1-B {
    z-index: 9999;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.login_passwordToggleIcon__nzt2F {
    font-size: 1.2rem;
}

.login_imageText__bwmgN {
    color: #6B7280;
}`, "",{"version":3,"sources":["webpack://./src/styles/login.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,gCAAgC;IAChC,aAAa;AACjB;;AAEA;IACI,cAAc;IACd,qBAAqB;IACrB,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,WAAW;IACX,QAAQ;IACR,2BAA2B;AAC/B;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,wBAAwB;IACxB,oCAAoC;IACpC,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,0BAA0B;IAC1B,6BAA6B;IAC7B,wBAAwB;IACxB,4BAA4B;AAChC;;AAEA;IACI,aAAa;IACb,eAAe;IACf,kBAAkB;IAClB,QAAQ;IACR,MAAM;IACN,SAAS;IACT,yBAAyB;IACzB,4BAA4B;AAChC;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".textLabel {\n    color: #4B5563;\n    font-weight: 500;\n    font-size: medium;\n}\n\n.labelLeft {\n    text-align: left;\n}\n\n.borderLogin {\n    border-color: #D1D5DB !important;\n    padding: 10px;\n}\n\n.forgotText {\n    color: #009F7F;\n    text-decoration: none;\n    font-size: small;\n}\n\n.passwordToggleIcon {\n    cursor: pointer;\n    position: absolute;\n    right: 10px;\n    top: 50%;\n    transform: translateY(-50%);\n}\n\n.formControl {\n    padding-right: 40px;\n}\n\n.loginButton {\n    width: 100%;\n    padding: 10px !important;\n    background-color: #009F7F !important;\n    font-weight: 500;\n}\n\n.passwordInputGroup {\n    display: flex;\n    align-items: center;\n    position: relative;\n}\n\n.passwordInput {\n    border-top-right-radius: 0;\n    border-bottom-right-radius: 0;\n    padding: 12px !important;\n    font-size: 0.9rem !important;\n}\n\n.passwordToggle {\n    z-index: 9999;\n    cursor: pointer;\n    position: absolute;\n    right: 0;\n    top: 0;\n    bottom: 0;\n    border-top-left-radius: 0;\n    border-bottom-left-radius: 0;\n}\n\n.passwordToggleIcon {\n    font-size: 1.2rem;\n}\n\n.imageText {\n    color: #6B7280;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textLabel": `login_textLabel__qGu9N`,
	"labelLeft": `login_labelLeft__etY6A`,
	"borderLogin": `login_borderLogin__UeFPx`,
	"forgotText": `login_forgotText__q3v2X`,
	"passwordToggleIcon": `login_passwordToggleIcon__nzt2F`,
	"formControl": `login_formControl__AKktj`,
	"loginButton": `login_loginButton__8MUGZ`,
	"passwordInputGroup": `login_passwordInputGroup__d+EYc`,
	"passwordInput": `login_passwordInput__3WIzt`,
	"passwordToggle": `login_passwordToggle__AR1-B`,
	"imageText": `login_imageText__bwmgN`
};
export default ___CSS_LOADER_EXPORT___;
