// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.adminsidebar_container__freRR {
    width: 275px;
    background-color: #FFF;
    height: 100%;
    padding-top: 90px;
    position: fixed;
    overflow-y: scroll;
    transition: width linear 0.1s;
    z-index: 999;
}

.adminsidebar_listItem__lKoQo {
    color: rgb(75,85,99);
    font-size: 1rem !important;
    padding: 11px;
    cursor: pointer;
    line-height: 1.5rem;
    text-decoration: none;
    -webkit-user-select: none;
            user-select: none;
}

.adminsidebar_hide__xnSt4 {
    width: 0;
}`, "",{"version":3,"sources":["webpack://./src/admin/components/styles/adminsidebar.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,sBAAsB;IACtB,YAAY;IACZ,iBAAiB;IACjB,eAAe;IACf,kBAAkB;IAClB,6BAA6B;IAC7B,YAAY;AAChB;;AAEA;IACI,oBAAoB;IACpB,0BAA0B;IAC1B,aAAa;IACb,eAAe;IACf,mBAAmB;IACnB,qBAAqB;IACrB,yBAAiB;YAAjB,iBAAiB;AACrB;;AAEA;IACI,QAAQ;AACZ","sourcesContent":[".container {\n    width: 275px;\n    background-color: #FFF;\n    height: 100%;\n    padding-top: 90px;\n    position: fixed;\n    overflow-y: scroll;\n    transition: width linear 0.1s;\n    z-index: 999;\n}\n\n.listItem {\n    color: rgb(75,85,99);\n    font-size: 1rem !important;\n    padding: 11px;\n    cursor: pointer;\n    line-height: 1.5rem;\n    text-decoration: none;\n    user-select: none;\n}\n\n.hide {\n    width: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `adminsidebar_container__freRR`,
	"listItem": `adminsidebar_listItem__lKoQo`,
	"hide": `adminsidebar_hide__xnSt4`
};
export default ___CSS_LOADER_EXPORT___;
