import { createContext, useState } from "react";

const MyContext = createContext("");

export function ContextProvider({ children }) {
  const [editing, setEditing] = useState(0);
  const [product, setProduct] = useState(0);
  const [order, setOrder] = useState({});
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <MyContext.Provider
      value={{
        editing,
        setEditing,
        product,
        setProduct,
        setOrder,
        order,
        setSidebarOpen,
        sidebarOpen,
      }}
    >
      {children}
    </MyContext.Provider>
  );
}

export default MyContext;
