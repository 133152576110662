import React, { useEffect, useState } from "react";
import styles from "../../styles/admin.module.css";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import TableView from "../../components/table_view";

export default function QuantityAlerts() {
  const [rows, setRows] = useState([]);

  const evaluateQuantity = (data, row) => {
    if (row === undefined) row = 0;
    if (data.alert_quantity > row) {
      return (
        <div
          style={{
            backgroundColor: "#d9534F",
            borderRadius: "6px",
            color: "#FFF",
            textAlign: "center",
            fontSize: "large",
          }}
        >
          {row}
        </div>
      );
    } else {
      return (
        <div
          style={{
            backgroundColor: "#4fd98b",
            borderRadius: "6px",
            color: "#FFF",
            textAlign: "center",
            fontSize: "large",
          }}
        >
          {row}
        </div>
      );
    }
  };

  const cols = [
    { title: "Product", internal: "name" },
    {
      title: "Ngor",
      internal: "warehouse_quantities.ngor",
      code: (data, row) => {
        return evaluateQuantity(data, row);
      },
    },
    {
      title: "Plateau",
      internal: "warehouse_quantities.plateau",
      code: (data, row) => {
        return evaluateQuantity(data, row);
      },
    },
    {
      title: "Dépôt",
      internal: "warehouse_quantities.depot",
      code: (data, row) => {
        return evaluateQuantity(data, row);
      },
    },
    {
      title: "Point E",
      internal: "warehouse_quantities.point_e",
      code: (data, row) => {
        return evaluateQuantity(data, row);
      },
    },
    { title: "Alert Quantity", internal: "alert_quantity" },
    { title: "Supplier", internal: "biller.name" },
  ];

  return (
    <div className={styles.mainContainer}>
      <AdminHeader />
      <AdminSidebar />

      <div className={`${styles.container} me-4`}>
        <TableView
          defaultFilters={{ quantity: -1 }}
          pull={setRows}
          fetchUrl={"product"}
          title={"Quantity Alerts"}
          cols={cols}
          rows={rows}
          noCreate={true}
        />
      </div>
    </div>
  );
}
