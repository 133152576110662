import React, { useState } from "react";
import styles from "../../styles/admin.module.css";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import TableView from "../../components/table_view";

export default function HistoricFeeList() {
  const [rows, setRows] = useState([]);
  const cols = [
    { title: "Name", internal: "delivery_guy.name" },
    { title: "Amount", internal: "amount" },
    {
      title: "Date",
      internal: "timestamp",
      code: (row, data) => {
        return data.split("T")[0];
      },
    },
  ];

  return (
    <div className={styles.mainContainer}>
      <AdminHeader />
      <AdminSidebar />

      <div className={`${styles.container} me-4`}>
        <TableView
          noCreate={true}
          pull={setRows}
          fetchUrl={"day_closure_delivery_guy_expense"}
          title={"Delivery Guy Fees"}
          cols={cols}
          rows={rows}
          useReactQuery={"feel-list"}
        />
      </div>
    </div>
  );
}
