import React, { useEffect, useState } from "react";
import styles from "../../styles/admin.module.css";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import axiosInstance from "../../../AxiosInstance";
import { PrimaryButton, SelectInput, TextInput } from "../../components/inputs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import Text from "../../../components/ajonjolib/inputs/text/text";
import {
  toast,
  ToastTypes,
} from "../../../components/ajonjolib/toasts/toast/toast";

export default function SystemSettingsView() {
  const [data, setData] = useState({});
  const [form, setForm] = useState({});
  const [loading, setLoading] = useState(false);
  const [paiments, setPaiments] = useState([]);
  const [activeLoading, setActiveLoading] = useState({});
  const [paymentsLoading, setPaymentsLoading] = useState(true);

  useEffect(() => {
    fetchPaymentMethods();
  }, []);

  const fetchPaymentMethods = () => {
    setPaymentsLoading(true);
    axiosInstance
      .get("payment-methods/")
      .then((response) => {
        if (response.status === 200) {
          const formattedData = response.data
            .filter((method) => method.name !== "cash")
            .map((method) => ({
              id: method.id,
              name: method.name,
              is_active: method.is_active,
            }));
          setPaiments(formattedData);
        }
      })
      .catch((error) => {
        console.error("Error fetching payment methods:", error);
        toast(
          "Erreur lors du chargement des méthodes de paiement",
          ToastTypes.ERROR
        );
      })
      .finally(() => setPaymentsLoading(false));
  };

  useEffect(() => {
    axiosInstance.get("system_settings/").then((response) => {
      setData(response.data);
      response.data.maintenance_mode =
        response.data.maintenance_mode === true ? 1 : 0;
      setForm(response.data);
    });
  }, []);

  const submit = () => {
    axiosInstance.put("system_settings/1/", form).then((response) => {
      window.location.reload();
    });
  };

  const handleToggleActive = (id, currentActive, name) => {
    setActiveLoading((prev) => ({ ...prev, [name]: true }));
    axiosInstance
      .post("update-payment-method/", {
        name: name,
        is_active: !currentActive,
      })
      .then((response) => {
        if (response.status === 200) {
          toast("Modification réussie", ToastTypes.SUCCESS);
          setPaiments((prev) =>
            prev.map((method) =>
              method.name === name
                ? { ...method, is_active: !currentActive }
                : method
            )
          );
        } else {
          toast("Modification échouée", ToastTypes.ERROR);
        }
      })
      .catch((error) => {
        console.error("Error updating payment method:", error);
        toast("Erreur lors de la modification", ToastTypes.ERROR);
      })
      .finally(() => setActiveLoading((prev) => ({ ...prev, [name]: false })));
  };

  return (
    <div className={styles.mainContainer}>
      <AdminHeader />
      <AdminSidebar />

      <div className={`${styles.crudContainer}`}>
        <div className={styles.crudText}>
          <div className={"mb-2"}>System Settings</div>
          <div />
        </div>
        <div className={styles.crudInputs}>
          <div className={`${styles.createContainer} p-4`}>
            <TextInput
              name={"Expiry Date Threshold"}
              form={form}
              setForm={setForm}
              internalName={"days_before_expiry_alert"}
            />
            <TextInput
              name={"Inventory Alert Threshold"}
              form={form}
              setForm={setForm}
              internalName={"days_before_inventory_alert"}
            />
            <TextInput
              name={"Formula Threshold"}
              form={form}
              setForm={setForm}
              internalName={"formula_threshold"}
            />
            <SelectInput
              name={"Maintenance Mode"}
              form={form}
              setForm={setForm}
              internalName={"maintenance_mode"}
              options={[
                { id: 0, name: "Off" },
                { id: 1, name: "On" },
              ]}
            />
          </div>

          <div className={styles.buttonContainer}>
            <PrimaryButton name={"Update"} onClick={submit} />
          </div>

          <div className="p-4 bg-white rounded-3">
            <h3>Méthodes de Paiement</h3>
            {paymentsLoading ? (
              <div className="text-center my-4">
                <FontAwesomeIcon icon={faSpinner} spin size="2x" />
                <p className="mt-2">Chargement des méthodes de paiement...</p>
              </div>
            ) : (
              <ul className="list-group">
                {paiments.map((row) => (
                  <li
                    key={row.id}
                    className="list-group-item d-flex justify-content-between align-items-center"
                  >
                    <span className="me-3 fs-5">{row.name}</span>
                    <span className="me-3 fs-5">
                      Actif : {row.is_active ? "Oui" : "Non"}
                    </span>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id={`flexSwitchCheck${row.id}`}
                        checked={row.is_active}
                        onChange={() =>
                          handleToggleActive(row.id, row.is_active, row.name)
                        }
                        disabled={activeLoading[row.name]}
                      />
                      {activeLoading[row.name] && (
                        <FontAwesomeIcon
                          icon={faSpinner}
                          spin
                          color="#9EA3AC"
                          className="ms-2"
                        />
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
