import React from "react";
import ShopHeader from "../../header/header";
import Footer from "../footer";

export default function CGV() {
    return (
        <div>
            <ShopHeader/>
            <section style={{padding: '160px 80px 120px', backgroundColor: 'white', textAlign: 'left'}}>

                <header>
                    <div className="o-container u-txt-center">
                        <h1 className="c-h1 u-txt-uppercase"> Conditions générales de vente et d’utilisation</h1>
                    </div>
                </header>

                <div className="o-page">
                    <div className="o-container">
                        <div className="o-grid u-flex-main-center">
                            <div className="o-page__container">
                                <p>Date de dernière mise à jour : 03/09/2019</p>
                                <p>Les présentes conditions générales de vente (« CGV ») s’appliquent exclusivement,
                                    sans restriction ni réserve, aux ventes en ligne conclues entre la société BRING ME
                                    et les consommateurs et acheteurs non professionnels (« Clients ») désirant acquérir
                                    les produits qu’elle propose à la vente sur le site internet <a
                                        href="https://www.bringme.sn">https://www.bringme.sn</a> (« les Produits ») et
                                    décrits à l’article 2.</p>
                                <p>Les CGV constituent avec la commande en ligne les documents contractuels opposables
                                    aux parties, à l'exclusion de tous autres documents, qui ne sauraient engager la
                                    responsabilité de la société BRING ME. </p>
                                <p>Les Clients sont tenus de prendre connaissance des présentes CGV avant toute
                                    passation de commande.</p>
                                <p>Les présentes CGV sont accessibles à tout moment à l’adresse <a
                                    href="https://www.bringme.sn/cgv-cgu">https://www.bringme.sn/cgv-cgu</a> et
                                    prévaudront, le cas échéant, sur toute autre version ou tout autre document
                                    contradictoire. </p>
                                <p>Le Client déclare avoir pris connaissance des présentes CGV ainsi que des conditions
                                    générales d'utilisation du site internet <a
                                        href="https://www.bringme.sn">https://www.bringme.sn</a> avant de passer
                                    commande. La validation de la commande par le Client vaut acceptation sans
                                    restriction ni réserve des présentes CGV. </p>
                                <p>Sauf preuve contraire, les données enregistrées dans le système informatique du
                                    Vendeur constituent la preuve de l'ensemble des transactions conclues avec le
                                    Client. Les informations relatives à l'ensemble des commandes sont stockées et
                                    archivées durant un délai de 10 ans et peuvent servir de preuve en cas de litige.
                                    Leur accès est garanti au Client à tout moment si celui-ci en fait la demande
                                    écrite.</p>
                                <p>Les coordonnées de la société BRING ME sont les suivantes : Route de l’aéroport,
                                    Ngor, contact@bringme.sn</p>
                                <p>Territoires : Les Produits présentés sur le site internet <a
                                    href="https://www.bringme.sn">https://www.bringme.sn</a> sont proposés à la vente
                                    pour les territoires suivants :</p>
                                <p>•Sénégal. Les présentes CGV sont exclusivement applicables à la vente de Produits au
                                    Sénégal ;</p><br/>
                                <p>Les présentes CGV sont rédigées ainsi que l'ensemble des informations contractuelles
                                    mentionnées sur le site en langue française.</p>
                                <p>Modifications : Ces CGV pouvant faire l'objet de modifications à tout moment, la
                                    version applicable à l'achat du Client est celle en vigueur sur le site internet à
                                    la date de passation de la commande, dont la conservation et la reproduction sont
                                    assurées par le vendeur professionnel et dont une copie datée à ce jour peut être
                                    remise à sa demande au Client.</p>
                                <p>ARTICLE 2 – PRODUITS</p>
                                <p>2-1 Caractéristiques</p>
                                <p>Les Produits font l’objet d’une fiche informative accessible en cliquant sur la
                                    photographie qui les représente ou leur nom, inscrit au dessous. Ces informations
                                    sont destinées, sans être exhaustives, à informer les Clients de la façon la plus
                                    complète possible sur les caractéristiques essentielles des Produits. </p>
                                <p>Les illustrations ou photographies des Produits n'ont qu'une valeur indicative et
                                    n’ont pas de valeur contractuelle.</p>
                                <p>2-2 Disponibilité - Stocks</p>
                                <p>Les offres de Produits sont valables, à défaut d’indication de durée particulière,
                                    tant qu'elles sont visibles sur le site et dans la limite des stocks
                                    disponibles.</p>
                                <p>En cas d'indisponibilité du Produit commandé, la société BRING ME, ou toute personne
                                    qu’elle aura mandatée pour le faire, en informe immédiatement le Client et peut lui
                                    proposer un produit d'une qualité et d'un prix équivalents ou, à défaut, un bon
                                    d'achat du montant de la commande utilisable pour toute prochaine commande. En cas
                                    de désaccord du Client, la société BRING ME procède à l’annulation de la commande,
                                    ou au retrait du produit en question si la commande comporte plusieurs articles. En
                                    dehors de l’annulation de vente du produit indisponible, le vendeur n'est tenu à
                                    aucune indemnité d'annulation. </p>
                                <p>ARTICLE 3 – TARIFS </p>
                                <p>Les Produits sont fournis aux tarifs en vigueur figurant sur le site Internet
                                    www.bringme.sn, lors de l'enregistrement de la commande par la société BRING ME. Sur
                                    les fiches informatives, les prix sont exprimés en FCFA et toutes taxes comprises
                                    (TTC), hors frais de livraison et de transport mentionnés lors du passage de la
                                    commande et facturés en supplément.</p>
                                <p>Les frais de livraison dépendent du moment où la commande est passée, de la distance
                                    à parcourir et de la date de livraison choisie :</p>
                                <p>Le montant total dû par le Client (comprenant les frais de livraison) est indiqué sur
                                    la page de confirmation de commande. </p>
                                <p>Les tarifs tiennent compte d'éventuelles réductions qui seraient consenties par la
                                    société BRING ME sur le site internet <a
                                        href="https://www.bringme.sn">https://www.bringme.sn</a>.</p>
                                <p>Le prix de vente du Produit (ferme et non révisable) est celui en vigueur au jour de
                                    la commande, la société BRING ME se réservant le droit, de modifier les prix à tout
                                    moment, tout en garantissant au Client l'application du prix en vigueur au jour de
                                    la commande. </p>
                                <p>Un minimum de commande de 5000 FCFA est demandé au client pour toute commande passée
                                    sur le service BRING ME quelque soit le moment où la commande est passée et de la
                                    date de livraison choisie.</p>
                                <p>ARTICLE 4 – COMMANDE - CONTRAT</p>
                                <p>4-1 Commande</p>
                                <p>Le Client déclare avoir la capacité requise pour contracter et acquérir les Produits,
                                    notamment avoir l’âge légal, le cas échéant, pour acheter des boissons
                                    alcoolisées.</p>
                                <p>Le Client est tenu de se reporter au descriptif de chaque Produit afin d'en connaître
                                    les propriétés et les particularités essentielles. </p>
                                <p>Les offres de Produits s'entendent dans la limite des stocks disponibles, tels que
                                    précisés lors de la passation de la commande. </p>
                                <p>Le processus de passation, validation et confirmation de la commande et de paiement
                                    est le suivant :</p>
                                <p>•Préalablement à toute commande, le Client doit s’inscrire sur le site :<br/>
                                    ⇨ Soit via Facebook ;<br/>
                                    ⇨ Soit en renseignant une adresse email et un mot de passe.</p><br/>
                                <p>•Le client doit ensuite renseigner ses informations personnelles, notamment son
                                    numéro de téléphone, information essentielle pour la suite de la commande.<br/>
                                        Ces informations peuvent être modifiées à tout moment, notamment au moment de
                                        confirmer et valider la commande.</p><br/>
                                <p>•Le Client sélectionne un produit et l’ajoute à son panier.</p><br/>
                                <p>•Le Client renseigne l’adresse à laquelle doit être livrée le Produit, les frais de
                                    livraison pouvant différer selon le créneau et le lieu sélectionnés.</p><br/>
                                <p>•Le Client valide accepte les présentes CGV validant sa commande. La validation de la
                                    commande implique l’acceptation de l’intégralité des présentes CGV.</p><br/>
                                <p>La société BRING ME se réserve le droit d'annuler ou de refuser toute commande pour
                                    des motifs légitimes, notamment :</p><br/>
                                <p>•si existe avec un client un litige relatif au paiement d'une commande
                                    antérieure,<br/>
                                        •en cas de commandes d'un même Produit en quantités anormalement élevées pour
                                        des acheteurs ayant la qualité de consommateurs, la société BRING ME n'ayant pas
                                        vocation à vendre les Produits à des professionnels sur le site <a
                                        href="https://www.bringme.sn">https://www.bringme.sn</a> (le service « la
                                        VIVETTE par BRING ME » leur étant dédié) mais uniquement à des consommateurs ou
                                        à des non professionnels, pour leurs besoins personnels.</p><br/>
                                <p>4-2 Contrat</p>
                                <p>La vente ne sera considérée comme définitive qu'après l'envoi au Client de la
                                    confirmation de l'acceptation de la commande par le Vendeur par message (whatsapp)
                                    ou appel.</p>
                                <p>La confirmation de la commande constitue la formation d'un contrat entre le Client et
                                    la société BRING ME et entraine l’acceptation des présentes CGV.</p>
                                <p>L'archivage des communications, des bons de commande et des factures est effectué sur
                                    un support fiable et durable de manière constituer une copie fidèle et durable. Ces
                                    communications, bons de commande et factures peuvent être produits à titre de preuve
                                    du contrat. </p>
                                <p>ARTICLE 5 – MODALITÉS DE PAIEMENT </p>
                                <p>Le prix est payable comptant, dès la passation de la commande par le Client, par voie
                                    de paiement sécurisé, par cartes bancaires uniquement (Visa, MasterCard, American
                                    Express). Il s’effectue immédiatement à la commande. Les données de paiement sont
                                    échangées en mode crypté grâce au protocole SSL. </p>
                                <p>Toute somme non payée à l'échéance est productive, sans mise en demeure, d'un intérêt
                                    au taux légal.</p>
                                <p>Les paiements effectués par le Client ne seront considérés comme définitifs qu'après
                                    encaissement effectif des sommes dues par la société BRING ME.</p>
                                <p>En outre, la société BRING ME se réserve le droit, en cas de non respect des
                                    conditions de paiement figurant ci-dessus, de suspendre ou d'annuler la livraison
                                    des commandes en cours effectuées par le Client.</p>
                                <p>ARTICLE 6 – LIVRAISONS </p>
                                <p>La livraison est constituée par le transfert au Client de la possession physique du
                                    Produit.</p>
                                <p>6-1 Lieu de livraison</p>
                                <p>Les Produits commandés par le Client seront livrés à Dakar et proche banlieue à
                                    l'adresse indiquée par le Client lors de sa commande sur le site Internet <a
                                        href="https://www.bringme.sn">https://www.bringme.sn</a>.</p>
                                <p>6-2 Délai de livraison</p>
                                <p>Les délais de livraison sont indiqués à titre indicatif et correspondent aux délais
                                    moyens de traitement et de livraison constatés. </p>
                                <p>6-3 Modalités de livraison</p>
                                <p>La livraison est effectuée par la remise directe du produit au Client. Si le Client
                                    n’est pas à l’adresse qu’il a indiquée lors de sa commande, que le client annule la
                                    commande après la prise en charge du livreur ou que le client ne montre pas une
                                    pièce d'identité au livreur lors de sa première commande, le livreur repartira avec
                                    le produit et remboursera le Produit commandé, mais des pénalités d’annulation
                                    peuvent appliquées : Jusqu’à 2500 FCFA correspondants aux frais de livraison et à
                                    des frais de gestion.</p>
                                <p>Le Client est tenu de vérifier l'état des Produits livrés en présence du livreur et,
                                    en cas d'avarie ou de manquants, d'émettre des réserves sur le bon de livraison ou
                                    sur le récépissé de transport, et éventuellement de refuser le produit et d'en
                                    avertir la société BRING ME. </p>
                                <p>6-4 Transfert de propriété - Transfert de risques</p>
                                <p>Le transfert de propriété des Produits de la société BRING ME au profit du Client ne
                                    sera réalisé qu'après complet paiement du prix par ce dernier, et ce quelle que soit
                                    la date de livraison desdits Produits. Quelle que soit la date du transfert de
                                    propriété des Produits, le transfert des risques de perte et de détérioration s'y
                                    rapportant, ne sera réalisé qu'au moment où le Client prendra physiquement
                                    possession des Produits. Les Produits voyagent donc aux risques et périls de la
                                    société BRING ME. Le Client s'engage, tant que la propriété ne lui est pas
                                    transférée, à prendre toutes les précautions utiles à la bonne conservation des
                                    produits.</p>
                                <p>ARTICLE 7 – DROIT DE RÉTRACTATION</p>
                                <p>7-1 Délai légal de rétractation</p>
                                <p>Le Client dispose de jusqu’à la livraison, pour exercer son droit de rétractation
                                    auprès de la société BRING ME, sans avoir à justifier de motifs ni à payer de
                                    pénalité, à fin de remboursement, à condition que les Produits soient retournés dans
                                    leur emballage d'origine et en parfait état suivant la communication de sa décision
                                    de rétractation.</p>
                                <p>7-2 Produits exclus du délai légal de rétractation</p>
                                <p>Conformément à l'article L.221-28 du Code de la consommation, le Client ne bénéficie
                                    pas, pour la commande de produits qui, du fait de leur nature, ne peuvent être
                                    réexpédiés ou sont susceptibles de se détériorer ou de se périmer rapidement, d'un
                                    droit de rétraction pour faire retour de sa commande. Sont ainsi notamment visés
                                    :</p><br/>
                                <p>•les Produits frais tels que les citrons,<br/>
                                    •les Produits dont l’emballage d’origine aura été descellé, y compris tout produit
                                    alimentaire qui aura été ouvert ou abimé, par le Client après la livraison, car ils
                                    ne peuvent être renvoyés pour des raisons d’hygiène et de santé.</p><br/>
                                <p>Les contrats passés sur ces Produits le sont donc de manière définitive.</p><br/>
                                <p>7-3 Modalités d’exercice du droit de rétractation</p>
                                <p>Pour exercer son droit de rétractation, le Client doit notifier à la société BRING ME
                                    :</p><br/>
                                <p>•son nom, son adresse géographique et, lorsqu'ils sont disponibles, son numéro de
                                    téléphone, son numéro de télécopieur et son adresse électronique ;<br/>
                                        •ainsi que sa décision de rétractation au moyen d'une déclaration dénuée
                                        d'ambiguïté exprimant sa volonté de se rétracter. </p><br/>
                                <p>Le client peut utiliser le modèle de formulaire de rétractation (annexe 1) mais ce
                                    n'est pas obligatoire.</p><br/>
                                <p>Les retours sont à effectuer, à la société BRING ME ou une personne qu’elle aura
                                    désignée, dans leur état d'origine et complets (emballage, accessoires,...)
                                    permettant leur remise sur le marché à l'état neuf, accompagnés de la facture
                                    d'achat. Les Produits endommagés, salis ou incomplets ne sont pas repris. Il est
                                    rappelé que la responsabilité du Client, en cas de rétractation après utilisation du
                                    ou des biens, est engagée à l'égard de la dépréciation du ou des biens résultant de
                                    manipulations autres que celles nécessaires pour établir la nature, les
                                    caractéristiques et le bon fonctionnement de ce ou ces biens. Ces manipulations sont
                                    celles qu'un consommateur peut effectuer dans un magasin, pour les biens qui y sont
                                    proposés à la vente. </p><br/>
                                <p>En cas d'exercice du droit de rétractation dans le délai susvisé, seul le prix du ou
                                    des Produits achetés et les frais de livraison sont remboursés ; les frais de retour
                                    restant à la charge du Client. La société BRING ME procèdera au remboursement en
                                    utilisant le même moyen de paiement que celui utilisé pour la transaction initiale
                                    (sauf accord exprès du Client pour qu'il utilise un autre moyen de paiement et dans
                                    la mesure où le remboursement n'occasionne pas de frais pour le Client).</p><br/>
                                <p>Le remboursement sera effectué dans un délai de 14 jours à compter de la date à
                                    laquelle la société BRING ME sera informée de la décision du consommateur de se
                                    rétracter, la société BRING ME se réservant le droit de différer le remboursement
                                    jusqu’à réception des Produits retournés et/ou jusqu’à ce que le Client ait fourni
                                    une preuve d’expédition des Produits, la date retenue étant celle du premier de ces
                                    faits.</p><br/>
                                <p>ARTICLE 8 – GARANTIE LÉGALE DE CONFORMITÉ ET GARANTIE DES VICES CACHÉS </p>
                                <p>Les Produits bénéficient de plein droit et sans paiement complémentaire,
                                    indépendamment du droit de rétractation, conformément aux dispositions légales :
                                </p><br/>
                                <p>•de la garantie légale de conformité, pour les Produits apparemment défectueux,
                                    abîmés ou endommagés ou ne correspondant pas à la commande. Il est rappelé que dans
                                    le cadre de la garantie légale de conformité :<br/>
                                        • Le client peut choisir entre la réparation ou le remplacement du Produit
                                        commandé. Toutefois, la société BRING ME peut ne pas procéder selon le choix du
                                        Client si ce choix entraîne un coût manifestement disproportionné au regard de
                                        l'autre modalité, compte tenu de la valeur du bien ou de l'importance du défaut.
                                        Elle est alors tenue de procéder, sauf impossibilité, selon la modalité non
                                        choisie par le Client. Si la réparation ou le remplacement du Produit sont
                                        impossibles ou n’interviennent pas dans le délai d’un mois suivant la
                                        réclamation du Client ou lui provoquent un inconvénient majeur, ce dernier
                                        pourra rendre le bien et se faire restituer le prix ou le garder et s’en faire
                                        rendre une partie du prix ;<br/>
                                        • Le client se doit de rapporter la preuve de l'existence du défaut de
                                        conformité du Produit au moment de sa délivrance. <br/>
                                        •de la garantie légale contre les vices cachés provenant d'un défaut de matière,
                                        de conception ou de fabrication affectant les produits livrés et les rendant
                                        impropres à l'utilisation. Il est rappelé que le Client peut décider de mettre
                                        en œuvre la garantie contre les défauts cachés Produit conformément à l'article
                                        1641 du Code Civil ; dans ce cas, il peut choisir entre la résolution de la
                                        vente ou une réduction du prix de vente conformément à 1644 du Code Civil.<br/>
                                </p>
                                <p>Afin de faire valoir ses droits, le Client devra informer la société BRING ME, par
                                    écrit, et retourner les Produits défectueux dans l'état dans lequel ils ont été
                                    reçus avec l'ensemble des éléments (accessoires, emballage,...).<br/></p>
                                <p>Les potentiels remboursements des Produits jugés non conformes ou défectueux seront
                                    effectués dans les meilleurs délais et au plus tard dans les 14 jours suivant la
                                    constatation par la société BRING ME du défaut de conformité ou du vice caché. Le
                                    remboursement s'effectuera par le moyen de paiement choisit par le client lors de
                                    son achat.<br/></p>
                                <p>ARTICLE 9 – EXONÉRATION DE RESPONSABILITÉ DE LA SOCIÉTÉ BRING ME</p>
                                <p>La responsabilité de la société BRING ME ne saurait être engagée dans les cas
                                    suivants : <br/></p>
                                <p>•non-conformité du produit à la législation du pays du Client auquel il appartient de
                                    vérifier si le produit n'est pas interdit à la vente dans son pays (en l’occurrence,
                                    le Sénégal),<br/>
                                        •en cas de mauvaise utilisation, d'utilisation à des fins professionnelles,
                                        négligence ou défaut d'entretien de la part du Client, comme en cas d'usure
                                        normale du Produit, d'accident ou de force majeure.<br/></p>
                                <p>En cas de dommage causé par un défaut de sécurité du produit, le Client doit
                                    rechercher la responsabilité du fabricant identifiable à partir des informations
                                    mentionnées sur l'emballage du produit. <br/></p>
                                <p>ARTICLE 10 – INFORMATIQUE &amp; LIBERTÉS - DONNÉES PERSONNELLES </p>
                                <p>Les données personnelles du Client sont collectées notamment pour permettre à la
                                    société BRING ME de traiter sa commande et établir une facture. À défaut de
                                    renseignement de la part du Client, la commande ne pourra pas être traitée.</p>
                                <p>Elles peuvent être communiquées aux éventuels partenaires de la société BRING ME
                                    chargés de l'exécution, du traitement, de la gestion et du paiement des commandes
                                    mais ne sont pas transmises à des destinataires situés hors Sénégal.</p>
                                <p>Le Client dispose, à tout moment, d'un droit d'accès, de rectification, et
                                    d'opposition à l'ensemble de ses données personnelles. </p>
                                <p>ARTICLE 11 – MÉDIATION </p>
                                <p>11-1 Réclamation préalable</p>
                                <p>En cas de litige, le Client doit s’adresser en priorité au service clientèle de la
                                    société BRING ME par courrier électronique à contact@bringme.sn.</p>
                                <p>11-2 Demande de médiation </p>
                                <p>En cas de réclamation non résolue amiablement, le Client peut soumettre le différend
                                    relatif au bon de commande ou aux présentes CGV l'opposant à la société BRING ME à
                                    un médiateur qui tentera, en toute indépendance et impartialité, de rapprocher les
                                    parties en vue d'aboutir à une solution amiable. </p>
                                <p>Les parties au contrat restent libres d'accepter ou de refuser le recours à la
                                    médiation ainsi que, en cas de recours à la médiation, d'accepter ou de refuser la
                                    solution proposée par le médiateur.</p>
                                <p>ARTICLE 12 – DROIT APPLICABLE - TRIBUNAL COMPÉTENT </p>
                                <p>Les présentes CGV et les opérations qui en découlent sont régies et soumises au droit
                                    sénégalais. </p>
                                <p>Tous les litiges auxquels les présentes CGV ou les opérations d'achat et de vente
                                    conclues en application des présentes CGV pourraient donner lieu, concernant tant
                                    leur validité, leur interprétation, leur exécution, leur résiliation, leurs
                                    conséquences et leurs suites et qui n'auraient pu être résolues entre la société
                                    BRING ME et le Client seront soumis aux juridictions compétentes dans les conditions
                                    de droit commun.</p>
                                <p>ANNEXE 1 – MODÈLE DE FORMULAIRE DE RÉTRACTATION</p>
                                <p>A l'attention de BRING ME : Route de l’aéroport, Dakar :<br/></p>
                                <p>Je vous notifie par la présente ma rétractation du contrat portant sur la vente du
                                    bien ci-dessous :<br/>
                                        •Commande passée le
                                        ........................................................... <br/>
                                        •Numéro de la commande :
                                        ........................................................... <br/>
                                        •Description du Produit commandé :
                                        ...........................................................<br/>
                                        •Nom du Client :
                                        ........................................................................... <br/>
                                        •Adresse du Client :
                                        ....................................................................... </p><br/>
                                <p>Date : ...........................................................<br/>
                                    Signature du Client :<br/></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer/>
        </div>
    );
}