import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../../../AxiosInstance";
import styles from "../../styles/admin.module.css";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faX } from "@fortawesome/free-solid-svg-icons";
import {
  PrimaryButton,
  SecondaryButton,
  TextInput,
} from "../../components/inputs";

export default function SubcategoryDetailed() {
  const { state } = useLocation();
  const [products, setProducts] = useState([]);
  const [toRemove, setToRemove] = useState([]);
  const [form, setForm] = useState({});
  const [foundProduct, setFoundProduct] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [timer, setTimer] = useState(null);
  const [biller, setBiller] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (state) {
      if (state.supplier) setBiller(state.supplier.id);
    }
    axiosInstance
      .get(
        `product/?all_warehouse=${true}&combos=${true}&all=${true}&subcategory_id=${
          state.id
        }&max_query=1000`
      )
      .then((response) => {
        setProducts(response.data.results);
      });
  }, []);

  useEffect(() => {
    let original = products;
    original = original.filter((product) => !toRemove.includes(product.id));
    setProducts(original);
  }, [toRemove]);

  const back = () => {
    // window.location.replace("/admin/subcategories");
    navigate("/admin/subcategories", { replace: true });
  };

  const submit = () => {
    axiosInstance
      .put(`subcategory/${state.id}/remove_products/`, toRemove)
      .then((response) => {
        axiosInstance
          .post("subcategory/add_products/", {
            subcategory: state.id,
            products: selectedProducts.map((product) => product.id),
          })
          .then((response) => {
            back();
          });
      });
  };

  useEffect(() => {
    if (timer) {
      clearTimeout(timer);
    }

    if (form["product_name"]?.trim() !== "") {
      const newTimer = setTimeout(() => {
        let appendThing = "";
        if (biller) {
          appendThing = `&biller_id=${biller}`;
        }
        axiosInstance
          .get(
            `product/subcategory_assign_search/?name=${form["product_name"]}${appendThing}`
          )
          .then((response) => {
            setFoundProduct(response.data);
          });
      }, 400);

      setTimer(newTimer);
    }
  }, [form["product_name"]]);

  return (
    <div className={styles.mainContainer}>
      <AdminHeader />
      <AdminSidebar />
      <div className={`${styles.crudContainer}`}>
        <div className={styles.crudText}>
          <div className={"mb-2"}>Subcategory Details</div>
          <div>Subcategory: {state?.name}</div>
        </div>
        <div className={styles.crudInputs}>
          <div className={`${styles.createContainer} p-4`}>
            <div className={""}>
              <div>
                <div style={{ fontWeight: "500" }}>Subcategory:</div>
                <div className={"pb-3"}>{state.name}</div>
              </div>

              <div>
                <div style={{ fontWeight: "500" }}>New Products:</div>
                {selectedProducts.map((product, index) => (
                  <div
                    className={
                      "d-flex justify-content-center align-items-center"
                    }
                  >
                    <div key={index}>{product.name}</div>
                    <div
                      className={"ps-3"}
                      role={"button"}
                      onClick={() => {
                        const new_selected = selectedProducts.filter(
                          (x) => x.id !== product.id
                        );
                        setSelectedProducts(new_selected);
                      }}
                    >
                      <FontAwesomeIcon icon={faX} />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className={`${styles.createContainer} p-4`}>
            <TextInput
              form={form}
              setForm={setForm}
              name={"Product"}
              internalName={"product_name"}
            />
            <div>
              {foundProduct?.map((product, index) => (
                <div
                  key={index}
                  className={
                    "d-flex mb-2 px-2 py-2 align-items-center justify-content-between"
                  }
                  style={{
                    borderRadius: "6px",
                    border: "1px #ccc solid",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setSelectedProducts([
                      ...selectedProducts,
                      { id: product.id, name: product.name },
                    ]);
                    setFoundProduct([]);
                    setForm({ ...form, product_name: "" });
                  }}
                >
                  <div>{product.name}</div>
                  <FontAwesomeIcon icon={faPlus} color={"#009F7F"} />
                </div>
              ))}
            </div>
          </div>

          <div className={`${styles.createContainer} p-4`}>
            {products.map((product, index) => (
              <div
                key={index}
                className={
                  "d-flex mb-2 px-2 py-2 align-items-center justify-content-between"
                }
                style={{
                  borderRadius: "6px",
                  border: "1px #ccc solid",
                  cursor: "pointer",
                }}
              >
                <div>{product.name}</div>
                <FontAwesomeIcon
                  icon={faX}
                  color={"#009F7F"}
                  className={"pe-2"}
                  onClick={() => {
                    setToRemove([...toRemove, product.id]);
                  }}
                />
              </div>
            ))}
          </div>
          <div className={styles.buttonContainer}>
            <SecondaryButton name={"Back"} onClick={back} />
            <div className={"ps-3"} />
            <PrimaryButton name={"Update"} onClick={submit} />
          </div>
        </div>
      </div>
    </div>
  );
}
