import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import RouteContainer from "./RouteContainer";
import { ContextProvider } from "./MyContext";
import ToastContainer from "./components/ajonjolib/toasts/toast/toast_container";
import axiosInstance from "./AxiosInstance";
import { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

// Create a client
export const queryClient = new QueryClient({
  defaultOptions: { queries: { gcTime: 20 * 60 * 1000 * 60 } },
});

function App() {

  const district = JSON.parse(localStorage.getItem("district"));
  const access_token = localStorage.getItem("access_token");
  const permissions = parseInt(localStorage.getItem("permissions"));

  useEffect(() => {
    // axiosInstance.get("current_user/").then((response) => {
    //   if (response?.response?.status === 500) {
    //     localStorage.removeItem("access_token");
    //   }
    //   if (
    //     district?.id !== response?.data?.user_data?.district?.id &&
    //     access_token
    //   ) {
    //     if (!permissions) {
    //       if (window.location.pathname !== "/locate") {
    //         alert("Veuillez mettre à jour votre position");
    //         window.location.href = "/locate";
    //       }
    //     }
    //   }
    // });
  }, []);

  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <ContextProvider>
          <Router>
            <RouteContainer />
          </Router>
          <ToastContainer />
        </ContextProvider>
      </QueryClientProvider>
    </div>
  );
}

export default App;
