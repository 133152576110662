import React, { useEffect, useState } from "react";
import styles from "./shop_header.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faChevronLeft,
  faMapPin,
} from "@fortawesome/free-solid-svg-icons";
import ShopSidebar from "../sidebar/sidebar";
import { useNavigate } from "react-router-dom";

export default function ShopHeader({ noMenu, showLogin }) {
  const [showSidebar, setShowSidebar] = useState(false);
  const [district, setDistrict] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    const retrieved = localStorage.getItem("district");
    if (retrieved !== undefined) {
      setDistrict(JSON.parse(retrieved));
    }
  }, []);

  return (
    <div className={`${styles.container}`}>
      <ShopSidebar
        show={showSidebar}
        setter={setShowSidebar}
        showLogin={showLogin}
      />
      <div className={`${styles.innerContainer}`}>
        {noMenu ? (
          <div
            className={"d-flex justify-content-start align-items-center"}
            role={"button"}
            onClick={() => navigate(-1, { preventScrollReset: true })}
          >
            {/* <div className={'d-flex justify-content-start align-items-center'} role={'button'} onClick={() => window.location.replace(noMenu.url)}> */}
            <FontAwesomeIcon
              icon={faChevronLeft}
              size={"xl"}
              color={"#00A6A8"}
            />
            <div style={{ color: "#00A6A8", fontSize: "1.2rem" }}>
              <b>{noMenu.name}</b>
            </div>
          </div>
        ) : (
          <div
            className={"d-flex flex-column justify-content-center"}
            role={"button"}
            onClick={() => setShowSidebar(true)}
          >
            <FontAwesomeIcon icon={faBars} size={"xl"} />
            <div>Menu</div>
          </div>
        )}
        <div
          onClick={() => navigate("/", { preventScrollReset: true })}
          role={"button"}
        >
          {/* <div onClick={() => window.location.replace("/")} role={"button"}> */}
          <img src={"/logo.png"} height={"60px"} width={"auto"} />
        </div>
        <div
          className={"d-flex flex-column justify-content-center"}
          role={"button"}
          onClick={() => window.location.replace("/locate")}
        >
          <FontAwesomeIcon icon={faMapPin} size={"xl"} color={"grey"} />
          <div>
            <b>{district?.name}</b>
          </div>
        </div>
      </div>
    </div>
  );
}
