import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import axiosInstance from "../../../AxiosInstance";
import { formatPrice } from "../../../utils/utils";

// Enregistrer les composants nécessaires pour Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const ClosureMonthlyStatsChart = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    axiosInstance
      .get("stats/warehouse/")
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error(
          "Il y a eu une erreur lors de la récupération des données !",
          error
        );
      });
  }, []);

  // Group data by warehouse and sort by month
  const groupedData = data.reduce((acc, item) => {
    if (!acc[item.warehouse_name]) {
      acc[item.warehouse_name] = [];
    }
    acc[item.warehouse_name].push(item);
    return acc;
  }, {});

  const months = [...new Set(data.map((item) => item.month))].sort(); // Get unique months and sort them

  const warehouses = Object.keys(groupedData);
  const generateColor = (index) => {
    const colors = [
      "rgba(255, 99, 132, 0.2)",
      "rgba(75, 192, 192, 0.2)",
      "rgba(54, 162, 235, 0.2)",
      "rgba(255, 159, 64, 0.2)",
      "rgba(153, 102, 255, 0.2)",
    ];
    return colors[index % colors.length];
  };

  const datasets = warehouses.flatMap((warehouse, index) => {
    const warehouseData = groupedData[warehouse];

    return [
      {
        label: `Total Profit (${warehouse})`,
        data: months.map((month) => {
          const monthData = warehouseData.find((item) => item.month === month);
          return monthData ? monthData.total_profit : 0;
        }),
        backgroundColor: generateColor(index),
        borderColor: generateColor(index).replace("0.2", "1"),
        borderWidth: 1,
        yAxisID: "y1",
      },
      {
        label: `Total Purchase (${warehouse})`,
        data: months.map((month) => {
          const monthData = warehouseData.find((item) => item.month === month);
          return monthData ? monthData.purchase_count : 0;
        }),
        backgroundColor: generateColor(index),
        borderColor: generateColor(index).replace("0.2", "1"),
        borderWidth: 1,
        yAxisID: "y1",
      },
      // {
      //   label: `Total Delivery Expenses (${warehouse})`,
      //   data: months.map((month) => {
      //     const monthData = warehouseData.find((item) => item.month === month);
      //     return monthData ? monthData.total_delivery_expenses : 0;
      //   }),
      //   backgroundColor: generateColor(index),
      //   borderColor: generateColor(index).replace("0.2", "1"),
      //   borderWidth: 1,
      //   yAxisID: "y1",
      // },
      // {
      //   label: `Total Closure Expenses (${warehouse})`,
      //   data: months.map((month) => {
      //     const monthData = warehouseData.find((item) => item.month === month);
      //     return monthData ? monthData.total_closure_expenses : 0;
      //   }),
      //   backgroundColor: generateColor(index),
      //   borderColor: generateColor(index).replace("0.2", "1"),
      //   borderWidth: 1,
      //   yAxisID: "y1",
      // },
      {
        label: `Sale Count (${warehouse})`,
        data: months.map((month) => {
          const monthData = warehouseData.find((item) => item.month === month);
          return monthData ? monthData.sale_count : 0;
        }),
        backgroundColor: generateColor(index),
        borderColor: generateColor(index).replace("0.2", "1"),
        borderWidth: 1,
        yAxisID: "y2",
      },
    ];
  });

  const chartData = {
    labels: months,
    datasets,
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            if (tooltipItem.dataset.label.includes("Total Profit")) {
              return `${tooltipItem.dataset.label}: ${formatPrice(
                tooltipItem.raw
              )}`;
            } else if (tooltipItem.dataset.label.includes("Total Purchase")) {
              return `${tooltipItem.dataset.label}: ${formatPrice(
                tooltipItem.raw
              )}`;
            } else if (
              tooltipItem.dataset.label.includes("Total Delivery Expenses")
            ) {
              return `${tooltipItem.dataset.label}: ${formatPrice(
                tooltipItem.raw
              )}`;
            } else if (
              tooltipItem.dataset.label.includes("Total Closure Expenses")
            ) {
              return `${tooltipItem.dataset.label}: ${formatPrice(
                tooltipItem.raw
              )}`;
            }
            return `${tooltipItem.dataset.label}: ${tooltipItem.raw}`;
          },
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Month",
        },
        type: "category",
        labels: months, // Ensure labels are sorted
      },
      y1: {
        title: {
          display: true,
          text: "Total",
        },
        position: "left",
        beginAtZero: true,
      },
      y2: {
        title: {
          display: true,
          text: "Sales counts",
        },
        position: "right",
        beginAtZero: true,
      },
    },
  };

  return (
    <div className="chart-container">
      <h2>Warehouse Monthly Statistics</h2>
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default ClosureMonthlyStatsChart;
