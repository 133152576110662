import React, { useEffect, useState } from "react";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import styles from "../../styles/admin.module.css";
import TableView from "../../components/table_view";
import {
  faEdit,
  faTrash,
  faCheck,
  faSpinner,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import axiosInstance from "../../../AxiosInstance";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DeleteModal from "../../components/delete_modal/delete_modal";
import { formatTimestamp } from "../../components/utils";
import {
  toast,
  ToastTypes,
} from "../../../components/ajonjolib/toasts/toast/toast";
import { Link } from "react-router-dom";

export default function UsersList() {
  const [rows, setRows] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [editing, setEditing] = useState(null);
  const [editingPointValue, setEditingPointValue] = useState("");
  const [changePointLoading, setChangePointloading] = useState({});

  useEffect(() => {
    axiosInstance.get("user_data_secure/").then((response) => {
      setRows(response.data);
    });
  }, []);

  const deleteObj = (id) => {
    axiosInstance.delete("admin_accounts/" + id + "/").then((response) => {
      window.location.reload();
    });
  };

  const handleChangePoints = (id, value) => {
    setChangePointloading((prev) => ({ ...prev, [id]: true })); // Démarrer le chargement
    console.log(id, value);
    setChangePointloading((prev) => ({ ...prev, [id]: false }));
    axiosInstance
      .post(`users/${id}/update-points/`, {
        points: parseInt(value),
      })
      .then((response) => {
        if (response.status === 200) {
          toast("Modification réussie", ToastTypes.SUCCESS);
          // Met à jour l'état local sans recharger la page
          setRows((prevRows) =>
            prevRows.map((row) =>
              row.id === id ? { ...row, points: value } : row
            )
          );
        } else {
          toast("Une erreur est survenue", ToastTypes.ERROR);
        }
      })
      .catch((error) => {
        toast("Error toggling points_enabled", ToastTypes.ERROR);
      })
      .finally(() => {
        setChangePointloading((prev) => ({ ...prev, [id]: false })); // Arrêter le chargement
      });
  };

  const cols = [
    { title: "Name", internal: "name", filter: true, filterInternal: "name" },
    {
      title: "Last Name",
      internal: "lastname",
      filter: true,
      filterInternal: "lastname",
    },
    {
      title: "Phone Number",
      internal: "phone_number",
      filter: true,
      filterInternal: "phone_number",
    },
    {
      title: "Email",
      internal: "email",
      filter: true,
      filterInternal: "email",
    },
    {
      title: "Address",
      internal: "address",
      filter: true,
      filterInternal: "address",
    },
    {
      title: "Orders",
      internal: "user_sales",
      filter: true,
      filterInternal: "user_sales",
    },
    {
      title: "Last Order",
      internal: "last_order_timestamp",
      filter: true,
      dateFilter: true,
      filterInternal: "last_order_timestamp",
      code: (row, data) =>
        data ? formatTimestamp(new Date(data)) : "No orders yet",
    },
    {
      title: "District",
      internal: "district.name",
      filter: true,
      filterInternal: "district",
    },
    {
      title: "Points",
      internal: "points",
      code: (data, row) => {
        const isEditing = editing === data.id;

        return (
          <div className="d-flex align-items-center">
            <span>{data.points}</span>
            <Link
              to={"/admin/users/point/" + data?.user}
              state={{
                userName: data?.name + " " + data?.lastname,
                points: data?.points,
                totalPoints: data?.points,
              }}
              style={{ padding: "0 10px" }}
            >
              <FontAwesomeIcon icon={faEye} className="ms-2" />
            </Link>
            {/* {changePointLoading[data.id] ? ( // Afficher l'icône de chargement
              <FontAwesomeIcon icon={faSpinner} spin className="ms-2" />
              ) : isEditing ? (
                <>
                <input
                  type="text"
                  value={editingPointValue}
                  onChange={(e) => setEditingPointValue(e.target.value)}
                />
                <FontAwesomeIcon
                  icon={faCheck} // Remplacez par l'icône de validation
                  className="ms-2"
                  color="green"
                  onClick={() => {
                    // Logique pour sauvegarder la modification
                    // Vous pouvez appeler une fonction pour mettre à jour les points ici
                    handleChangePoints(data?.id, editingPointValue);
                    setEditing(null);
                    setEditingPointValue("");
                    }}
                    />
                    </>
                    ) : (
                      <>
                      <span>{data.points}</span>
                <FontAwesomeIcon
                  icon={faEdit}
                  className="ms-2"
                  color="orange"
                  onClick={() => {
                    setEditing(data.id);
                    setEditingPointValue(data.points);
                  }}
                />
              </>
            )} */}
          </div>
        );
      },
    },
    {
      title: "Actions",
      internal: "id",
      code: (row, data) => {
        return (
          <div className={"d-flex justify-content-end"}>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                setSelectedId(row.user);
                setShowDelete(true);
              }}
            >
              <FontAwesomeIcon icon={faTrash} color={"#F00"} />
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className={styles.mainContainer}>
      <AdminHeader />
      <AdminSidebar />

      <DeleteModal
        show={showDelete}
        setter={setShowDelete}
        onClick={() => deleteObj(selectedId)}
      />

      <div className={`${styles.container} me-4`}>
        <TableView
          fetchUrl={"user_data_secure"}
          pull={setRows}
          noPagination={true}
          title={"users"}
          cols={cols}
          rows={rows}
          noCreate={true}
          useReactQuery={"user-list"}
        />
      </div>
    </div>
  );
}
