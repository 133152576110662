import React, { useEffect, useState } from "react";
import styles from "./checkout.module.css";
import ShopHeader from "../components/header/header";
import axiosInstance from "../../AxiosInstance";
import { useLocation, useNavigate } from "react-router-dom";
import { formatPrice } from "../../utils/utils";
import {
  toast,
  ToastTypes,
} from "../../components/ajonjolib/toasts/toast/toast";
import Loading from "../../admin/components/loading";

export default function Checkout() {
  const { state } = useLocation();
  const [userData, setUserData] = useState({});
  const [indications, setIndications] = useState(state.receivedIndications);
  const [canPurchase, setCanPurchase] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showPurchaseError, setShowPurchaseError] = useState(false);
  const district = JSON.parse(localStorage.getItem("district"));
  const [selectedPayment, setSelectedPayment] = useState("cash");
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [loadingPayment, setLoadingPayment] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    axiosInstance.get("current_user/").then((res) => {
      setUserData(res.data);
    });
  }, []);

  useEffect(() => {
    // Fetch the payment methods status
    setLoadingPayment(true);
    axiosInstance
      .get("/payment-methods/")
      .then((response) => {
        setPaymentMethods(response.data); // Set payment methods from the response
        setLoadingPayment(false);
      })
      .catch((error) => {
        console.error("Error fetching payment methods:", error);
        setLoadingPayment(false);
      });
  }, []);

  const makePurchase = () => {
    if (!canPurchase) return;
    setLoading(true);
    axiosInstance.get("cart/is_open/").then((response) => {
      setCanPurchase(false);
      const is_open = response.data.is_open;
      const message = response.data.message;
      if (is_open) {
        axiosInstance
          .post("sale/make_sale/", {
            district_id: district.id,
            indications: indications,
            selectedPayment,
            // success_url: "https://devfrontend.indilma.sn/confirmation",
            success_url: "https://bringme.sn/confirmation",
            total: state.total + state.deliveryFee,
          })
          .then((response) => {
            if (response.status === 200) {
              if (
                response.data &&
                typeof response.data === "string" &&
                response.data.startsWith("https")
              ) {
                return (window.location.href = response.data);
              }

              navigate("/confirmation", {
                state: {
                  userData: userData,
                  total: state.total,
                  deliveryFee: state.deliveryFee,
                },
              });
            } else {
              setShowPurchaseError(true);
              toast(
                "Une erreur est survenue. Merci de réessayer.",
                ToastTypes.ERROR
              );
              axiosInstance.post("cart/clear/").then((response) => {
                setTimeout(() => {
                  navigate("section", {});
                }, 300);
              });
            }
          })
          .catch((error) => {
            setCanPurchase(true);
            setShowPurchaseError(true);
            console.log(error);
          })
          .finally(() => {
            setLoading(false); // Set loading to false once the process finishes
          });
      } else {
        // Toast shop is closed
        setCanPurchase(true);
        setLoading(false);
        toast(message, ToastTypes.ERROR);
      }
    });
  };

  return (
    <div className={styles.container}>
      <ShopHeader />

      <div style={{ paddingTop: "80px" }}>
        <div className={styles.boxContainer}>
          <div>{userData?.user_data?.name}</div>
          <div>{userData?.user_data?.lastname}</div>
          <div>{userData?.user_data?.phone_number}</div>

          <div className={"my-4"}>{userData?.user_data?.address}</div>

          <div className={"d-flex justify-content-between"}>
            <div>Total: </div>
            <div className={"fw-bold"}>
              {formatPrice(state.total + state.deliveryFee)} FCFA
            </div>
          </div>
          <div className={"mt-2"}>
            <div className={"mb-1"}>Indications adresse:</div>
            <textarea
              value={indications}
              onChange={(e) => setIndications(e.target.value)}
              className={"w-100"}
              style={{ height: "100px" }}
            />
          </div>

          {/* Payment Method Selection */}
          <div className="mt-3">
            {loadingPayment ? (
              <Loading />
            ) : (
              <>
                <h6>Moyens de paiement:</h6>
                <div className="d-flex align-items-center justify-content-between">
                  {paymentMethods.map((method, index) => {
                    const methodName = method.name; // Adjust according to your response structure
                    const methodStatus = method.is_active; // Check if the method is active

                    const isWaveDisabled =
                      methodName === "wave" && state.restaurant_id; // Condition to disable wave

                    // Only render payment methods that are active and not disabled
                    if (methodStatus && !isWaveDisabled) {
                      return (
                        <div
                          key={index}
                          className="d-flex flex-column align-items-center mb-2"
                          onClick={() => setSelectedPayment(methodName)} // Only allow selection if not disabled
                          style={{
                            cursor: "pointer",
                            backgroundColor:
                              selectedPayment === methodName
                                ? "#f0f0f0"
                                : "transparent",
                            padding: "10px",
                            borderRadius: "5px",
                            flex: "1",
                            textAlign: "center",
                            border:
                              selectedPayment === methodName
                                ? "2px solid #000"
                                : "none",
                          }}
                        >
                          <img
                            src={`/assets/${methodName}.png`} // Assumes you have images named like the method name
                            alt={`${methodName} Payment`}
                            style={{ height: "40px", width: "auto" }}
                            className="mb-1"
                          />
                          <span>{methodName}</span>
                        </div>
                      );
                    }

                    return null; // Do not render if methodStatus is false or wave is disabled
                  })}
                </div>
              </>
            )}
          </div>

          <div
            className={`${styles.orderButton} pe-2 ps-4 mt-3`}
            style={{
              cursor: canPurchase ? "pointer" : "not-allowed",
              opacity: loading ? 0.5 : 1,
              pointerEvents: loading ? "none" : "auto",
            }}
            onClick={() => (loading ? null : makePurchase())}
          >
            <div className={"px-5 my-2"}>
              {loading ? "Validation..." : " Confirmer ma commande"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
