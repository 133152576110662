import React, { useEffect, useState } from "react";
import ShopHeader from "../components/header/header";
import PageTitle from "../components/page_title/page_title";
import styles from "./billers.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import DeliveryText from "../components/delivery_text/delivery_text";
import Cart from "../components/cart/cart";
import Footer from "../components/footer/footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faPersonBiking } from "@fortawesome/free-solid-svg-icons";
import axiosInstance from "../../AxiosInstance";

export default function ShopBillers() {
  const { state } = useLocation();
  const [section, setSection] = useState({});
  const navigate = useNavigate();
  const [billers, setBillers] = useState([]);

  useEffect(() => {
    if (state) {
      setSection(state[0].section);
      document.title = `${state[0]?.section?.name} | Bring Me`;
      setBillers(state);
    } else {
      const currentUrl = window.location.href;
      const splitURL = currentUrl.split("/");
      const foundDistrict = JSON.parse(localStorage.getItem("district"));
      if (splitURL[splitURL.length - 1] === "restaurants-et-autres") {
        axiosInstance
          .get(`supplier/?section_id=7&district_id=${foundDistrict.id}`)
          .then((response) => {
            const filteredData = response?.data.filter(
              (item) => item.fee !== undefined
            );
            filteredData.sort((a, b) => a.fee - b.fee);
            filteredData.sort((a, b) => b.is_open - a.is_open);
            setBillers(filteredData);
          });
      } else {
        axiosInstance
          .get(`supplier/?section_id=8&district_id=${foundDistrict.id}`)
          .then((response) => {
            const filteredData = response.data.filter(
              (item) => item.fee !== undefined
            );
            filteredData.sort((a, b) => a.fee - b.fee);
            filteredData.sort((a, b) => b.is_open - a.is_open);
            setBillers(filteredData);
          });
      }
    }
  }, []);

  return (
    <div>
      <ShopHeader />
      <Cart />

      <div className={`pb-5`} style={{ paddingTop: "80px" }}>
        <PageTitle name={section?.name} description={section?.description} />

        <div className={`${styles.responsiveContainer}`}>
          <DeliveryText />

          <div
            className={`d-flex flex-wrap mt-4 justify-content-between`}
            style={{ display: "flex" }}
          >
            {billers?.map((biller, index) => {
              return (
                <div
                  key={index}
                  className={`flex-fill mb-2 pb-1 ${styles.billerBox}`}
                  style={{ flex: "1", marginLeft: "10px" }}
                  onClick={() => {
                    navigate(`/${biller.slug}`, { state: biller });
                  }}
                >
                  <div style={{ position: "relative" }}>
                    {!biller.is_open && (
                      <div
                        style={{
                          position: "absolute",
                          width: "100%",
                          height: "100%",
                          backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust the background color and opacity
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          zIndex: 2,
                          borderTopLeftRadius: "15px",
                          borderTopRightRadius: "15px",
                          userSelect: "none",
                        }}
                      >
                        <div
                          style={{ backgroundColor: "#009F7F" }}
                          className={
                            "d-flex px-3 py-1 align-items-center rounded-3"
                          }
                        >
                          <div className={"pe-2"}>
                            <FontAwesomeIcon icon={faLock} color={"#FFF"} />
                          </div>
                          <div style={{ color: "#FFF", fontWeight: 500 }}>
                            Fermé
                          </div>
                        </div>
                      </div>
                    )}
                    <img
                      alt={"biller_image"}
                      src={biller?.image?.image}
                      width={"100%"}
                      style={{
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                        maxHeight: "108px",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                  <div className={"px-2"}>
                    <div style={{ fontWeight: "500", fontSize: "x-large" }}>
                      {biller.name}
                    </div>
                    <div className={"d-flex align-items-center"}>
                      <FontAwesomeIcon
                        icon={faPersonBiking}
                        size={"xs"}
                        className={"pe-1"}
                      />
                      <div style={{ fontSize: "14px" }}>
                        {biller.delivery_time} • {biller.fee}Fr
                      </div>
                    </div>
                    <div>{biller.description}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
