// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.billers_billerBox__dgZjQ {
    background-color: #FFFFFF;
    min-width: 300px;
    max-width: 320px;
    box-shadow: 0 0 10px #0000001A;
    text-align: left;
    -webkit-user-select: none;
            user-select: none;
    margin-right: 0.5rem;
    object-fit: contain;
    border-radius: 10px;
    cursor: pointer;
}

.billers_billerBox__dgZjQ img {
    width: 100%;
    height: 100px;
    object-fit: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.billers_responsiveContainer__uAIhM {
    margin-right: 3rem;
    margin-left: 3rem;
}

@media (max-width: 650px) {
    .billers_responsiveContainer__uAIhM {
        margin-right: 0.5rem;
        margin-left: 0.5rem;
    }

    .billers_billerBox__dgZjQ {
        max-width: 100%;
        margin: 0 !important;
    }

    .billers_billerBox__dgZjQ img {
        width: 100%;
        min-height: 150px !important;
        object-fit: cover;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
}`, "",{"version":3,"sources":["webpack://./src/shop/billers/billers.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,gBAAgB;IAChB,gBAAgB;IAChB,8BAA8B;IAC9B,gBAAgB;IAChB,yBAAiB;YAAjB,iBAAiB;IACjB,oBAAoB;IACpB,mBAAmB;IACnB,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,iBAAiB;IACjB,4BAA4B;IAC5B,6BAA6B;AACjC;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI;QACI,oBAAoB;QACpB,mBAAmB;IACvB;;IAEA;QACI,eAAe;QACf,oBAAoB;IACxB;;IAEA;QACI,WAAW;QACX,4BAA4B;QAC5B,iBAAiB;QACjB,4BAA4B;QAC5B,6BAA6B;IACjC;AACJ","sourcesContent":[".billerBox {\n    background-color: #FFFFFF;\n    min-width: 300px;\n    max-width: 320px;\n    box-shadow: 0 0 10px #0000001A;\n    text-align: left;\n    user-select: none;\n    margin-right: 0.5rem;\n    object-fit: contain;\n    border-radius: 10px;\n    cursor: pointer;\n}\n\n.billerBox img {\n    width: 100%;\n    height: 100px;\n    object-fit: cover;\n    border-top-left-radius: 10px;\n    border-top-right-radius: 10px;\n}\n\n.responsiveContainer {\n    margin-right: 3rem;\n    margin-left: 3rem;\n}\n\n@media (max-width: 650px) {\n    .responsiveContainer {\n        margin-right: 0.5rem;\n        margin-left: 0.5rem;\n    }\n\n    .billerBox {\n        max-width: 100%;\n        margin: 0 !important;\n    }\n\n    .billerBox img {\n        width: 100%;\n        min-height: 150px !important;\n        object-fit: cover;\n        border-top-left-radius: 10px;\n        border-top-right-radius: 10px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"billerBox": `billers_billerBox__dgZjQ`,
	"responsiveContainer": `billers_responsiveContainer__uAIhM`
};
export default ___CSS_LOADER_EXPORT___;
