import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import axiosInstance from "../../../AxiosInstance";
import { formatPrice } from "../../../utils/utils";
import "./diagram.css";

// Enregistrer les composants nécessaires pour Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const WarehouseMonthlyStatsChart = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    axiosInstance
      .get("dashboard/warehouse/")
      .then((response) => {
        setData(response.data.monthly_stats);
      })
      .catch((error) => {
        console.error(
          "Il y a eu une erreur lors de la récupération des données !",
          error
        );
      });
  }, []);

  // Préparer les données pour Chart.js
  const chartData = {
    labels: data.map((item) => item.month),
    datasets: [
      {
        label: "Total Profit",
        data: data.map((item) => item.total_profit),
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
        yAxisID: "y1",
      },

      {
        label: "Total Purchase",
        data: data.map((item) => item.purchase_count),
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
        yAxisID: "y1",
      },
      {
        label: "Sale Count",
        data: data.map((item) => item.sale_count),
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
        yAxisID: "y2",
      },
    ],
  };

  // Options for Chart.js
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            if (tooltipItem.dataset.label === "Total Profit") {
              return `${tooltipItem.dataset.label}: ${formatPrice(
                tooltipItem.raw
              )}`;
            } else if (tooltipItem.dataset.label === "Total Purchase") {
              return `${tooltipItem.dataset.label}: ${formatPrice(
                tooltipItem.raw
              )}`;
            }
            return `${tooltipItem.dataset.label}: ${tooltipItem.raw}`;
          },
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Month",
        },
      },
      y1: {
        title: {
          display: true,
          text: "Total",
        },
        position: "left",
        beginAtZero: true,
      },
      y2: {
        title: {
          display: true,
          text: "Sales counts",
        },
        position: "right",
        beginAtZero: true,
      },
    },
  };

  return (
    <div className="chart-container">
      <h2>Warehouse Monthly Statistics</h2>
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default WarehouseMonthlyStatsChart;
