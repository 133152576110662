import React, { useState } from "react";
import styles from "../../styles/admin.module.css";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import TableView from "../../components/table_view";
import { formatTimestamp } from "../../components/utils";

export default function TransferHistory() {
  const [rows, setRows] = useState([]);
  const cols = [
    { title: "Product", internal: "product.name" },
    { title: "From", internal: "from_warehouse.name" },
    { title: "To", internal: "to_warehouse.name" },
    { title: "Quantity", internal: "quantity" },
    {
      title: "Time",
      internal: "timestamp",
      code: (row, data) => {
        return <div>{formatTimestamp(data)}</div>;
      },
    },
  ];

  return (
    <div className={styles.mainContainer}>
      <AdminHeader />
      <AdminSidebar />

      <div className={`${styles.container} me-4`}>
        <TableView
          fetchUrl={"transfer_history"}
          pull={setRows}
          noPagination={false}
          title={"transfer History"}
          cols={cols}
          rows={rows}
          withIndex={true}
          noCreate={true}
          useReactQuery={"transfere-history-list"}
        />
      </div>
    </div>
  );
}
