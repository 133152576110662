import React, {useState} from "react";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import styles from '../../styles/admin.module.css';
import {
    BigTextInput,
    FileInput,
    PrimaryButton, RadioInput,
    SecondaryButton,
    SelectInput,
    TextInput
} from "../../components/inputs";

export default function ExpensesCreate() {
    const [form, setForm] = useState({});

    const back = () => {
        window.location.replace('/admin/expenses');
    }

    return (
        <div className={styles.mainContainer}>
            <AdminHeader/>
            <AdminSidebar/>

            <div className={`${styles.crudContainer}`}>
                <div className={styles.crudText}>
                    <div className={'mb-2'}>Create Expense</div>
                    <div>Add the expense description and necessary information from here</div>
                </div>
                <div className={styles.crudInputs}>
                    <div className={`${styles.createContainer} p-4`}>
                        <SelectInput name={'warehouse'} setForm={setForm} form={form}/>
                        <TextInput name={'name'} setForm={setForm} form={form}/>
                        <TextInput name={'amount'} setForm={setForm} form={form}/>
                    </div>

                    <div className={styles.buttonContainer}>
                        <SecondaryButton name={'Back'} onClick={back}/>
                        <div className={'ps-3'}/>
                        <PrimaryButton name={'Add Expense'}/>
                    </div>
                </div>
            </div>
        </div>
    );
}