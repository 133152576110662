import React, {useEffect, useState} from "react";
import ShopHeader from "../components/header/header";
import PageTitle from "../components/page_title/page_title";
import Footer from "../components/footer/footer";
import axiosInstance from "../../AxiosInstance";
import {formatTimestamp} from "../../admin/components/utils";
import styles from './my_orders.module.css';
import OrderDetail from "../components/order_detail/order_detail";
import {formatPrice} from "../../utils/utils";

export default function MyOrders() {
    const [orders, setOrders] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState({});

    useEffect(() => {
        document.title = 'Mes commandes | Bring Me';
        axiosInstance.get('current_user_orders/').then((response) => {
            setOrders(response.data);
        });
    }, []);

    const getStatus = (status) => {
        switch (status) {
            case 0:
                return 'En attente de confirmation';
            case 1:
                return 'Confirmée';
            case 5:
                return 'Annulée';
            case 4:
                return 'Livrée';
            default:
                return 'Inconnu';
        }
    }

    return (
      <div>
          <ShopHeader/>

          <OrderDetail showModal={showModal} setShowModal={setShowModal} order={selectedOrder}/>

          <div className={`pb-5`} style={{paddingTop: "80px"}}>
              <PageTitle name={'Mes commandes'}/>

              <div className={`mx-5 pb-5 mt-4 ${styles.innerContainer}`}>
                  {orders.map((order, index) => {
                      return (
                          <div className={`${styles.container} mb-3`} onClick={() => {
                              setShowModal(true);
                              setSelectedOrder(order);
                          }}>
                              <div className={`${styles.title}  p-3 d-flex justify-content-between`}>
                                  <div><b>Commande</b> ({order.reference})</div>
                                  <div className={'fw-bold'}>{order.supplier ? order.supplier.name : 'Epicerie en ligne'}</div>
                                  <div style={{color: '#0097F7'}}>{getStatus(order.status)}</div>
                              </div>

                              <div className={'px-3 py-2 d-flex justify-content-between'}>
                                  <div><b>Address:</b></div>
                                  <div>{order.address}</div>
                              </div>


                              <div className={'px-3 py-2 d-flex justify-content-between'}>
                                  <div><b>Date:</b></div>
                                  <div>{formatTimestamp(order.date)}</div>
                              </div>

                              <div className={'px-3 py-2 d-flex justify-content-between'}>
                                  <div><b>Total: </b></div>
                                  <div><b>{formatPrice(order.sub_total + order.fee)} FCFA</b></div>
                              </div>
                          </div>
                      );
                  })}
              </div>

              <Footer/>
          </div>
      </div>
    );
}