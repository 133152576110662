import React from "react";
import styles from './footer.module.css';
import {useNavigate} from "react-router-dom";

export default function Footer() {
    const navigate = useNavigate();
    return (
        <div className={styles.container}>
            <div className={'d-flex justify-content-between align-items-center py-4 mx-2'}>
                <div style={{flexBasis: '33%'}}>
                    <a href={'https://apps.apple.com/us/app/bring-me-sénégal/id1508042789'} target={'_blank'}>
                        <img src={'/footer/app_store.png'} height={'40px'}/>
                    </a>
                    <a href={'https://play.google.com/store/apps/details?id=com.angularfirebase.bringme&hl=fr'} target={'_blank'}>
                        <img src={'/footer/play_store.png'} height={'40px'}/>
                    </a>
                </div>
                <div style={{color: '#FFF', flexBasis: '33%'}} className={'d-flex justify-content-around'}>
                    <div role={'button'} onClick={() => navigate('/cgv-cgu')}>CGV</div>
                    <div role={'button'} onClick={() => window.open('https://www.iubenda.com/privacy-policy/15684431')}>Politique de confidentialité</div>
                    <div role={'button'} onClick={() => navigate('/contact')}>Contact</div>
                </div>
                <div style={{flexBasis: '33%'}}>
                    <a href={'https://www.facebook.com/BringMeSn'} target={'_blank'} className={'pe-3'}>
                        <img src={'/footer/fb.png'} height={'30px'}/>
                    </a>
                    <a href={'https://www.instagram.com/bringme.sn/'} target={'_blank'}>
                        <img src={'/footer/insta.png'} height={'30px'}/>
                    </a>
                </div>
            </div>
        </div>
    );
}