import React from "react";
import {useLocation} from "react-router-dom";
import styles from "../../styles/admin.module.css";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import SaleDetailModal from "../closures/sale_detail/sale_detail";

export default function SaleView() {
    const {state} = useLocation();

    return (
        <div className={styles.mainContainer}>
            <AdminHeader/>
            <AdminSidebar/>
            <div className={`${styles.crudContainer}`}>
                <div className={styles.crudText}>
                    <div className={'mb-2'}>Sale Details</div>
                    <div></div>
                </div>
                <div className={styles.crudInputs}>
                    <SaleDetailModal data={state}/>
                </div>
            </div>
        </div>
    );
}