// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard_iconBall__LMZUz {
    border-radius: 50%;
    width: 2.75rem;
    height: 2.75rem;
    padding-top: 10px;
}

.dashboard_title__Xt\\+dN {
    font-weight: 600;
}

.dashboard_subtitle__-nGK6 {
    font-weight: 600;
    float: left;
    color: #6B768C;
    font-size: small;
}

.dashboard_cubeContainer__E74N\\+ {
    display: flex;
}

.dashboard_cube__Va1mu {
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #FFF;
    margin-right: 1rem;
    margin-bottom: 1rem;
    height: 10rem;
    padding: 20px;
    flex: 1 0 21%;
}

.dashboard_cubeValue__uNTvC {
    font-size: large;
    font-weight: 600;
    justify-self: start;
}

@media(max-width: 992px) {
    .dashboard_cubeContainer__E74N\\+ {
        flex-wrap: wrap;
    }

    .dashboard_cube__Va1mu {
        flex: 1 0 45% !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/admin/styles/dashboard.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,cAAc;IACd,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,WAAW;IACX,cAAc;IACd,gBAAgB;AACpB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,mBAAmB;IACnB,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,sBAAsB;IACtB,kBAAkB;IAClB,mBAAmB;IACnB,aAAa;IACb,aAAa;IACb,aAAa;AACjB;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI;QACI,eAAe;IACnB;;IAEA;QACI,wBAAwB;IAC5B;AACJ","sourcesContent":[".iconBall {\n    border-radius: 50%;\n    width: 2.75rem;\n    height: 2.75rem;\n    padding-top: 10px;\n}\n\n.title {\n    font-weight: 600;\n}\n\n.subtitle {\n    font-weight: 600;\n    float: left;\n    color: #6B768C;\n    font-size: small;\n}\n\n.cubeContainer {\n    display: flex;\n}\n\n.cube {\n    border-radius: 10px;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    background-color: #FFF;\n    margin-right: 1rem;\n    margin-bottom: 1rem;\n    height: 10rem;\n    padding: 20px;\n    flex: 1 0 21%;\n}\n\n.cubeValue {\n    font-size: large;\n    font-weight: 600;\n    justify-self: start;\n}\n\n@media(max-width: 992px) {\n    .cubeContainer {\n        flex-wrap: wrap;\n    }\n\n    .cube {\n        flex: 1 0 45% !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconBall": `dashboard_iconBall__LMZUz`,
	"title": `dashboard_title__Xt+dN`,
	"subtitle": `dashboard_subtitle__-nGK6`,
	"cubeContainer": `dashboard_cubeContainer__E74N+`,
	"cube": `dashboard_cube__Va1mu`,
	"cubeValue": `dashboard_cubeValue__uNTvC`
};
export default ___CSS_LOADER_EXPORT___;
