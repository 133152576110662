import React, { useContext, useEffect, useState } from "react";
import AdminHeader from "../../components/header";
import AdminSidebar from "../../components/sidebar";
import styles from "../../styles/admin.module.css";
import {
  PrimaryButton,
  SecondaryButton,
  SelectInput,
  TextInput,
} from "../../components/inputs";
import axiosInstance from "../../../AxiosInstance";
import MyContext from "../../../MyContext";
import TimePicker from "react-time-picker";
import { useNavigate } from "react-router-dom";
import { queryClient } from "../../../App";

export default function WarehousesCreate() {
  const [districts, setDistricts] = useState([]);
  const [form, setForm] = useState({});
  const { editing } = useContext(MyContext);

  useEffect(() => {
    setForm(editing);
    axiosInstance.get("district/").then((response) => {
      setDistricts(response.data);
    });
  }, []);

  const navigate = useNavigate();
  const back = () => {
    // window.location.replace("/admin/warehouses");
    navigate("/admin/warehouses", { replace: true });
  };

  const submit = () => {
    let formData = new FormData();
    for (const key in form) {
      const value = form[key];
      if (value !== null) {
        formData.append(key, value);
      }
    }

    if (editing) {
      axiosInstance
        .put("warehouse/" + editing.id + "/", formData)
        .then((response) => {
          queryClient.invalidateQueries({
            queryKey: ["tableData-warehouse-list"],
          });
          back();
        });
    } else {
      axiosInstance.post("warehouse/", formData).then((response) => {
        queryClient.invalidateQueries({
          queryKey: ["tableData-warehouse-list"],
        });
        back();
      });
    }
  };

  return (
    <div className={styles.mainContainer}>
      <AdminHeader />
      <AdminSidebar />

      <div className={`${styles.crudContainer}`}>
        <div className={styles.crudText}>
          <div className={"mb-2"}>Warehouse</div>
          <div>
            Add your warehouse details and necessary information from here
          </div>
        </div>
        <div className={styles.crudInputs}>
          <div className={`${styles.createContainer} p-4`}>
            <TextInput name={"name"} setForm={setForm} form={form} />
            <TextInput name={"slug"} setForm={setForm} form={form} />
            <TextInput
              name={"Free Delivery"}
              internalName={"free_delivery"}
              setForm={setForm}
              form={form}
            />{" "}
            {/*Min Delivery*/}
            <div className={"d-flex justify-content-center pb-2"}>
              <div className={"pe-4"}>
                <div>Open Monday</div>
                <TimePicker
                  value={form.open_1}
                  onChange={(val) => setForm({ ...form, open_1: val })}
                />
              </div>
              <div>
                <div>Close Monday</div>
                <TimePicker
                  value={form.close_1}
                  onChange={(val) => setForm({ ...form, close_1: val })}
                />
              </div>
            </div>
            <div className={"d-flex justify-content-center pb-2"}>
              <div className={"pe-4"}>
                <div>Open Tuesday</div>
                <TimePicker
                  value={form.open_2}
                  onChange={(val) => setForm({ ...form, open_2: val })}
                />
              </div>
              <div>
                <div>Close Tuesday</div>
                <TimePicker
                  value={form.close_2}
                  onChange={(val) => setForm({ ...form, close_2: val })}
                />
              </div>
            </div>
            <div className={"d-flex justify-content-center pb-2"}>
              <div className={"pe-4"}>
                <div>Open Wednesday</div>
                <TimePicker
                  value={form.open_3}
                  onChange={(val) => setForm({ ...form, open_3: val })}
                />
              </div>
              <div>
                <div>Close Wednesday</div>
                <TimePicker
                  value={form.close_3}
                  onChange={(val) => setForm({ ...form, close_3: val })}
                />
              </div>
            </div>
            <div className={"d-flex justify-content-center pb-2"}>
              <div className={"pe-4"}>
                <div>Open Thursday</div>
                <TimePicker
                  value={form.open_4}
                  onChange={(val) => setForm({ ...form, open_4: val })}
                />
              </div>
              <div>
                <div>Close Thursday</div>
                <TimePicker
                  value={form.close_4}
                  onChange={(val) => setForm({ ...form, close_4: val })}
                />
              </div>
            </div>
            <div className={"d-flex justify-content-center pb-2"}>
              <div className={"pe-4"}>
                <div>Open Friday</div>
                <TimePicker
                  value={form.open_5}
                  onChange={(val) => setForm({ ...form, open_5: val })}
                />
              </div>
              <div>
                <div>Close Friday</div>
                <TimePicker
                  value={form.close_5}
                  onChange={(val) => setForm({ ...form, close_5: val })}
                />
              </div>
            </div>
            <div className={"d-flex justify-content-center pb-2"}>
              <div className={"pe-4"}>
                <div>Open Saturday</div>
                <TimePicker
                  value={form.open_6}
                  onChange={(val) => setForm({ ...form, open_6: val })}
                />
              </div>
              <div>
                <div>Close Saturday</div>
                <TimePicker
                  value={form.close_6}
                  onChange={(val) => setForm({ ...form, close_6: val })}
                />
              </div>
            </div>
            <div className={"d-flex justify-content-center pb-2"}>
              <div className={"pe-4"}>
                <div>Open Sunday</div>
                <TimePicker
                  value={form.open_7}
                  onChange={(val) => setForm({ ...form, open_7: val })}
                />
              </div>
              <div>
                <div>Close Sunday</div>
                <TimePicker
                  value={form.close_7}
                  onChange={(val) => setForm({ ...form, close_7: val })}
                />
              </div>
            </div>
            <div
              style={{ fontWeight: 500, fontSize: "16px" }}
              className={"py-3"}
            >
              Set Delivery Fees
            </div>
            {districts.map((district, index) => {
              return (
                <div
                  className={
                    "d-flex justify-content-between align-items-center"
                  }
                  style={{ borderTop: "1px solid #D6D6D6" }}
                >
                  <div>{district.name}</div>
                  <div className={"py-3"}>
                    <TextInput
                      name={`fee_${district.id}`}
                      form={form}
                      setForm={setForm}
                      hideTitle={true}
                    />
                  </div>
                </div>
              );
            })}
          </div>

          <div className={styles.buttonContainer}>
            <SecondaryButton name={"Back"} onClick={back} />
            <div className={"ps-3"} />
            <PrimaryButton
              name={editing ? "Update" : "Add Warehouse"}
              onClick={submit}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
